export const colors = {
  purple_light: '#F8F8FE',
  purple_dark: '#946ED1',
  purple_label: '#8C67C9',
  white: '#fff',
  black: '#000',
  grey: '#637083',
  grey_light: '#C7CED1',
  grey_dark: '#333',
  secondary: '#637083',
  primary: '#26D9D9',
  primary_dark: '#02c4c4',
  error: '#E23E3E',
  active: '#FBFCFF',
  dashboard_light_grey: '#f9fafa',
};
