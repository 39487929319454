import React from 'react';
import { useAuth } from '../../../../context/auth-provider';
import { User } from '../../../../interfaces/user-interfaces';
import { colors } from '../../../../style-utils/colors';
import { font, Text } from '../../../../style-utils/text';
import {
  DashboardHeadingContainer,
  LogedInUser,
  ProfileButtonBorder,
  SearchAndProfileContainer,
} from '../dashboard.styled';
import { Profile } from '../../../profile/profile';

interface DashboardHeadingProps {
  title: string;
}
export const DashboardHeading: React.FC<DashboardHeadingProps> = ({
  title,
}) => {
  const {
    user,
  }: {
    user: User | null | undefined;
  } = useAuth();

  return (
    <DashboardHeadingContainer id="dashboard-heading-container">
      <Text
        id="dashboard-title"
        fontSize={20}
        fontWeight={700}
        style={{ textTransform: 'capitalize' }}
      >
        {title}
      </Text>
      <SearchAndProfileContainer id="dashboard-search-profile-container">
        <LogedInUser>
          <Text
            mt={0}
            mb={4}
            fontFamily={font.family}
            fontSize={font.size.xs}
            fontWeight={500}
            color={colors.grey_dark}
          >
            {user?.full_name}
          </Text>
          <Text
            mt={4}
            mb={0}
            fontFamily={font.family}
            fontSize={font.size.xxs}
            color={colors.grey}
            style={{ textTransform: 'capitalize' }}
          >
            {user?.role}
          </Text>
        </LogedInUser>
        <ProfileButtonBorder>
          <Profile dashboard={true} />
        </ProfileButtonBorder>
      </SearchAndProfileContainer>
    </DashboardHeadingContainer>
  );
};
