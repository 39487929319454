export enum GUEST_TYPES {
  GUEST = 'Guest',
  PROSPECT = 'Prospect',
  VENDOR = 'Vendor',
  DELIVERY = 'Delivery',
  EMERGENCY = 'Emergency (911)',
}

export enum VISITOR_TYPES {
  GUEST = 'guest',
  PROSPECT = 'prospect',
  VENDOR = 'vendor',
  DELIVERY = 'delivery',
  EMERGENCY = 'emergency',
}
