export const getNextAndPrevPaths = (
  currentPath: string,
  allPaths: string[]
): { nextPath: string; prevPath: string; lastPath: string } => {
  const firstSlug = currentPath.split('/')[1];
  const cleanPath = removeFirstSlug(currentPath);
  const currentIndex = allPaths.indexOf(cleanPath);

  const getNextPath = () => {
    const nextPaths = allPaths.slice(currentIndex + 1);
    const foundPath = getNextOrPrevPath(nextPaths, cleanPath);

    return foundPath ? addFirstSlug(foundPath, firstSlug) : '/main-menu';
  };

  const getPrevPath = () => {
    if (currentPath === '/visitor-log/' || currentPath === '/daily-log') {
      return '/main-menu';
    }
    const prevPaths = allPaths.slice(0, currentIndex).reverse();
    const foundPath = getNextOrPrevPath(prevPaths, cleanPath);
    if (foundPath) {
      return addFirstSlug(foundPath, firstSlug);
    } else {
      return currentPath.includes('visitor-log')
        ? '/visitor-log/'
        : '/daily-log';
    }
  };

  const getNextOrPrevPath = (paths: string[], current: string) => {
    const currentMainRoute = current.split('/')[1];

    return paths.find(path => path.split('/')[1] === currentMainRoute);
  };

  const nextPath = getNextPath();
  const prevPath = getPrevPath();

  const lastPath = currentPath.includes('visitor-log')
    ? '/visitor-log/'
    : '/daily-log';

  return { nextPath, prevPath, lastPath };
};

export const removeFirstSlug = (path: string): string => {
  const parts = path.split('/');
  parts.shift();
  parts.shift();

  return '/' + parts.join('/');
};

export const addFirstSlug = (path: string, slug: string): string => {
  return `/${slug}${path}`;
};
