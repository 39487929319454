import React from 'react';
import { StepContainer } from './step.styled';
import { Text, font } from '../../style-utils/text';
import { colors } from '../../style-utils/colors';

interface StepProps {
  maxPaths: number;
  currentPathIndex: number;
}
export const Step: React.FC<StepProps> = ({ maxPaths, currentPathIndex }) => {
  return (
    <StepContainer>
      <Text
        borderBottom={`3px solid ${colors.purple_dark}`}
        fontFamily={font.family}
        fontSize={font.size.xs}
        mt={0}
        pb={6}
      >
        {maxPaths === 0
          ? `Step ${currentPathIndex} `
          : `Step ${currentPathIndex + 2}`}
      </Text>
    </StepContainer>
  );
};
