import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const SidebarWrapper = styled.div`
  display: flex;
  width: 250px;
`;
export const BuildingDataWrapper = styled.div`
  display: flex;
  border-right: 1px solid lightgray;
  background: rgb(253, 253, 253);
  width: 100%;
`;

export const SidebarContainer = styled.div`
  width: 80px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid lightgray;
  background: rgb(253, 253, 253);
`;

export const Logo = styled.div`
  margin: 16px;
  width: 32px;
  height: 32px;
  background-color: green;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 68px;
`;

export const IconWrapper = styled.div<{ isHighlighted: boolean }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: ${({ isHighlighted }) => isHighlighted && `rgb(248, 248, 254);`};
`;
