export const GUEST_NAVIGATION = [
  '/visitor-log/guest/visitor-details',
  '/visitor-log/guest/number-of-guests',
  '/visitor-log/guest/id',
  '/visitor-log/guest/apartment-location',
  '/visitor-log/guest/approval',
  '/visitor-log/guest/parking-validation',
  '/visitor-log/guest/notes',
];

export const PROSPECT_NAVIGATION = [
  '/visitor-log/prospect/visitor-details',
  '/visitor-log/prospect/number-of-guests',
  '/visitor-log/prospect/id',
  '/visitor-log/prospect/apartment-location',
  '/visitor-log/prospect/approval',
  '/visitor-log/prospect/parking-validation',
  '/visitor-log/prospect/notes',
];

export const VENDOR_NAVIGATION = [
  '/visitor-log/vendor/visitor-details',
  '/visitor-log/vendor/number-of-guests',
  '/visitor-log/vendor/id',
  '/visitor-log/vendor/apartment-location',
  '/visitor-log/vendor/approval',
  '/visitor-log/vendor/parking-validation',
  '/visitor-log/vendor/notes',
];

export const DELIVERY_NAVIGATION = [
  '/visitor-log/delivery/visitor-details',
  '/visitor-log/delivery/number-of-guests',
  '/visitor-log/delivery/id',
  '/visitor-log/delivery/apartment-location',
  '/visitor-log/delivery/approval',
  '/visitor-log/delivery/parking-validation',
  '/visitor-log/delivery/notes',
];

export const EMERGENCY_NAVIGATION = [
  '/visitor-log/emergency/visitor-details',
  '/visitor-log/emergency/number-of-guests',
  '/visitor-log/emergency/apartment-location',
  '/visitor-log/emergency/notes',
];

export const MAINTENANCE_NAVIGATION = [
  '/daily-log/maintenance/apartment-location',
  '/daily-log/maintenance/priority',
  '/daily-log/maintenance/description',
  '/daily-log/maintenance/additional-notes',
];

export const LEASING_OFFICE_NAVIGATION = [
  '/daily-log/leasing-office/description',
  '/daily-log/leasing-office/additional-notes',
];

export const RESIDENT_NAVIGATION = [
  '/daily-log/resident/id',
  '/daily-log/resident/apartment-location',
  '/daily-log/resident/priority',
  '/daily-log/resident/description',
  '/daily-log/resident/additional-notes',
];

export const MISSCELANEAUS_NAVIGATION = [
  '/daily-log/miscellaneous/apartment-location',
  '/daily-log/miscellaneous/description',
  '/daily-log/miscellaneous/additional-notes',
];

export const SHIFT_CHANGE_NAVIGATION = [
  '/daily-log/shift-change/id',
  '/daily-log/shift-change/additional-notes',
];
