import React from 'react';

export const UploadImageIcon = () => {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="54"
        height="54"
        rx="27"
        fill="#8C67C9"
        fillOpacity="0.1"
      />
      <path
        d="M18.5 26C18.5 22.2288 18.5 20.3431 19.6716 19.1716C20.8431 18 22.7288 18 26.5 18H28.5C32.2712 18 34.1569 18 35.3284 19.1716C36.5 20.3431 36.5 22.2288 36.5 26V28C36.5 31.7712 36.5 33.6569 35.3284 34.8284C34.1569 36 32.2712 36 28.5 36H26.5C22.7288 36 20.8431 36 19.6716 34.8284C18.5 33.6569 18.5 31.7712 18.5 28V26Z"
        stroke="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4976 29.2904L34.1544 28.9471L34.1385 28.9313C33.738 28.5307 33.4149 28.2077 33.1314 27.9687C32.8394 27.7226 32.555 27.5353 32.2221 27.4349C31.7512 27.2928 31.2488 27.2928 30.7779 27.4349C30.445 27.5353 30.1606 27.7226 29.8686 27.9687C29.5851 28.2077 29.262 28.5307 28.8615 28.9313L28.8456 28.9471C28.5459 29.2469 28.3458 29.4462 28.1832 29.5807C28.0218 29.7142 27.952 29.7359 27.9237 29.7408C27.8029 29.762 27.6785 29.7381 27.5742 29.6735C27.5498 29.6584 27.493 29.6123 27.3928 29.4285C27.2917 29.2432 27.18 28.9838 27.013 28.5942L26.9596 28.4696L26.9475 28.4414L26.9475 28.4414C26.5829 27.5907 26.2931 26.9144 26.0107 26.4126C25.7237 25.9026 25.4052 25.4984 24.9457 25.268C24.5343 25.0618 24.0738 24.9731 23.6152 25.0118C23.103 25.055 22.6572 25.3121 22.2013 25.6789C21.7527 26.04 21.2324 26.5603 20.578 27.2148L20.578 27.2148L20.5563 27.2364L20.5 27.2928V27.9999C20.5 28.2462 20.5001 28.4816 20.5004 28.7065L21.2634 27.9435C21.9444 27.2626 22.4269 26.7811 22.8284 26.458C23.2276 26.1366 23.4826 26.0265 23.6992 26.0082C23.9744 25.985 24.2507 26.0382 24.4976 26.162C24.692 26.2594 24.8879 26.4564 25.1392 26.903C25.3919 27.3521 25.6611 27.9783 26.0404 28.8635L26.0938 28.9881L26.1034 29.0105L26.1034 29.0105C26.2583 29.3719 26.3884 29.6754 26.5149 29.9073C26.6448 30.1455 26.8038 30.3727 27.0479 30.5238C27.3608 30.7175 27.7341 30.7894 28.0966 30.7258C28.3794 30.6761 28.6114 30.5242 28.8204 30.3513C29.024 30.183 29.2575 29.9495 29.5355 29.6714L29.5527 29.6542C29.9728 29.2341 30.266 28.9416 30.513 28.7334C30.7553 28.5292 30.9185 28.437 31.0667 28.3922C31.3493 28.307 31.6507 28.307 31.9333 28.3922C32.0815 28.437 32.2447 28.5292 32.487 28.7334C32.734 28.9416 33.0272 29.2341 33.4473 29.6542L34.4755 30.6825C34.4887 30.2721 34.4948 29.812 34.4976 29.2904Z"
        fill="#333333"
      />
      <circle cx="32" cy="22.5" r="1.5" fill="#333333" />
    </svg>
  );
};
