import React, { useEffect, useRef, useState } from 'react';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import {
  AddNewUserForm,
  AddNewUserFormContainer,
  SaveButton,
  ButtonTitle,
  CountryCodeContainer,
  CountryCodeSelect,
  DashboardContent,
  ImageUploadContainer,
  InputContainer,
  Label,
  Title,
  ButtonsContainer,
  CancelButton,
  ErrorLabel,
  CheckRingContainer,
  StyledSelectMenu,
  StyledUserInput,
  eyeIconStyles,
  StyledSelectCheckboxes,
  CheckboxesContainerInSelectMenu,
  StyledImage,
} from './dashboard-users.styled';
import { useNavigate } from 'react-router-dom';
import {
  User,
  UserWithBuildingsObjects,
} from '../../../../interfaces/user-interfaces';
import { regexValidation } from '../../../../function-utils/regex-validation';
import { CheckRing } from '../../../icons/check-ring';
import { Spinner } from '../../../icons/spinner';
import { UploadImageIcon } from '../../../icons/upload-image';
import { codes } from '../../../../constants/country-codes';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import { fetchAllItems } from '../../../requests/requests';
import { RightArrow } from '../../../icons/right-arrow';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { resizeImage } from '../dashboard-buildings-components/dashboard-add-new-building';

const UserInitialState: UserWithBuildingsObjects = {
  id: '',
  user_image: null,
  full_name: '',
  email: '',
  password: '',
  property_ids: [],
  propertiesObjects: [],
  phone_number: '',
  role: '',
};

interface DashboardEditUserByIdProps {
  editableUserData: UserWithBuildingsObjects | User | undefined;
}

export const DashboardEditUserById: React.FC<DashboardEditUserByIdProps> = ({
  editableUserData,
}) => {
  const [updatedUserData, setUpdatedUserData] = useState<
    UserWithBuildingsObjects | User
  >(
    editableUserData && editableUserData.email.length > 0
      ? editableUserData
      : UserInitialState
  );
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [properties, setProperties] = useState<BuildingData[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPropertiesCheckboxesOpened, setIsPropertiesCheckboxesOpened] =
    useState<boolean>(false);
  const checkboxesContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null | undefined>(
    updatedUserData.user_image as string
  );

  const navigate = useNavigate();

  const getPropertiesfromDB = async () => {
    const response = await fetchAllItems('dashboard/buildings');

    setProperties(response as BuildingData[]);
  };

  useEffect(() => {
    getPropertiesfromDB();
  }, []);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewUserData = (data: any) => {
    const managerFields: string[] = [
      'full_name',
      'email',
      'property_ids',
      'phone_number',
      'role',
      'password',
    ];

    const emptyFields: string[] = [];

    managerFields.forEach(field => {
      if (data[field].length === 0 && field !== 'password') {
        emptyFields.push(field);
      }

      if (
        field === 'password' &&
        data.password.length < 8 &&
        data.password.length > 0
      ) {
        emptyFields.push('password');
      }
    });

    const emailValidation = regexValidation('email', updatedUserData.email);

    if (!emailValidation.isValidEmail) {
      emptyFields.push('email');
    }

    return emptyFields;
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updatedUserData.phone_number = '';
    setCountryCode(event.target.value);
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'user_image') {
      // Check if the event is from file input and has files
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const file = event.target.files[0];
        if (file) {
          setUpdatedUserData({ ...updatedUserData, [name]: file });
        }
      }
    } else if (name === 'role') {
      setUpdatedUserData({ ...updatedUserData, [name]: value });
    } else if (name === 'phone_number') {
      // Validate phone number input to allow only numeric characters
      let numericValue = value.replace(/\D/g, '');
      numericValue = '+' + numericValue;

      setUpdatedUserData({ ...updatedUserData, [name]: numericValue });
    } else if (name === 'password') {
      // Trim password input
      const trimmedPassword = value.trim();

      setUpdatedUserData({ ...updatedUserData, [name]: trimmedPassword });
    } else {
      // Handle all other inputs
      setUpdatedUserData({ ...updatedUserData, [name]: value });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    setIsLoading(true);

    const invalidFields = validateNewUserData(updatedUserData);

    setInvalidFieldsInputArray(invalidFields);

    if (invalidFields.length === 0) {
      try {
        const formData = new FormData();

        if (updatedUserData.user_image instanceof File) {
          const resizedImage = await resizeImage(updatedUserData.user_image);
          formData.append('user_image', resizedImage);
        } else {
          // console.log('No file selected or incorrect file format');
        }

        formData.append('id', updatedUserData.id);
        formData.append('full_name', updatedUserData.full_name);
        formData.append('email', updatedUserData.email);
        formData.append('password', updatedUserData.password);
        formData.append(
          'property_ids',
          JSON.stringify(updatedUserData.property_ids)
        );
        formData.append('phone_number', updatedUserData.phone_number);
        formData.append('role', updatedUserData.role);

        const response = await axios.put(
          `${apiRoute}/dashboard/users/edit-user`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        if (response.status === 200) {
          // console.log('User successfully added:', response.data);
          navigate('users');
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        setIsLoading(false);
        setUpdatedUserData(UserInitialState);
        setInvalidFieldsInputArray([]);
      }
    } else {
      // console.log('Form validation failed');
      setIsLoading(false);
    }
  };

  const showCheckboxes = () => {
    setIsPropertiesCheckboxesOpened(!isPropertiesCheckboxesOpened);
  };

  const clearData = () => {
    setUpdatedUserData(UserInitialState);
    setInvalidFieldsInputArray([]);
    navigate('users');
  };

  useEffect(() => {
    if (updatedUserData.email === '' || !updatedUserData.email) {
      navigate('users');
    }
  }, [editableUserData]);

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (
        checkboxesContainerRef.current &&
        !checkboxesContainerRef.current.contains(event.target)
      ) {
        // Clicked outside the container, so close it
        setIsPropertiesCheckboxesOpened(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (propertyId: string, isChecked: boolean) => {
    if (updatedUserData.role === 'employee') {
      if (isChecked) {
        setUpdatedUserData(updatedUserData => ({
          ...updatedUserData,
          property_ids: [Number(propertyId)],
        }));
      } else {
        setUpdatedUserData(updatedUserData => ({
          ...updatedUserData,
          property_ids: [
            ...updatedUserData.property_ids.filter(
              item => item !== Number(propertyId)
            ),
          ],
        }));
      }
    } else {
      if (isChecked) {
        setUpdatedUserData(updatedUserData => ({
          ...updatedUserData,
          property_ids: [...updatedUserData.property_ids, Number(propertyId)],
        }));
      } else {
        setUpdatedUserData(updatedUserData => ({
          ...updatedUserData,
          property_ids: [
            ...updatedUserData.property_ids.filter(
              item => item !== Number(propertyId)
            ),
          ],
        }));
      }
    }
  };

  useRedirectOnBack('/dashboard');

  useEffect(() => {
    if (
      typeof updatedUserData.user_image === 'object' &&
      updatedUserData.user_image !== null
    ) {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target) {
          setImageSrc(event.target.result as string);
        }
      };

      reader.readAsDataURL(updatedUserData.user_image as File);
    }
  }, [updatedUserData.user_image]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const isBuildingData = (obj: any): obj is BuildingData => {
    return (
      obj &&
      typeof obj === 'object' &&
      'property_name' in obj &&
      'address' in obj
    );
  };

  return (
    <DashboardContent id="dashboard-edit-building-form" isAddNewUser={true}>
      <Title>
        <Text mt={16} ml={16} mb={0} fontSize={28} fontWeight={700}>
          Edit user
        </Text>
        <Text mt={6} ml={16} mb={0} fontSize={14} color={`${colors.grey}`}>
          Please update the user
        </Text>
      </Title>

      <AddNewUserFormContainer>
        <AddNewUserForm>
          {isLoading && <Spinner isAddNewPropertyForm={true} />}

          <InputContainer isRoleField={true}>
            <CheckRingContainer>
              <CheckRing />
            </CheckRingContainer>
            <StyledSelectMenu
              name="role"
              value={updatedUserData.role}
              onChange={handleInputChange}
              isRoleField={true}
            >
              <option value={'admin'}>admin</option>
              <option value={'manager'}>manager</option>
              <option value={'employee'}>employee</option>
            </StyledSelectMenu>
          </InputContainer>

          <InputContainer isImageUpload={true}>
            <ImageUploadContainer>
              {imageSrc ? (
                <StyledImage src={imageSrc} alt="User Image" isAddNewUserForm />
              ) : (
                <UploadImageIcon />
              )}
              <Label isImageUpload={true}>Upload photo</Label>
            </ImageUploadContainer>

            <StyledUserInput
              type="file"
              name="user_image"
              accept="image/*"
              placeholder={'Enter image url'}
              onChange={handleInputChange}
              isImageUpload={true}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('user_image') && (
            <ErrorLabel>Please upload a photo*</ErrorLabel>
          )}

          <>
            <InputContainer>
              <Label>User name</Label>
              <StyledUserInput
                type="text"
                name="full_name"
                value={updatedUserData.full_name}
                placeholder={'Enter first and last name'}
                onChange={handleInputChange}
              />
            </InputContainer>
            {invalidFieldsInputArray.includes('full_name') && (
              <ErrorLabel>Please enter first and last name*</ErrorLabel>
            )}
          </>

          <InputContainer>
            <Label>Email</Label>
            <StyledUserInput
              type="email"
              name="email"
              value={updatedUserData.email}
              placeholder={'Enter email address'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('email') && (
            <ErrorLabel>Please enter a valid email address*</ErrorLabel>
          )}

          <InputContainer isPasswordInput={true}>
            <Label>Password</Label>
            <StyledUserInput
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={updatedUserData.password}
              placeholder={'Enter password'}
              onChange={handleInputChange}
              style={{ position: 'relative' }}
            />
            <i
              style={eyeIconStyles}
              onClick={() => setShowPassword(!showPassword)}
              className={showPassword ? 'far fa-eye' : `far fa-eye-slash`}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('password') && (
            <ErrorLabel>
              Your password must be at least 8 characters*
            </ErrorLabel>
          )}

          <InputContainer>
            <Label>Phone</Label>
            <CountryCodeContainer>
              <CountryCodeSelect
                id="country-code"
                onChange={e => handleCountryCode(e)}
                name="countryCode"
                value={countryCode}
              >
                {Object.entries(codes).map(([country, code]) => (
                  <option key={code} value={code}>
                    {country}
                  </option>
                ))}
              </CountryCodeSelect>

              <StyledUserInput
                type="text"
                name="phone_number"
                value={updatedUserData.phone_number || countryCode}
                onChange={handleInputChange}
                isCountryCodeInput={true}
              />
            </CountryCodeContainer>
          </InputContainer>
          {invalidFieldsInputArray.includes('phone_number') && (
            <ErrorLabel>Please enter phone number*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Property selection</Label>

            <div style={{ position: 'relative' }} ref={checkboxesContainerRef}>
              <StyledSelectCheckboxes onClick={showCheckboxes}>
                {Array.isArray(updatedUserData.propertiesObjects) &&
                updatedUserData.propertiesObjects.length > 0
                  ? isBuildingData(updatedUserData.propertiesObjects[0])
                    ? `${
                        updatedUserData.propertiesObjects[0]?.property_name ??
                        'Property'
                      },${
                        updatedUserData.propertiesObjects[0]?.address ??
                        'Address'
                      }`
                    : 'Invalid BuildingData'
                  : 'Property selection'}
              </StyledSelectCheckboxes>
              {isPropertiesCheckboxesOpened && (
                <CheckboxesContainerInSelectMenu>
                  {properties.map((property, i) => (
                    <label key={i} style={{ marginBottom: '3px' }}>
                      <input
                        name="property_ids"
                        type="checkbox"
                        value={property.id}
                        checked={updatedUserData.property_ids.includes(
                          Number(property.id) as never
                        )}
                        onChange={e =>
                          handleCheckboxChange(property.id, e.target.checked)
                        }
                      />
                      {property.property_name}, {property.address}
                    </label>
                  ))}
                </CheckboxesContainerInSelectMenu>
              )}
            </div>
          </InputContainer>
          {invalidFieldsInputArray.includes('property_ids') && (
            <ErrorLabel>Please select at least one property*</ErrorLabel>
          )}
        </AddNewUserForm>
      </AddNewUserFormContainer>

      <ButtonsContainer>
        <CancelButton onClick={() => clearData()}>
          <ButtonTitle>Cancel</ButtonTitle>
        </CancelButton>
        <SaveButton onClick={onSubmit}>
          <ButtonTitle>Save</ButtonTitle>
          <RightArrow />
        </SaveButton>
      </ButtonsContainer>
    </DashboardContent>
  );
};
