import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useRedirectOnRefresh = (route = '/main-menu') => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location object

  useEffect(() => {
    // eslint-disable-next-line
    const handleBeforeUnload = (e: any) => {
      if (location.pathname === route) {
        // If we are on the main-menu route, do not execute any of the logic below
        return;
      }

      sessionStorage.setItem('shouldRedirectAfterRefresh', 'true');
      e.preventDefault();
      e.returnValue = 'Are you sure you want to refresh?';
    };

    if (sessionStorage.getItem('shouldRedirectAfterRefresh')) {
      navigate(route);
      sessionStorage.removeItem('shouldRedirectAfterRefresh');
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [navigate, location]); // Add location as a dependency
};

export default useRedirectOnRefresh;
