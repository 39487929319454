import React, { CSSProperties, useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  // Progress,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
} from '../../../layout/layout-container.styled';
import { Navbar } from '../../../layout/visitor-log/navbar';
import { NavButton } from '../../../nav-button/nav-button';
import { Text, font } from '../../../../style-utils/text';
import { TextEditor } from '../../../styled-quill/text-editor';
import { VisitorLogState } from '../../../hooks/use-visitor-log-handler';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { DailyLogState } from '../../../hooks/use-daily-log-handler';
import { Step } from '../../../step/step';
import { findPathIndex } from './controllers/find-path-index';
import { Progress } from '../../../progress/progress';
import { createItem } from '../../../requests/requests';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { colors } from '../../../../style-utils/colors';
import useRedirectOnRefresh from '../../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../../style-utils/use-window-height';

export const Notes: React.FC<DefaultComponentProps> = ({
  navigate,
  changeHandler,
  state,
  options,
  prevPath,
  nextPath,
  path,
  navigation,
  selectedOption,
  selectedBuilding,
}) => {
  const { notes } = state as VisitorLogState;
  const { additionalNotes, description } = state as DailyLogState;
  const isLog = path?.includes('/daily-log') || false;
  const isLastPath = '/main-menu' === nextPath ? true : false;
  const maxPaths = navigation?.length || 0;
  const currentPathIndex = findPathIndex(path, navigation);
  useRedirectOnRefresh();
  useRedirectOnBack();

  const override: CSSProperties = {
    zIndex: '9',
    display: 'block',
    position: 'absolute',
    borderColor: 'red',
    top: '43%',
    left: '43%',
  };

  const getComponentType = (options: string): string => {
    switch (options) {
      case 'DESCRIPTION':
        return 'description';
      case 'ADDITIONAL_NOTES':
        return 'additionalNotes';
      default:
        return 'notes';
    }
  };

  const getTitleFromComponentType = (options: string) => {
    switch (options) {
      case 'DESCRIPTION':
        return 'Description';
      case 'ADDITIONAL_NOTES':
        return 'Additional Notes';
      default:
        return 'Notes';
    }
  };

  const getStateFromType = (options: string) => {
    switch (options) {
      case 'DESCRIPTION':
        return description;
      case 'ADDITIONAL_NOTES':
        return additionalNotes;
      default:
        return notes;
    }
  };

  const type = getComponentType(options?.[0] || '');
  const title = getTitleFromComponentType(options?.[0] || '');
  const stateFromType = getStateFromType(options?.[0] || '');
  const [loading, setLoading] = useState(false);

  async function waitForThreeSeconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('3 seconds have passed');
      }, 3000);
    });
  }

  const height = useWindowHeight();

  return loading ? (
    <ClimbingBoxLoader
      color={colors.purple_dark}
      loading={loading}
      cssOverride={override}
      size={50}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  ) : (
    // different render for each component type
    <LayoutContainer height={height} key={type}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        selectedOption={selectedOption}
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer>
          <Step currentPathIndex={currentPathIndex} maxPaths={maxPaths || 0} />
          <Progress
            currentPathIndex={currentPathIndex}
            maxPaths={maxPaths || 0}
          />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer>
            <Navbar
              path={path}
              navigation={navigation}
              state={state}
              isLog={isLog}
              navigate={navigate}
              currentPage={path}
              hasContent
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column">
              <Text fontSize={font.size.xl} fontWeight={700} mt={0} mb={10}>
                {title}
              </Text>
              <Text
                color={font.color.gray}
                fontSize={font.size.xs}
                mt={0}
                mb={0}
              >
                Enter {title}{' '}
              </Text>
            </TitleAndSubtitle>
            <ContentButtonsWrapper>
              <TextEditor
                changeHandlerFor={type}
                defaultValue={stateFromType}
                changeHandler={changeHandler}
              />
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => navigate(prevPath, { replace: false })}
              />
              <NavButton
                disabled={false}
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={isLastPath ? 'Finish' : 'Next'}
                onClick={
                  isLastPath
                    ? async () => {
                        setLoading(true);
                        await createItem(
                          isLog ? '/daily-log' : '/visitor-log',
                          state
                        );

                        await waitForThreeSeconds();

                        navigate(nextPath, { replace: false });
                      }
                    : () => navigate(nextPath, { replace: false })
                }
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
