export const codes = {
  USA: '+1',
  CHN: '+86',
  IND: '+91',
  IDN: '+62',
  PAK: '+92',
  BRA: '+55',
  NGA: '+234',
  BGD: '+880',
  RUS: '+7',
  MEX: '+52',
  JPN: '+81',
  ETH: '+251',
  PHL: '+63',
  EGY: '+20',
  VNM: '+84',
  COD: '+243',
  TUR: '+90',
  IRN: '+98',
  DEU: '+49',
  THA: '+66',
  GBR: '+44',
  FRA: '+33',
  ITA: '+39',
  ESP: '+34',
  UKR: '+380',
  NMK: '+389',
  POL: '+48',
  ROU: '+40',
  NLD: '+31',
  BEL: '+32',
  CZE: '+420',
  GRC: '+30',
  PRT: '+351',
  SWE: '+46',
  HUN: '+36',
  BLR: '+375',
  AUT: '+43',
  CHE: '+41',
  BGR: '+359',
  DNK: '+45',
  FIN: '+358',
  NOR: '+47',
  IRL: '+353',
};

export const getCountryNames = () => {
  return Object.keys(codes);
};
