import { Notes } from '../../components/pages/visitor-log/shared/notes';

export const getLeasingOfficeRoutes = () => {
  const base = '/daily-log/leasing-office/';

  return [
    {
      path: `${base}description`,
      component: Notes,
      options: ['DESCRIPTION'],
    },
    {
      path: `${base}additional-notes`,
      component: Notes,
      options: ['ADDITIONAL_NOTES'],
    },
  ];
};
