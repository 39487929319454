/* eslint-disable */
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { EnumCapturedResultItemType } from 'dynamsoft-core';
import { DecodedBarcodesResult, EnumBarcodeFormat } from 'dynamsoft-barcode-reader';
import { CameraEnhancer, CameraView } from 'dynamsoft-camera-enhancer';
import {
  CapturedResultReceiver,
  CaptureVisionRouter,
} from 'dynamsoft-capture-vision-router';
import { MultiFrameResultCrossFilter } from 'dynamsoft-utility';
import '../../cvr'; // import side effects. The license, engineResourcePath, so on.
import './VideoCapture.css';
import { ButtonContainer } from '../login/login.styled';
import { Text } from '../../style-utils/text';
import { useRedirectOnBack } from '../hooks/use-redirect-on-back';
import useRedirectOnRefresh from '../hooks/use-redirect-on-refresh';

interface VideoCaptureProps {
  setResultsFromScan: Dispatch<SetStateAction<any>>;
  setIsCameraMode: Dispatch<SetStateAction<boolean>>;
}

const VideoCapture: React.FC<VideoCaptureProps> = ({
  setResultsFromScan,
  setIsCameraMode
}) => {
  const uiContainer = useRef<HTMLDivElement>(null);
  const resultsContainer = useRef<HTMLDivElement>(null);

  const pInit = useRef(
    null as Promise<{
      cameraView: CameraView;
      cameraEnhancer: CameraEnhancer;
      router: CaptureVisionRouter;
    }> | null
  );
  const pDestroy = useRef(null as Promise<void> | null);

  const init = async (): Promise<{
    cameraView: CameraView;
    cameraEnhancer: CameraEnhancer;
    router: CaptureVisionRouter;
  }> => {
    try {
      // Create a `CameraEnhancer` instance for camera control and a `CameraView` instance for UI control.
      const cameraView = await CameraView.createInstance();
      const cameraEnhancer = await CameraEnhancer.createInstance(cameraView);
      uiContainer.current!.innerText = '';
      uiContainer.current!.append(cameraView.getUIElement()); // Get default UI and append it to DOM.

      cameraEnhancer.setScanRegion({
        x: 15,
        y: 25,
        width: 70,
        height: 50,
        isMeasuredInPercentage: true,
      });
      await cameraEnhancer.setResolution({ width: 1920, height: 1080 });

      // Create a `CaptureVisionRouter` instance and set `CameraEnhancer` instance as its image source.
      const router = await CaptureVisionRouter.createInstance();
      router.setInput(cameraEnhancer);

      // Define a callback for results.
      const resultReceiver = new CapturedResultReceiver();
      resultReceiver.onDecodedBarcodesReceived = (
        result: DecodedBarcodesResult
      ) => {
        if (!result.barcodeResultItems.length) return;

        setResultsFromScan(result as unknown as string)
        for (const item of result.barcodeResultItems) {
          resultsContainer.current!.append(
            `${item.formatString}: ${item.text}`,
            document.createElement('br'),
            document.createElement('hr')
          );
        }
      };
      router.addResultReceiver(resultReceiver);

      // Filter out unchecked and duplicate results.
      const filter = new MultiFrameResultCrossFilter();
      filter.enableResultCrossVerification(
        EnumCapturedResultItemType.CRIT_BARCODE,
        true
      ); // Filter out unchecked barcodes.
      // Filter out duplicate barcodes within 3 seconds.
      filter.enableResultDeduplication(
        EnumCapturedResultItemType.CRIT_BARCODE,
        true
      );
      filter.setDuplicateForgetTime(
        EnumCapturedResultItemType.CRIT_BARCODE,
        3000
      );
      await router.addResultFilter(filter);

      // Open camera and start scanning single barcode.
      await cameraEnhancer.open();

      let settings = await router.getSimplifiedSettings("ReadDenseBarcodes");
      settings.barcodeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
      await router.updateSettings("ReadDenseBarcodes", settings);
      await router.startCapturing('ReadDenseBarcodes');

      return {
        cameraView,
        cameraEnhancer,
        router,
      };
    } catch (ex: any) {
      const errMsg = ex.message || ex;
      console.error(errMsg);
      alert(errMsg);
      throw ex;
    }
  };

  const destroy = async (): Promise<void> => {
    if (pInit.current) {
      const { cameraView, cameraEnhancer, router } = await pInit.current;
      router.dispose();
      cameraEnhancer.dispose();
      cameraView.dispose();
    }
  };

  useEffect(() => {
    (async () => {
      // In 'development', React runs setup and cleanup one extra time before the actual setup in Strict Mode.
      if (pDestroy.current) {
        await pDestroy.current;
        pInit.current = init();
      } else {
        pInit.current = init();
      }
    })();

    return () => {
      (async () => {
        await (pDestroy.current = destroy());
        console.log('VideoCapture Component Unmount');
      })();
    };
  }, []);

  useRedirectOnBack();
  useRedirectOnRefresh();

  return (
    <div>
      <div ref={uiContainer} className="div-ui-container"></div>
      <center>
        <ButtonContainer onClick={() => {
          setResultsFromScan([])
          setIsCameraMode(false);
        }}>
          <Text fontWeight={700}>Back</Text>
        </ButtonContainer>
      </center>
      <div ref={resultsContainer} className="div-results-container"></div>
    </div>
  );
}

export default VideoCapture;