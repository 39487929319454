import React from 'react';

export const RightArrow = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7L16.3536 6.64645L16.7071 7L16.3536 7.35355L16 7ZM1 7.5C0.723858 7.5 0.5 7.27614 0.5 7C0.5 6.72386 0.723858 6.5 1 6.5V7.5ZM10.3536 0.646447L16.3536 6.64645L15.6464 7.35355L9.64645 1.35355L10.3536 0.646447ZM16.3536 7.35355L10.3536 13.3536L9.64645 12.6464L15.6464 6.64645L16.3536 7.35355ZM16 7.5H1V6.5H16V7.5Z"
        fill="white"
      />
    </svg>
  );
};
