import {
  DELIVERY_NAVIGATION,
  EMERGENCY_NAVIGATION,
  GUEST_NAVIGATION,
  LEASING_OFFICE_NAVIGATION,
  MAINTENANCE_NAVIGATION,
  MISSCELANEAUS_NAVIGATION,
  PROSPECT_NAVIGATION,
  RESIDENT_NAVIGATION,
  SHIFT_CHANGE_NAVIGATION,
  VENDOR_NAVIGATION,
} from './navigation';

export enum VISITOR_TYPES {
  GUEST = 'Guest',
  PROSPECT = 'Prospect',
  VENDOR = 'Vendor',
  DELIVERY = 'Delivery',
  EMERGENCY = 'Emergency (911)',
  MAINTENANCE = 'Maintenance',
  LEASING_OFFICE = 'Leasing Office',
  RESIDENT = 'Resident',
  MISSCELANEAUS = 'Miscellaneous',
  SHIFT_CHANGE = 'Shift Change',
}
// old
// MISCALLANEOUS
// 'Misscelaneaus',
// new
// MISSCELANEAUS = 'Misscelaneaus',

export const getNavigationFromVisitorType = (visitorType: string | null) => {
  switch (visitorType) {
    case '':
      return [];
    case undefined:
      return [];
    case VISITOR_TYPES.GUEST:
      return GUEST_NAVIGATION;

    case VISITOR_TYPES.PROSPECT:
      return PROSPECT_NAVIGATION;

    case VISITOR_TYPES.VENDOR:
      return VENDOR_NAVIGATION;

    case VISITOR_TYPES.DELIVERY:
      return DELIVERY_NAVIGATION;

    case VISITOR_TYPES.EMERGENCY:
      return EMERGENCY_NAVIGATION;

    case VISITOR_TYPES.MAINTENANCE:
      return MAINTENANCE_NAVIGATION;

    case VISITOR_TYPES.LEASING_OFFICE:
      return LEASING_OFFICE_NAVIGATION;

    case VISITOR_TYPES.RESIDENT:
      return RESIDENT_NAVIGATION;

    case VISITOR_TYPES.MISSCELANEAUS:
      return MISSCELANEAUS_NAVIGATION;

    case VISITOR_TYPES.SHIFT_CHANGE:
      return SHIFT_CHANGE_NAVIGATION;

    default:
      return [
        'Check getNavigationFromVisitorType the parameter is not defined in the case',
      ];
  }
};

export const getTitlesFromRoute = (route: string): string | null => {
  switch (true) {
    case route.includes('/main-menu'):
      return null;
    case route.includes('/visitor-details'):
      return 'Visitor Details';
    case route.includes('/number-of-guests'):
      return 'Number of Guests';
    case route.includes('/apartment-location'):
      return 'Visitor Location';
    case route.includes('/approval'):
      return 'Approval';
    case route.includes('/parking-validation'):
      return 'Parking Validation';
    case route.includes('/notes'):
      return 'Notes';
    case route.includes('/id'):
      return 'ID (Name and Surname)';
    case route.includes('/priority'):
      return 'Priority';
    case route.includes('/description'):
      return 'Description';
    case route.includes('/additional-notes'):
      return 'Additional Notes';
    default:
      return 'Check getTitlesFromRoute the paramater is not defined in the case';
  }
};
