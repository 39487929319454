import React, { useCallback, useEffect, useState } from 'react';
import {
  ContentAndButtonsContainer,
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
} from '../../layout/layout-container.styled';
import { Sidebar } from '../../layout/sidebar/sidebar';
import { ButtonsContainer } from '../dashboard/dashboard-users-components/dashboard-users.styled';
import { NavButton } from '../../nav-button/nav-button';
import { DefaultComponentProps } from '../../app-router/component-props';
import { User } from '../../../interfaces/user-interfaces';
import { useAuth } from '../../../context/auth-provider';
import { Text, font } from '../../../style-utils/text';
import {
  DashboardHomeButton,
  DashboardWrapper,
} from '../dashboard/dashboard-home/dashboard-home.styled';
import { ButtonsWrapper, ContentContainer } from './view-daily-report.styled';
import { ReportsTable } from './report-table';
import useBuildingData from '../dashboard/dashboard-home/use-get-building-data';
import { fetchAllItems } from '../../requests/requests';
import { Spinner } from '../../icons/spinner';
import { useWindowHeight } from '../../../style-utils/use-window-height';

export const ViewDailyReport: React.FC<DefaultComponentProps> = ({
  navigate,
}) => {
  const { user }: { user: User | null | undefined } = useAuth();
  const [isVisitorLog, setIsVisitorLog] = useState(false);

  // Using useCallback to ensure that the date value is stable across re-renders
  const getDate = useCallback(() => {
    const now = new Date();

    return new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
  }, []);

  // eslint-disable-next-line
  const [date, setDate] = useState(getDate);

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [logs, setLogs] = useState<any>();

  const { selectedBuilding } = useBuildingData(user);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const formattedDate = date.toISOString().split('T')[0];

        if (selectedBuilding) {
          setLoading(true);
          const response = await fetchAllItems(
            `logs?buildingId=${selectedBuilding.id}&date=${formattedDate}`
          );
          setLogs(response);
        }
      } catch (error) {
        console.error('Failed to fetch logs:', error);
      } finally {
        setLoading(false);
      }
    };

    // Trigger fetchLogs only if selectedBuilding and date are present
    if (selectedBuilding && date) {
      fetchLogs();
    }
  }, [selectedBuilding, date]); // Dependencies are selectedBuilding and date

  const height = useWindowHeight();

  if (loading) {
    return (
      <DashboardWrapper>
        <Spinner />
      </DashboardWrapper>
    );
  }

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        path={undefined}
        selectedOption={''}
        navigate={navigate}
      />
      <ContentWrapper>
        <NavbarAndContentContainer>
          <ContentAndButtonsContainer dashboard>
            <ButtonsWrapper>
              <DashboardHomeButton
                isSelected={true}
                onClick={() => setIsVisitorLog(!isVisitorLog)}
              >
                <Text
                  width={'100'}
                  fontFamily={font.family}
                  fontSize={font.size.xs}
                  fontWeight={600}
                  mb={0}
                  mt={0}
                >
                  {isVisitorLog ? 'See Daily Logs' : 'See Visitor Logs'}
                </Text>
              </DashboardHomeButton>
            </ButtonsWrapper>
            <ContentContainer>
              <ReportsTable
                log={isVisitorLog ? logs?.visitorLogs : logs?.dailyLogs}
              />
            </ContentContainer>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => {
                  navigate('/main-menu', { replace: false });
                }}
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
