import { useState, useEffect } from 'react';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import { createItem, fetchAllItems } from '../../../requests/requests';
import { User } from '../../../../interfaces/user-interfaces';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import {
  processDailyData,
  processMonthlyData,
  processVisitorData,
  processWeeklyData,
} from './format-analytics-data';

export enum TimePeriod {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export const extractBuildingNames = (data: BuildingData[]): string[] => {
  return data.map(building => building.property_name);
};

export const extractBuildingIds = (data: BuildingData[]): string[] => {
  return data.map(building => building.id);
};

const useBuildingData = (user: User | null | undefined) => {
  /* eslint-disable */
  const [dailyData, setDailyData] = useState<any>([]);
  const [weeklyData, setWeeklyData] = useState<any>([]);
  const [monthlyData, setMonthlyData] = useState<any>([]);
  /* eslint-enable */
  const [buildingData, setBuildingData] = useState<BuildingData[]>([]);
  const [buildingNames, setBuildingNames] = useState<string[]>([]);
  const [buildingIds, setBuildingIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Daily);
  const [selectedBuilding, setSelectedBuilding] = useState<BuildingData | null>(
    null
  );
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | null>(
    null
  );

  const fetchPropertiesForAdmin = async (): Promise<BuildingData[]> => {
    const response = await fetchAllItems('dashboard/buildings');

    return response as BuildingData[];
  };

  const fetchPropertiesForManager = async (
    user: User
  ): Promise<BuildingData[]> => {
    const response = await createItem('dashboard/buildings', user);

    return response as BuildingData[];
  };

  const getPropertiesfromDB = async () => {
    setLoading(true);
    try {
      let data: BuildingData[] = [];

      if (user) {
        if (user.role === 'admin') {
          data = await fetchPropertiesForAdmin();
        } else {
          data = await fetchPropertiesForManager(user);
        }
      }

      if (Array.isArray(data) && data.every(item => typeof item === 'object')) {
        setBuildingData(data);
        const names = extractBuildingNames(data);
        const ids = extractBuildingIds(data);
        setBuildingNames(names);
        setBuildingIds(ids);
        setSelectedBuilding(data[0] || null);
      } else {
        console.error('Received data is not in the expected format.');
      }
    } catch (error) {
      console.error('Failed to fetch buildings data:', error);
    } finally {
      setLoading(false);
    }
  };

  const changeHandler = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | string,
    fieldName?: string,
    explicitValue?: string
  ) => {
    let value: string;

    if (explicitValue !== undefined) {
      value = explicitValue;
    } else if (typeof e === 'string') {
      value = e;
    } else if ('value' in e.target) {
      value = e.target.value;
    } else {
      throw new Error('Unable to determine the value to set.');
    }

    if (fieldName === 'selectedBuilding') {
      const matchingBuilding = buildingData.find(
        b => b.property_name === value
      );
      setSelectedBuilding(matchingBuilding || null);
    }
  };

  useEffect(() => {
    getPropertiesfromDB();
  }, [user]);

  const fetchAnalyticsForSelectedBuilding = async (period: string) => {
    if (selectedBuilding?.id && parseInt(selectedBuilding?.id)) {
      try {
        const response = await axios.get(
          `${apiRoute}/analytics/${period}/${selectedBuilding?.id}`
        );
        const initialProcess = processVisitorData(response.data);

        switch (period) {
          case 'daily':
            const daily = processDailyData(initialProcess);
            setDailyData(daily);
            break;
          case 'weekly':
            const weekly = processWeeklyData(initialProcess);
            setWeeklyData(weekly);
            break;
          case 'monthly':
            const monthly = processMonthlyData(initialProcess);
            setMonthlyData(monthly);
            break;
          default:
            console.error('Invalid analytics period:', period);
            break;
        }
      } catch (error) {
        console.error(`Error fetching ${period} data:`, error);
      }
    }
  };

  useEffect(() => {
    switch (timePeriod) {
      case TimePeriod.Daily:
        fetchAnalyticsForSelectedBuilding('daily');

        break;
      case TimePeriod.Weekly:
        fetchAnalyticsForSelectedBuilding('weekly');

        break;
      case TimePeriod.Monthly:
        fetchAnalyticsForSelectedBuilding('monthly');

        break;
      default:
        console.error('Invalid time period:', timePeriod);
        break;
    }
  }, [selectedBuilding?.id, timePeriod]);

  useEffect(() => {
    setSelectedBuildingId(selectedBuilding ? selectedBuilding.id : null);
  }, [selectedBuilding]);

  return {
    changeHandler,
    selectedBuilding,
    buildingData,
    buildingNames,
    buildingIds,
    loading,
    timePeriod,
    setTimePeriod,
    dailyData,
    monthlyData,
    weeklyData,
    selectedBuildingId,
  };
};

export default useBuildingData;
