import { Approval } from '../../components/pages/visitor-log/shared/approval';
import { Id } from '../../components/pages/visitor-log/shared/id';
import { Location } from '../../components/pages/visitor-log/shared/location';
import { Notes } from '../../components/pages/visitor-log/shared/notes';
import { NumberOfGuests } from '../../components/pages/visitor-log/shared/number-of-people';
import { ParkingTicketValidation } from '../../components/pages/visitor-log/shared/parking-ticket-validation';
import { VisitorDetails } from '../../components/pages/visitor-log/shared/visitor-details';

export const getGuestRoutes = () => {
  const base = '/visitor-log/guest/';

  return [
    { path: `${base}approval`, component: Approval },
    { path: `${base}id`, component: Id },
    { path: `${base}apartment-location`, component: Location },
    { path: `${base}notes`, component: Notes },
    { path: `${base}number-of-guests`, component: NumberOfGuests },
    {
      path: `${base}parking-validation`,
      component: ParkingTicketValidation,
    },
    { path: `${base}visitor-details`, component: VisitorDetails },
  ];
};
