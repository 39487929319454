import React, { useEffect, useState } from 'react';
import { codes } from '../../../../constants/country-codes';
import { NewBuildingData } from '../../../../interfaces/building-interfaces';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import { RightArrow } from '../../../icons/right-arrow';
import { Spinner } from '../../../icons/spinner';
import { UploadImageIcon } from '../../../icons/upload-image';
import {
  AddNewPropertyForm,
  AddNewPropertyFormContainer,
  SaveButton,
  ButtonTitle,
  CountryCodeContainer,
  CountryCodeSelect,
  DashboardContent,
  ImageUploadContainer,
  InputContainer,
  Label,
  StyledBuildingInput,
  Title,
  ButtonsContainer,
  CancelButton,
  ErrorLabel,
  StyledImage,
} from './dashboard-buildings.styled';
import { regexValidation } from '../../../../function-utils/regex-validation';
import { useNavigate } from 'react-router-dom';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import Resizer from 'react-image-file-resizer';

export const resizeImage = (file: File): Promise<Blob> => {
  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file, // the file from input
      800, // max width
      800, // max height
      'JPEG', // compress format
      100, // quality
      0, // rotation
      uri => {
        // Assert the uri as Blob
        resolve(uri as Blob);
      },
      'blob' // output type as blob to append directly to formData
    );
  });
};

const newBuildingInitialState: NewBuildingData = {
  property_image: null,
  property_name: '',
  contact_person: '',
  second_contact_person: '',
  position: '',
  country: 'USA',
  address: '',
  email: '',
  second_email: '',
  third_email: '',
  fourth_email: '',
  fifth_email: '',
  sixth_email: '',
  seventh_email: '',
  eight_email: '',
  ninth_email: '',
  tenth_email: '',
  phone_number: '',
  second_phone_number: '',
};

const numberOfEmailsObject = {
  second_email: 'second_email',
  third_email: 'third_email',
  fourth_email: 'fourth_email',
  fifth_email: 'fifth_email',
  sixth_email: 'sixth_email',
  seventh_email: 'seventh_email',
  eight_email: 'eight_email',
  ninth_email: 'ninth_email',
  tenth_email: 'tenth_email',
};

const initialVisibilityEmailInputs = {
  second_email: false,
  third_email: false,
  fourth_email: false,
  fifth_email: false,
  sixth_email: false,
  seventh_email: false,
  eight_email: false,
  ninth_email: false,
  tenth_email: false,
};

export const DashboardAddNewBuilding = () => {
  const [newBuildingData, setNewBuildingData] = useState<NewBuildingData>(
    newBuildingInitialState
  );
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [secondCountryCode, setSecondCountryCode] = useState<string>('+1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openNewContactInput, setOpenNewContactInput] =
    useState<boolean>(false);
  const [openNewEmailInput, setOpenNewEmailInput] = useState<boolean>(false);
  const [emailInputs, setEmailInputs] = useState<{ [key: string]: boolean }>(
    initialVisibilityEmailInputs
  );
  const [
    numberOfEmailInputsToBeDisplayed,
    setNumberOfEmailInputsToBeDisplayed,
  ] = useState<string[]>(['second_email']);
  const [openNewPhoneInput, setOpenNewPhoneInput] = useState<boolean>(false);
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const navigate = useNavigate();

  const openNewInput = (inputType: string) => {
    if (inputType === 'new-contact') {
      setOpenNewContactInput(!openNewContactInput);
    } else if (inputType === 'new-email') {
      setOpenNewEmailInput(!openNewEmailInput);
    } else if (inputType === 'new-phone') {
      setOpenNewPhoneInput(!openNewPhoneInput);
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewBuildingData = (data: any) => {
    const requiredFields: string[] = [
      'property_name',
      'contact_person',
      'position',
      'country',
      'address',
      'phone_number',
    ];

    const emptyFields: string[] = [];

    requiredFields.forEach(field => {
      if (data[field].length === 0) {
        emptyFields.push(field);
      }
    });

    const emailValidation = regexValidation('email', newBuildingData.email);

    if (!emailValidation.isValidEmail) {
      emptyFields.push('email');
    }

    Object.keys(numberOfEmailsObject).forEach(numberOfEmail => {
      const emailNumber = newBuildingData[numberOfEmail];
      if (typeof emailNumber === 'string' && emailNumber.length > 0) {
        const emailValidation = regexValidation(
          'email',
          newBuildingData[numberOfEmail] as string
        );

        if (!emailValidation.isValidEmail) {
          emptyFields.push(numberOfEmail as string);
        }
      }
    });

    return emptyFields;
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    newBuildingData.phone_number = '';
    setCountryCode(event.target.value);
  };

  const handleSecondInputCountryCode = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    newBuildingData.second_phone_number = '';
    setSecondCountryCode(event.target.value);
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'property_image') {
      // Check if the event is from file input and has files
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const file = event.target.files[0];
        if (file) {
          setNewBuildingData({ ...newBuildingData, [name]: file });
        }
      }
    } else if (name === 'phone_number' || name === 'second_phone_number') {
      // Remove non-numeric characters
      let numericValue = value.replace(/\D/g, '');
      numericValue = '+' + numericValue;

      setNewBuildingData({ ...newBuildingData, [name]: numericValue });
    } else {
      setNewBuildingData({ ...newBuildingData, [name]: value });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    setIsLoading(true);

    const invalidFields = validateNewBuildingData(newBuildingData);

    setInvalidFieldsInputArray(invalidFields);

    if (invalidFields.length === 0) {
      try {
        const formData = new FormData();

        if (newBuildingData.property_image instanceof File) {
          const resizedImage = await resizeImage(
            newBuildingData.property_image
          );
          formData.append('property_image', resizedImage);
        } else {
          console.log('No file selected or incorrect file format');
        }

        formData.append('property_name', newBuildingData.property_name);
        formData.append('contact_person', newBuildingData.contact_person);
        formData.append('position', newBuildingData.position);
        formData.append('country', newBuildingData.country);
        formData.append('address', newBuildingData.address);
        formData.append('email', newBuildingData.email);
        formData.append('phone_number', newBuildingData.phone_number);
        formData.append(
          'second_contact_person',
          newBuildingData.second_contact_person
        );
        formData.append('second_email', newBuildingData.second_email);
        formData.append('third_email', newBuildingData.third_email);
        formData.append('fourth_email', newBuildingData.fourth_email);
        formData.append('fifth_email', newBuildingData.fifth_email);
        formData.append('sixth_email', newBuildingData.sixth_email);
        formData.append('seventh_email', newBuildingData.seventh_email);
        formData.append('eight_email', newBuildingData.eight_email);
        formData.append('ninth_email', newBuildingData.ninth_email);
        formData.append('tenth_email', newBuildingData.tenth_email);
        formData.append(
          'second_phone_number',
          newBuildingData.second_phone_number
        );

        const response = await axios.post(
          `${apiRoute}/dashboard/buildings/add-new-property`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        if (response.status === 200) {
          // console.log('Property successfully added:', response.data);
          navigate('users');
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        setIsLoading(false);
        setNewBuildingData(newBuildingInitialState);
        setInvalidFieldsInputArray([]);
        navigate('buildings');
      }
    } else {
      // console.log('Form validation failed');
      setIsLoading(false);
    }
  };

  const clearData = () => {
    setNewBuildingData(newBuildingInitialState);
    setInvalidFieldsInputArray([]);
    navigate('buildings');
  };

  useRedirectOnBack('/dashboard');

  useEffect(() => {
    if (newBuildingData.property_image !== null) {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target) {
          setImageSrc(event.target.result as string);
        }
      };

      reader.readAsDataURL(newBuildingData.property_image);
    }
  }, [newBuildingData.property_image]);

  const openEmailInput = (emailNumber: string, index: number) => {
    const nextEmailIndex = index + 1;
    if (nextEmailIndex < Object.keys(numberOfEmailsObject).length + 1) {
      const email = Object.keys(numberOfEmailsObject)[nextEmailIndex];

      // Update the state array by filtering out the previous string and then adding the new email
      setNumberOfEmailInputsToBeDisplayed(prevState => {
        const filteredArray = prevState.filter(
          prevEmail => prevEmail !== emailNumber
        );

        if (!emailInputs[email]) {
          return [...filteredArray, email];
        } else {
          return [...filteredArray];
        }
      });

      // Set the corresponding email input state to true
      setEmailInputs(prevState => ({
        ...prevState,
        [emailNumber]: true,
      }));
    }
  };

  const closeEmailInput = (emailNumber: string, index: number) => {
    const email = Object.keys(numberOfEmailsObject)[index];

    setNewBuildingData({ ...newBuildingData, [emailNumber]: '' });

    setNumberOfEmailInputsToBeDisplayed(prevState => {
      // Filter out the previous email
      const filteredArray = prevState.filter(
        prevEmail => prevEmail !== emailNumber
      );

      // Concatenate the filtered array with the new email
      return [...filteredArray, email];
    });

    setEmailInputs(prevState => ({
      ...prevState,
      [emailNumber]: false,
    }));
  };

  return (
    <DashboardContent
      id="dashboard-add-new-building-content"
      isAddNewBuilding={true}
    >
      <Title>
        <Text mt={16} ml={16} mb={0} fontSize={28} fontWeight={700}>
          Add new property
        </Text>
        <Text mt={6} ml={16} mb={0} fontSize={14} color={`${colors.grey}`}>
          Please fill in the form
        </Text>
      </Title>

      <AddNewPropertyFormContainer>
        <AddNewPropertyForm>
          {isLoading && <Spinner isAddNewPropertyForm={true} />}

          <InputContainer isImageUpload={true}>
            <ImageUploadContainer>
              {imageSrc !== null ? (
                <StyledImage
                  src={imageSrc}
                  alt="Property Image"
                  isAddNewPropertyForm
                />
              ) : (
                <UploadImageIcon />
              )}

              <Label isImageUpload={true}>Upload photo</Label>
            </ImageUploadContainer>

            <StyledBuildingInput
              type="file"
              name="property_image"
              accept="image/*"
              placeholder={'Enter image url'}
              onChange={handleInputChange}
              isImageUpload={true}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('property_image') && (
            <ErrorLabel>Please upload a photo*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Property name</Label>
            <StyledBuildingInput
              type="text"
              name="property_name"
              value={newBuildingData.property_name}
              placeholder={'Enter property name'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('property_name') && (
            <ErrorLabel>Please enter property name*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Contact person</Label>
            <StyledBuildingInput
              type="text"
              name="contact_person"
              value={newBuildingData.contact_person}
              placeholder={'Enter contact person'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('contact_person') && (
            <ErrorLabel>Please enter contact person*</ErrorLabel>
          )}
          {openNewContactInput && (
            <InputContainer>
              <Label>Second contact person</Label>
              <StyledBuildingInput
                type="text"
                name="second_contact_person"
                value={newBuildingData.second_contact_person}
                placeholder={'Enter second contact person'}
                onChange={handleInputChange}
              />
            </InputContainer>
          )}

          {!openNewContactInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-contact')}
            >
              + Add new contact person
            </Label>
          )}

          {openNewContactInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-contact')}
            >
              x Close new contact field
            </Label>
          )}

          <InputContainer>
            <Label>Position</Label>
            <StyledBuildingInput
              type="text"
              name="position"
              value={newBuildingData.position}
              placeholder={'Enter position'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('position') && (
            <ErrorLabel>Please enter position*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Address</Label>
            <CountryCodeContainer>
              <CountryCodeSelect
                id="country"
                name="country"
                onChange={handleInputChange}
                value={newBuildingData.country}
              >
                {Object.entries(codes).map(([country, code]) => (
                  <option key={code} value={country}>
                    {country}
                  </option>
                ))}
              </CountryCodeSelect>

              <StyledBuildingInput
                type="text"
                name="address"
                value={newBuildingData.address}
                placeholder={'Enter property location'}
                onChange={handleInputChange}
                isCountryCodeInput={true}
              />
            </CountryCodeContainer>
          </InputContainer>
          {invalidFieldsInputArray.includes('address') && (
            <ErrorLabel>Please enter address*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Email</Label>
            <StyledBuildingInput
              type="email"
              name="email"
              value={newBuildingData.email}
              placeholder={'Enter email address'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('email') && (
            <ErrorLabel>Please enter a valid email address*</ErrorLabel>
          )}

          {Object.keys(numberOfEmailsObject).map(
            (emailNumber, index) =>
              emailInputs[emailNumber] && (
                <>
                  <InputContainer key={emailNumber}>
                    <Label>
                      {emailNumber.charAt(0).toUpperCase() +
                        emailNumber.slice(1).replace('_', ' ')}{' '}
                      address
                    </Label>
                    <StyledBuildingInput
                      type="email"
                      name={emailNumber}
                      value={newBuildingData[emailNumber] as string}
                      placeholder={`Enter ${emailNumber.replace(
                        '_',
                        ' '
                      )} address`}
                      onChange={handleInputChange}
                    />
                  </InputContainer>
                  {invalidFieldsInputArray.includes(emailNumber) && (
                    <ErrorLabel>Please enter a valid email address*</ErrorLabel>
                  )}
                  <Label
                    isOpenningNewInput={true}
                    onClick={() => closeEmailInput(emailNumber, index)}
                  >
                    {`x Close ${emailNumber.replace('_', ' ')} field`}
                  </Label>
                </>
              )
          )}

          {/* Rendering additional email inputs if new email input is opened */}
          {Object.keys(numberOfEmailsObject).map(
            (emailNumber, index) =>
              numberOfEmailInputsToBeDisplayed.includes(emailNumber) && (
                <Label
                  isOpenningNewInput={true}
                  key={emailNumber}
                  onClick={() => openEmailInput(emailNumber, index)}
                >
                  {`+ Add ${emailNumber.replace('_', ' ')} address`}
                </Label>
              )
          )}

          <InputContainer>
            <Label>Phone</Label>
            <CountryCodeContainer>
              <CountryCodeSelect
                id="country-code"
                onChange={e => handleCountryCode(e)}
                name="countryCode"
                value={countryCode}
              >
                {Object.entries(codes).map(([country, code]) => (
                  <option key={code} value={code}>
                    {country}
                  </option>
                ))}
              </CountryCodeSelect>

              <StyledBuildingInput
                type="text"
                name="phone_number"
                value={newBuildingData.phone_number || countryCode}
                onChange={handleInputChange}
                isCountryCodeInput={true}
              />
            </CountryCodeContainer>
          </InputContainer>
          {invalidFieldsInputArray.includes('phone_number') && (
            <ErrorLabel>Please enter phone number*</ErrorLabel>
          )}

          {openNewPhoneInput && (
            <InputContainer>
              <Label>Second phone number</Label>
              <CountryCodeContainer>
                <CountryCodeSelect
                  id="country-code"
                  onChange={e => handleSecondInputCountryCode(e)}
                  name="secondCountryCode"
                  value={secondCountryCode}
                >
                  {Object.entries(codes).map(([country, code]) => (
                    <option key={code} value={code}>
                      {country}
                    </option>
                  ))}
                </CountryCodeSelect>

                <StyledBuildingInput
                  type="text"
                  name="second_phone_number"
                  value={
                    newBuildingData.second_phone_number || secondCountryCode
                  }
                  onChange={handleInputChange}
                  isCountryCodeInput={true}
                />
              </CountryCodeContainer>
            </InputContainer>
          )}
          {!openNewPhoneInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-phone')}
            >
              + Add new phone number
            </Label>
          )}
          {openNewPhoneInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-phone')}
            >
              x Close new phone field
            </Label>
          )}
        </AddNewPropertyForm>
      </AddNewPropertyFormContainer>
      <ButtonsContainer>
        <CancelButton onClick={() => clearData()}>
          <ButtonTitle>Cancel</ButtonTitle>
        </CancelButton>
        <SaveButton onClick={onSubmit}>
          <ButtonTitle>Save</ButtonTitle>
          <RightArrow />
        </SaveButton>
      </ButtonsContainer>
    </DashboardContent>
  );
};
