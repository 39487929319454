import React from 'react';
import {
  IconWrapper,
  StyledButtonWrapper,
  TextWrapper,
} from './styled-button.styled';
import { Text, font } from '../../style-utils/text';

interface StyledButtonProps {
  text: string;
  icon: React.ReactNode;
  isSelected: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  path?: string;
}

export const StyledButton: React.FC<StyledButtonProps> = ({
  text,
  icon,
  isSelected,
  onClick,
  path,
}) => {
  const isMainMenu = path && path === '/main-menu';

  return (
    <StyledButtonWrapper
      onClick={e => onClick && onClick(e)}
      isSelected={isSelected}
    >
      {isMainMenu ? (
        <>
          <IconWrapper>{icon}</IconWrapper>
          <TextWrapper>
            <Text
              align="center"
              fontWeight={isSelected ? 600 : 300}
              fontSize={font.size.m}
              color={isSelected ? 'black' : font.color.gray}
            >
              {text}
            </Text>
          </TextWrapper>
          <TextWrapper />
        </>
      ) : (
        <>
          <IconWrapper>{isSelected && icon}</IconWrapper>
          <TextWrapper>
            <Text
              align="center"
              fontWeight={isSelected ? 600 : 300}
              fontSize={font.size.m}
              color={isSelected ? 'black' : font.color.gray}
            >
              {text}
            </Text>
          </TextWrapper>
          <TextWrapper />
        </>
      )}
    </StyledButtonWrapper>
  );
};
