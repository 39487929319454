import { NameAndLastName } from '../../components/pages/visitor-log/shared/name-and-last-name';
import { Location } from '../../components/pages/visitor-log/shared/location';
import { Notes } from '../../components/pages/visitor-log/shared/notes';
import { Priority } from '../../components/pages/visitor-log/shared/priority';

export const getResidentRoutes = () => {
  const base = '/daily-log/resident/';

  return [
    { path: `${base}id`, component: NameAndLastName },
    {
      path: `${base}apartment-location`,
      component: Location,
    },
    {
      path: `${base}priority`,
      component: Priority,
      options: ['Low', 'Medium', 'High'],
    },
    {
      path: `${base}description`,
      component: Notes,
      options: ['DESCRIPTION'],
    },
    {
      path: `${base}additional-notes`,
      component: Notes,
      options: ['ADDITIONAL_NOTES'],
    },
  ];
};
