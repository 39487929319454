import styled from 'styled-components';

export const NavbarContainer = styled.div`
  margin: 80px 32px;
`;

export const NavTitle = styled.div`
  margin-bottom: 24px;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;
