import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonsWrapper,
  DashboardHomeButton,
  DashboardWrapper,
  TitleAndButtonsWrapper,
} from '../dashboard-home/dashboard-home.styled';
import { Column, Row } from '../../../layout/design-helpers';
import { UsersListing } from '../dashboard-users-components/dashboard-users.styled';
import { Title } from '../dashboard-buildings-components/dashboard-buildings.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { User } from '../../../../interfaces/user-interfaces';
import { useAuth } from '../../../../context/auth-provider';
import useBuildingData from '../dashboard-home/use-get-building-data';
import { OptionsInput } from '../../../styled-options/styled-options';
import { SelectDate } from './date-picker';
import { createItem, fetchAllItems } from '../../../requests/requests';
import { Spinner } from '../../../icons/spinner';
import { DashboardLogs } from './dashboard-logs';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { stripHtmlTags } from '../../../../function-utils/string-converter';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';

export const ViewEditExport = () => {
  const { user }: { user: User | null | undefined } = useAuth();
  const [date, setDate] = useState<Date | undefined>(undefined);
  const { buildingNames, selectedBuilding, changeHandler } =
    useBuildingData(user);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [logs, setLogs] = useState<any>();
  const [isVisitorLog, setIsVisitorLog] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null); // Define the ref here
  const [showExportOptions, setShowExportOptions] = useState(false);
  const { email, full_name } = user || {};

  const sendDailyReport = async () => {
    try {
      if (!selectedBuilding) {
        console.error('No building selected');
        toast.error('No building selected.');

        return; // Exit the function if no building is selected
      }
      const { id } = selectedBuilding;
      setLoading(true);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically detect client's timezone
      console.log('Client Time Zone:', timezone); // Optional: Log the detected timezone

      const response = await createItem('daily-report', {
        email,
        full_name,
        property_ids: id,
        timezone: timezone,
      });

      const res = response as AxiosResponse;
      if (res) {
        // console.log('Report sent successfully.', res); // Log successful response
        toast.success('Report sent!');
      } else {
        // console.log('No response from the server.'); // Log no response scenario
        toast.error('Failed to send report.');
      }
    } catch (error) {
      console.error('Error sending report:', error); // Log the error details
      toast.error('Error sending report.');
    } finally {
      setLoading(false);
      // console.log('Finalizing...'); // Log the finalization step
    }
  };

  const exportOptionsRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const handleClickOutside = (event: any) => {
    if (
      exportOptionsRef.current &&
      !exportOptionsRef.current.contains(event.target)
    ) {
      setShowExportOptions(false);
    }
  };

  const toggleExportOptions = () => {
    setShowExportOptions(!showExportOptions);
  };

  const exportPDF = async () => {
    if (tableRef.current) {
      const canvas = await html2canvas(tableRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('dashboard-logs.pdf');
    } else {
      console.error('Error: HTMLTableElement is null.');
    }
  };

  const formatAmericanDateTimeLocal = (utcDateString: string) => {
    const date = new Date(utcDateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  const exportToXLS = (
    // eslint-disable-next-line
    data: any[],
    keysToRemove: string[],
    fileName: string
  ) => {
    // Function to convert camelCase string to Title Case
    const camelCaseToTitleCase = (camelCase: string) => {
      return camelCase
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());
    };

    // Remove specified keys, convert remaining keys from camelCase to Title Case,
    // and strip HTML tags from values
    const modifiedData = data.map(item => {
      // eslint-disable-next-line
      const newItem: any = {};
      Object.keys(item)
        .filter(key => !keysToRemove.includes(key)) // Remove unwanted keys
        .forEach(key => {
          // Check if the key is related to names or last names
          if (key === 'createdAt') {
            // Convert createdAt to American date-time format in local time
            newItem[camelCaseToTitleCase(key)] = formatAmericanDateTimeLocal(
              item[key]
            );
          } else if (
            key === 'names' ||
            key === 'lastNames' ||
            key === 'name' ||
            key === 'lastName'
          ) {
            // Handle both array and string types
            if (Array.isArray(item[key])) {
              newItem[camelCaseToTitleCase(key)] = item[key].join('\n');
            } else {
              newItem[camelCaseToTitleCase(key)] = item[key];
            }
          } else {
            // Apply stripHtmlTags to each value and convert key to Title Case
            newItem[camelCaseToTitleCase(key)] = stripHtmlTags(item[key]);
          }
        });

      return newItem;
    });

    const estimateTextWidth = (text: string) => {
      let width = 0;
      for (let i = 0; i < text.length; i++) {
        const char = text.charAt(i);
        // Estimate character width (adjust these values based on your font settings)
        width += char === 'i' || char === 'l' ? 0.5 : 1;
      }

      return width;
    };
    // Calculate max width for each column
    const maxWidths: { [key: string]: number } = {};
    modifiedData.forEach(item => {
      Object.keys(item).forEach(key => {
        const text = item[key].toString();
        const estimatedWidth = estimateTextWidth(text);
        maxWidths[key] = Math.max(estimatedWidth, maxWidths[key] || key.length);
      });
    });
    const factor = 1.3;
    // Create worksheet and set column widths
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const cols = Object.keys(maxWidths).map(key => ({
      wch: maxWidths[key] * factor,
    }));
    worksheet['!cols'] = cols;

    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Writing to a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const formattedDate = date
          ? date.toISOString().split('T')[0]
          : undefined;

        if (selectedBuilding && formattedDate) {
          setLoading(true); // Start loading
          const response = await fetchAllItems(
            `logs?buildingId=${selectedBuilding.id}&date=${formattedDate}`
          );
          setLogs(response); // Directly use the response from fetchAllItems
        }
      } catch (error) {
        console.error('Failed to fetch logs:', error);
        // Handle errors here
      } finally {
        setLoading(false); // Stop loading regardless of the outcome
      }
    };

    if (selectedBuilding && date) {
      fetchLogs();
    }
  }, [selectedBuilding, date]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateChange = (value: Date) => {
    setDate(value);
  };

  if (loading) {
    return (
      <DashboardWrapper>
        <Spinner />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <Column overflow="hidden">
        <Row maxHeight={76}>
          <TitleAndButtonsWrapper>
            <UsersListing>
              <Title>
                <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
                  Property Reports
                </Text>
                <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
                  Please select property and date to show log
                </Text>
              </Title>
            </UsersListing>
            <ButtonsWrapper>
              <SelectDate value={date} onChange={handleDateChange} />
              <OptionsInput
                isRsuite
                isDashboard
                selectedOption={
                  selectedBuilding?.id ? selectedBuilding?.property_name : ''
                }
                options={buildingNames}
                icon={undefined}
                changeHandler={changeHandler}
                changeHandlerName={'selectedBuilding'}
              />
              <DashboardHomeButton
                isSelected={true}
                onClick={() => sendDailyReport()}
              >
                <Text
                  width={'100'}
                  fontFamily={font.family}
                  fontSize={font.size.xs}
                  fontWeight={500}
                  mt={0}
                  mb={0}
                >
                  {'Send Daily Report'}
                </Text>
              </DashboardHomeButton>
              <DashboardHomeButton
                isSelected={true}
                onClick={() => setIsVisitorLog(!isVisitorLog)}
              >
                <Text
                  width={'100'}
                  fontFamily={font.family}
                  fontSize={font.size.xs}
                  fontWeight={500}
                  mt={0}
                  mb={0}
                >
                  {isVisitorLog ? 'See Daily Logs' : 'See Visitor Logs'}
                </Text>
              </DashboardHomeButton>
              <DashboardHomeButton
                isSelected={true}
                onClick={() => toggleExportOptions()}
              >
                <Text
                  width={'100'}
                  fontFamily={font.family}
                  fontSize={font.size.xs}
                  fontWeight={500}
                  mt={0}
                  mb={0}
                >
                  Export
                </Text>
              </DashboardHomeButton>
              {showExportOptions && (
                <div
                  ref={exportOptionsRef}
                  style={{
                    position: 'absolute',
                    top: '18%',
                    zIndex: 22 /* additional styles */,
                  }}
                >
                  <DashboardHomeButton
                    isSelected={true}
                    isExportToPdfButton={true}
                    onClick={() => exportPDF()}
                  >
                    <Text
                      width={'100'}
                      fontFamily={font.family}
                      fontSize={font.size.xs}
                      fontWeight={600}
                    >
                      To PDF
                    </Text>
                  </DashboardHomeButton>
                  <div style={{ padding: '2px' }}></div>
                  <DashboardHomeButton
                    isSelected={true}
                    onClick={() => {
                      exportToXLS(
                        isVisitorLog ? logs?.visitorLogs : logs?.dailyLogs,
                        isVisitorLog
                          ? [
                              'userId',
                              'id',
                              'updatedAt',
                              'property_ids',
                              'description',
                              'additionalNotes',
                            ]
                          : ['userId', 'id', 'updatedAt', 'property_ids'],
                        isVisitorLog ? 'visitor_log' : 'daily_log'
                      );
                    }}
                  >
                    <Text
                      width={'100'}
                      fontFamily={font.family}
                      fontSize={font.size.xs}
                      fontWeight={600}
                    >
                      To XLS
                    </Text>
                  </DashboardHomeButton>
                </div>
              )}
            </ButtonsWrapper>
          </TitleAndButtonsWrapper>
        </Row>
        <Text
          backgroundColour="white"
          fontFamily={font.family}
          fontSize={font.size.l}
          pl={14}
          fontWeight={600}
          pb={16}
        >
          {isVisitorLog ? 'Visitor Logs' : 'Daily Logs'}
        </Text>
        <DashboardLogs
          tableRef={tableRef}
          key={isVisitorLog.toString()}
          log={isVisitorLog ? logs?.visitorLogs : logs?.dailyLogs}
        />
      </Column>
    </DashboardWrapper>
  );
};
