import { DefaultComponentProps } from '../../components/app-router/component-props';
import { LogIn } from '../../components/login/login';
import { DailyLog } from '../../components/pages/daily-log/daily-log';
import { MainMenu } from '../../components/pages/main-menu';
import { VisitorLog } from '../../components/pages/visitor-log/visitor-log';
import { getDeliveryRoutes } from './delivery-routes';
import { getEmergencyRoutes } from './emergency-routes';
import { getGuestRoutes } from './guest-routes';
import { getLeasingOfficeRoutes } from './leasing-office-routes';
import { getMaintenanceRoutes } from './maintanance-routes';
import { getMisscelaneausRoutes } from './misscelaneaus-routes';
import { getProspectRoutes } from './prospect-routes';
import { getResidentRoutes } from './resident-routes';
import { getVendorRoutes } from './vendor-routes';
import { getShiftChangeRoutes } from './shift-change-routes';
import { Dashboard } from '../../components/pages/dashboard/dashboard';
import { ViewDailyReport } from '../../components/pages/view-daily-report/view-daily-report';

interface RouteType {
  path: string;
  component: React.FC<DefaultComponentProps>;
  options?: string[];
}

export const routes: RouteType[] = [
  //
  { path: '/', component: LogIn },
  { path: '/main-menu', component: MainMenu },
  { path: '/view-report', component: ViewDailyReport },
  // DASHBOARD routes function differently then the others.
  // The Dashboard Component is loaded on 'dashboard/*',
  // and according to the url name after '/dashboard/',
  //  inside this component we load a different component with a switch statement (renderDashboardContentAccordingUrl) in dashboard.tsx file.
  { path: '/dashboard/*', component: Dashboard },

  { path: '/visitor-log', component: VisitorLog },
  ...getGuestRoutes(),
  ...getProspectRoutes(),
  ...getVendorRoutes(),
  ...getDeliveryRoutes(),
  ...getEmergencyRoutes(),

  //
  { path: '/daily-log', component: DailyLog },
  ...getMaintenanceRoutes(),
  ...getLeasingOfficeRoutes(),
  ...getResidentRoutes(),
  ...getMisscelaneausRoutes(),
  ...getShiftChangeRoutes(),
];
