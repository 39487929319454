import { useEffect, useRef, useState } from 'react';
import cv from 'opencv-ts';
import { createItem } from '../requests/requests';

export const useCamera = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [isStreaming, setIsStreaming] = useState<boolean>(true);
  const [isBlurry, setIsBlurry] = useState<boolean>(false);
  const [cameraError, setCameraError] = useState<string | null>(null);
  // eslint-disable-next-line
  const [ocrText, setOcrText] = useState<any>();
  const [isCameraMode, setIsCameraMode] = useState(false);
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>(
    []
  );
  const [currentCamera, setCurrentCamera] = useState<MediaDeviceInfo | null>(
    null
  );
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    // Enumerate all devices and set available cameras
    if (typeof window === 'undefined' || !navigator.mediaDevices) {
      console.error('Window object or navigator.mediaDevices is not available');
      setCameraError('Camera access is not supported in this context.');

      return;
    }

    const enumerateDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          device => device.kind === 'videoinput'
        );
        setAvailableCameras(videoDevices);
        if (videoDevices.length > 0) {
          setCurrentCamera(videoDevices[0]);
        }
      } catch (err) {
        console.error('Error enumerating devices:', err);
      }
    };

    enumerateDevices();

    const handleDeviceChange = () => {
      // Re-enumerate devices when a change is detected
      enumerateDevices();
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
    };
  }, []);

  const switchCamera = async () => {
    if (availableCameras.length <= 1) return;

    stopCamera();

    const currentIndex = availableCameras.findIndex(
      cam => cam.deviceId === currentCamera?.deviceId
    );
    const nextIndex = (currentIndex + 1) % availableCameras.length;

    const newCamera = availableCameras[nextIndex];
    setCurrentCamera(newCamera);

    await requestCameraAccess(newCamera.deviceId);
  };

  const stopCamera = () => {
    // console.log('Attempting to stop the camera...');

    if (mediaStream) {
      // console.log('MediaStream found...');
      const tracks = mediaStream.getTracks();

      if (tracks.length === 0) {
        // console.log('No tracks found in the MediaStream.');
      }

      tracks.forEach(track => {
        // console.log('Stopping track:', track);
        track.stop();
      });

      setMediaStream(null);
    } else {
      // console.log('No MediaStream available.');
    }
  };

  const countZeroCrossings = (data: number[]): number => {
    let count = 0;
    for (let i = 1; i < data.length; i++) {
      if (
        (data[i - 1] > 0 && data[i] < 0) ||
        (data[i - 1] < 0 && data[i] > 0)
      ) {
        count++;
      }
    }

    return count;
  };

  const isImageBlurry = (imgData: ImageData): boolean => {
    const width = imgData.width;
    const data = imgData.data;
    const laplacian: number[] = [];
    for (let i = 0; i < data.length; i += 4) {
      const currPixel = data[i];
      const left = data[i - 4] || currPixel;
      const right = data[i + 4] || currPixel;
      const top = data[i - 4 * width] || currPixel;
      const bottom = data[i + 4 * width] || currPixel;

      laplacian.push(currPixel * 4 - (left + right + top + bottom));
    }

    const zeroCrossings = countZeroCrossings(laplacian);

    // console.log('Zero Crossings: ', zeroCrossings); // Log zero crossings for debugging

    const threshold = 140500;
    if (zeroCrossings < threshold) {
      return false;
    }

    return true;
  };

  const requestCameraAccess = async (deviceId?: string) => {
    if (!navigator.mediaDevices) {
      setCameraError('Camera access is not supported in this context.');

      return;
    }

    const useDeviceId = deviceId || currentCamera?.deviceId;

    try {
      const constraints: MediaStreamConstraints = {
        video: useDeviceId ? { deviceId: { exact: useDeviceId } } : true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setMediaStream(stream);
      if (videoRef.current) {
        const previousStream = videoRef.current.srcObject as MediaStream;
        if (previousStream) {
          previousStream.getTracks().forEach(track => track.stop());
        }
        videoRef.current.srcObject = stream;
        const handleMetadataLoaded = () => {
          setIsStreaming(true);
          videoRef.current?.removeEventListener(
            'loadedmetadata',
            handleMetadataLoaded
          );
        };

        videoRef.current.addEventListener(
          'loadedmetadata',
          handleMetadataLoaded
        );
      }
    } catch (error) {
      console.error('Error accessing webcam:', error);
      setCameraError(`Error accessing webcam: ${error || 'Unknown error'}`);
      setIsStreaming(false);
    }
  };
  const captureImage = () => {
    setIsCameraMode(true);
    setIsStreaming(true);
    if (videoRef.current) {
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      const canvas = document.createElement('canvas');

      // Set canvas size to the video's dimensions
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      if (videoWidth === 0 || videoHeight === 0) {
        console.error('Video dimensions not yet available.');

        return;
      }

      const ctx = canvas.getContext('2d');

      if (ctx) {
        // Capture the entire video frame
        ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

        // preprocessImage(canvas); // Assuming you still want to preprocess the full image
        const dataURL = canvas.toDataURL('image/png');
        // Note: If you're checking for blurriness, you might need to adjust or disable this step,
        // since the logic may have been specific to the focused area
        const imageData = ctx.getImageData(0, 0, videoWidth, videoHeight);
        setIsBlurry(isImageBlurry(imageData)); // You may need to adjust the isImageBlurry function
        setCapturedImage(dataURL);
        setIsStreaming(false);
        setIsCameraMode(false);
      }
      canvas.remove();
    }
  };

  const retakeImage = async () => {
    setCapturedImage(null);
    if (videoRef.current) {
      videoRef.current.onloadedmetadata = () => {
        captureImage();

        if (videoRef.current) {
          videoRef.current.removeEventListener('loadedmetadata', retakeImage);
        }
      };
    }
    requestCameraAccess(currentCamera?.deviceId);
  };

  // const preprocessImage = (canvas: HTMLCanvasElement) => {
  //   const src = cv.imread(canvas);

  //   // Resize the image for clearer scan (double the size for this example)
  //   const dsize = new cv.Size(src.cols * 1.5, src.rows * 1.5);
  //   const resized = new cv.Mat();
  //   cv.resize(src, resized, dsize, 0, 0, cv.INTER_LINEAR);

  //   const dst = new cv.Mat(); // Define dst here

  //   // Grayscale
  //   cv.cvtColor(resized, dst, cv.COLOR_RGBA2GRAY);

  //   // Generate blurred image
  //   const blurred = new cv.Mat();
  //   const ksize = new cv.Size(5, 5);
  //   cv.GaussianBlur(dst, blurred, ksize, 0, 0, cv.BORDER_DEFAULT);

  //   // Weighted subtraction
  //   const alpha = 1.5;
  //   const beta = -0.5;
  //   cv.addWeighted(dst, alpha, blurred, beta, 0, dst);
  //   blurred.delete();

  //   // Sharpening
  //   const sharpenKernel = cv.Mat.eye(3, 3, cv.CV_32F);
  //   sharpenKernel.data32F[0] = 0;
  //   sharpenKernel.data32F[1] = -1;
  //   sharpenKernel.data32F[2] = 0;
  //   sharpenKernel.data32F[3] = -1;
  //   sharpenKernel.data32F[4] = 5;
  //   sharpenKernel.data32F[5] = -1;
  //   sharpenKernel.data32F[6] = 0;
  //   sharpenKernel.data32F[7] = -1;
  //   sharpenKernel.data32F[8] = 0;

  //   const sharpenTimes = 1;
  //   for (let i = 0; i < sharpenTimes; i++) {
  //     cv.filter2D(
  //       dst,
  //       dst,
  //       cv.CV_8U,
  //       sharpenKernel,
  //       new cv.Point(-1, -1),
  //       0,
  //       cv.BORDER_DEFAULT
  //     );
  //   }
  //   sharpenKernel.delete();

  //   // cv.threshold(dst, dst, 0, 255, cv.THRESH_BINARY_INV + cv.THRESH_OTSU);

  //   // Display the processed image
  //   cv.imshow(canvas, dst);

  //   src.delete();
  //   resized.delete();
  //   dst.delete();
  // };

  const [loading, setLoading] = useState(false);

  const extractBase64 = (input: string | null): string => {
    const base64Pattern = /^data:image\/[a-zA-Z]+;base64,(.*)$/;
    const match = input?.match(base64Pattern);
    if (match && match.length > 1) {
      return match[1];
    } else {
      throw new Error('Input is not a valid base64 image string');
    }
  };

  console.log('ocr Text:');
  console.log(ocrText);

  useEffect(() => {
    const processImage = async () => {
      setLoading(true);
      const base64Img = extractBase64(capturedImage);
      try {
        const text = await createItem('process-image', {
          documentType: 1,
          backOrSecondImageBase64: base64Img,
          overriddenSettings: {
            isOCREnabled: false,
            isBackOrSecondImageProcessingEnabled: true,
            isFaceMatchEnabled: false,
          },
        });
        setOcrText(text);
      } catch (error) {
        console.error('Error processing image:', error);
      } finally {
        setLoading(false); // set loading to false when processing completes (either success or failure)
      }
    };

    if (capturedImage && capturedImage?.length > 1) {
      processImage();
    }
  }, [capturedImage]);

  const [isCvReady, setCvReady] = useState(false);

  useEffect(() => {
    if (cv && cv.onRuntimeInitialized) {
      cv.onRuntimeInitialized = () => {
        setCvReady(true);
      };
    }
  }, []);

  return {
    videoRef,
    capturedImage,
    isStreaming,
    isBlurry,
    captureImage,
    retakeImage,
    requestCameraAccess,
    cameraError,
    ocrText,
    isCvReady,
    isCameraMode,
    setIsCameraMode,
    stopCamera,
    loading,
    switchCamera,
    availableCameras,
    currentCamera,
  };
};
