import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_TITLES } from '../../../../constants/dashboard-constants';
import { Text, font } from '../../../../style-utils/text';
import { RightPointer } from '../../../icons/right-pointer';
import {
  DashboardLogout,
  LogoContainer,
  LogOutButton,
  MenuContainer,
  MenuItem,
  MenuAndLogoutWrappper,
  MenuTitle,
  LogoSpan,
} from '../dashboard.styled';
import { useLocation } from 'react-router-dom';
import { LogoutIcon } from '../../../icons/log-out';
import { NavbarContainer } from '../../../layout/layout-container.styled';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import Cookies from 'universal-cookie';
import { User } from '../../../../interfaces/user-interfaces';
import { useAuth } from '../../../../context/auth-provider';
import { DashboardLogoArrow } from '../../../icons/dashboard-logo-arrow';

interface DashboardMenuProps {
  // dashboardTitle: string;
  updateDashboardTitle: (newValue: string) => void;
}

export const DashboardMainMenu: React.FC<DashboardMenuProps> = ({
  // dashboardTitle,
  updateDashboardTitle,
}) => {
  const [currentRoute, setCurrentRoute] = useState<string | undefined>();
  const {
    // user,
    setUser,
  }: {
    // user: User | null | undefined;
    setUser: (user: User | null | undefined) => void;
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  //here we set the routes according to the menu item clicked
  const getPathFromMenu = (title: string) => {
    if (title.toLowerCase() === 'home') {
      setCurrentRoute('/dashboard');
      updateDashboardTitle('dashboard');

      return navigate('/dashboard');
    } else {
      setCurrentRoute(`/dashboard/${title.toLocaleLowerCase()}`);
      updateDashboardTitle(`${title && title?.toLocaleLowerCase()}`);

      return navigate(`/dashboard/${title.toLocaleLowerCase()}`);
    }
  };

  //here we set the dashboard title according to the url
  const renderDashboardContentAccordingUrl = (url: string) => {
    if (url.includes('buildings')) {
      updateDashboardTitle('buildings');
      setCurrentRoute('/dashboard/buildings');

      return '/dashboard/buildings';
    } else if (url.includes('users')) {
      updateDashboardTitle('users');
      setCurrentRoute('/dashboard/users');

      return '/dashboard/users';
    } else if (url.includes('reports')) {
      updateDashboardTitle('reports');
      setCurrentRoute('/dashboard/reports');

      return '/dashboard/reports';
    } else {
      updateDashboardTitle('dashboard');
      setCurrentRoute('/dashboard');

      return '/dashboard';
    }
  };

  useEffect(() => {
    if (
      location.pathname === '/dashboard' ||
      location.pathname === '/dashboard/'
    ) {
      setCurrentRoute(`/dashboard`);
      updateDashboardTitle('dashboard');
    } else {
      setCurrentRoute(location.pathname);
      renderDashboardContentAccordingUrl(location.pathname);
    }
  }, []);

  const logOut = async () => {
    try {
      await axios.post(`${apiRoute}/logout` as string);
      cookies.remove('authorization_token');
      setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      navigate('/');
    }
  };

  const extractTitleFromPath = (path: string) => {
    if (path === '/dashboard' || path === '/dashboard/') {
      return 'home';
    }

    const keywords = ['buildings', 'users', 'reports'];

    for (const keyword of keywords) {
      if (path.includes(keyword)) {
        return keyword;
      }
    }

    return 'dashboard';
  };

  useEffect(() => {
    const updatePathAndTitle = () => {
      const path = location.pathname;
      const title = extractTitleFromPath(path);

      setCurrentRoute(`/dashboard/${title}`);
      updateDashboardTitle(title);
    };

    updatePathAndTitle();
  }, [location.pathname, updateDashboardTitle]);

  return (
    <NavbarContainer dashboard={true} id="dashboard-menu-container">
      <LogoContainer id="logo-container">
        <Text
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          fontSize={20}
          fontWeight={700}
          onClick={() => getPathFromMenu('home')}
        >
          <LogoSpan>C</LogoSpan>oncierge
        </Text>
        <DashboardLogoArrow />
      </LogoContainer>
      <MenuAndLogoutWrappper>
        <MenuContainer id="dashboard-main-menu">
          <MenuTitle>
            <Text
              mb={0}
              mt={0}
              fontFamily={font.family}
              fontSize={font.size.xs}
              fontWeight={400}
            >
              Main menu
            </Text>
          </MenuTitle>
          {Object.values(DASHBOARD_TITLES).map((e, i) => {
            const route = `/dashboard/${e.toLowerCase()}`;
            const isCurrent = currentRoute?.includes(route);

            return (
              <MenuItem key={i} onClick={() => getPathFromMenu(e)}>
                <RightPointer fill={isCurrent ? undefined : 'transparent'} />
                <Text
                  mt={0}
                  mb={0}
                  ml={8}
                  fontFamily={font.family}
                  fontSize={font.size.s}
                  fontWeight={isCurrent ? 700 : 300}
                >
                  {e}
                </Text>
              </MenuItem>
            );
          })}
        </MenuContainer>
        <DashboardLogout>
          <LogOutButton onClick={() => logOut()}>
            <LogoutIcon />
            <Text ml={12} color={font.color.purple} fontSize={font.size.s}>
              Logout
            </Text>
          </LogOutButton>
          <Text mt={0} fontSize={font.size.xxs} color={font.color.gray}>
            Dashboard by
            <span style={{ fontWeight: '500', color: '#333' }}>
              &nbsp;Moxelle
            </span>
          </Text>
        </DashboardLogout>
      </MenuAndLogoutWrappper>
    </NavbarContainer>
  );
};
