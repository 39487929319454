import React, { useState } from 'react';
import {
  ButtonContainer,
  ButtonTitle,
  ProfileButton,
  ProfileModal,
} from './profile.styled';
import { useAuth } from '../../context/auth-provider';
import { font, Text } from '../../style-utils/text';
import { User } from '../../interfaces/user-interfaces';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { apiRoute } from '../../constants/api-constants';

interface ProfileProps {
  dashboard?: boolean;
}

export const Profile: React.FC<ProfileProps> = ({ dashboard }) => {
  const {
    user,
    setUser,
  }: {
    user: User | null | undefined;
    setUser: (user: User | null | undefined) => void;
  } = useAuth();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleProfile = () => {
    setShowProfileModal(!showProfileModal);
  };

  const logOut = async () => {
    try {
      await axios.post(`${apiRoute}/logout` as string);
      cookies.remove('authorization_token');
      setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      navigate('/');
    }
  };

  return (
    <>
      <ProfileButton dashboard={dashboard} onClick={() => handleProfile()}>
        <Text
          mt={0}
          mb={0}
          ml={0}
          mr={0}
          fontFamily={font.family}
          fontSize={font.size.l}
          color={'white'}
        >
          {user?.full_name.charAt(0)}
          {/* {user?.full_name.length > 0
            ? user?.full_name.charAt(0)
            : user?.property_ids[0]?.property_name} */}
        </Text>
      </ProfileButton>
      {showProfileModal ? (
        <ProfileModal dashboard={dashboard}>
          <Text fontFamily={font.family}>{user?.full_name}</Text>
          {user?.role === 'employee' ? (
            <>
              <ButtonContainer dashboard={dashboard} onClick={() => logOut()}>
                <ButtonTitle>Sign Out </ButtonTitle>
              </ButtonContainer>
            </>
          ) : (
            <>
              <ButtonContainer
                dashboard={dashboard}
                onClick={() =>
                  navigate(dashboard ? '/main-menu' : '/dashboard', {
                    replace: false,
                  })
                }
              >
                <ButtonTitle>
                  {dashboard ? 'Main Menu' : 'Dashboard'}
                </ButtonTitle>
              </ButtonContainer>
              <ButtonContainer dashboard={dashboard} onClick={() => logOut()}>
                <ButtonTitle>Sign Out </ButtonTitle>
              </ButtonContainer>
            </>
          )}
        </ProfileModal>
      ) : null}
    </>
  );
};
