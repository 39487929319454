import React from 'react';
import { NavItem, NavTitle, NavbarContainer } from './visitor-log.styled';
import { Text, font } from '../../../style-utils/text';
import { RightPointer } from '../../icons/right-pointer';
import { NavigateFunction } from 'react-router-dom';
import { getTitlesFromRoute } from './navbar-controller';

interface NavbarProps {
  hasContent: boolean;
  currentPage: string;
  navigate: NavigateFunction;
  isLog: boolean;
  state: object;
  navigation: string[];
  path: string;
}

export const Navbar: React.FC<NavbarProps> = ({
  hasContent,
  navigate,
  isLog,
  navigation,
  currentPage,
  path,
}) => {
  return (
    <NavbarContainer>
      <NavTitle>
        <Text
          mb={0}
          mt={0}
          fontFamily={font.family}
          fontWeight={font.weight.bold}
          fontSize={font.size.xl}
        >
          {path === '/main-menu' && 'Main Menu'}
          {isLog
            ? 'Daily Log'
            : hasContent
            ? 'Visitor Log'
            : path !== '/main-menu' && 'Choose type'}
        </Text>
        {path === '/main-menu' && (
          <Text mt={10} color={font.color.gray} fontSize={font.size.xs}>
            Please select one of the options below
          </Text>
        )}
      </NavTitle>
      {hasContent &&
        navigation?.map((e, i) => {
          return (
            <NavItem key={i} onClick={() => navigate}>
              <RightPointer
                fill={
                  getTitlesFromRoute(e) === getTitlesFromRoute(currentPage)
                    ? 'black'
                    : 'transparent'
                }
              />
              <Text
                mt={0}
                mb={0}
                ml={8}
                fontWeight={
                  getTitlesFromRoute(e) === getTitlesFromRoute(currentPage)
                    ? 600
                    : 400
                }
              >
                {getTitlesFromRoute(e)}
              </Text>
            </NavItem>
          );
        })}
    </NavbarContainer>
  );
};
