import { useState } from 'react';

export interface VisitorLogState {
  visitorType: string;
  preAuthorized: string;
  numberOfGuests: number;
  apartmentLocation: string;
  approval: string;
  parkingValidation: string;
  notes: string;
  details: string;
  description: string;
  names: string[];
  lastNames: string[];
  // camera
  capturedImage: string | null;
  isStreaming: boolean;
  isBlurry: boolean;
  userId: string | undefined;
  visitorDetails: string;
  property_ids: number[] | [] | undefined;
}

const useVisitorLogHandler = (
  userId: string | undefined,
  property_ids: number[] | [] | undefined
) => {
  const initialState: VisitorLogState = {
    preAuthorized: '',
    numberOfGuests: 1,
    apartmentLocation: '',
    approval: '',
    parkingValidation: '',
    notes: '',
    details: '',
    description: '',
    names: [''],
    lastNames: [''],
    capturedImage: null,
    isStreaming: true,
    isBlurry: false,
    userId: userId,
    visitorType: '',
    visitorDetails: '',
    property_ids: property_ids || [],
  };

  const [visitorLogState, setVisitorLogState] =
    useState<VisitorLogState>(initialState);

  const visitorChangeHandler = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | string,
    fieldName?: string,
    explicitValue?: string,
    index?: number
  ) => {
    let value: string;

    if (explicitValue !== undefined) {
      value = explicitValue;
    } else if (typeof e === 'string') {
      value = e;
    } else if ('value' in e.target) {
      value = e.target.value;
    } else {
      throw new Error('Unable to determine the value to set.');
    }

    if ((fieldName === 'names' || fieldName === 'lastNames') && value) {
      // console.log('Setting state for:', fieldName, value);
      setVisitorLogState(prevState => {
        const updatedField = [...prevState[fieldName as 'names' | 'lastNames']];
        if (index !== undefined) {
          // <-- Use the provided index or default to the last element
          updatedField[index] = value;
        } else {
          updatedField[updatedField.length - 1] = value; // Update the last element
        }

        return {
          ...prevState,
          [fieldName]: updatedField,
        };
      });
    } else if (fieldName) {
      setVisitorLogState(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const visitorFinish = () => {
    setVisitorLogState(initialState);
  };

  const addNewName = (fieldName: 'names' | 'lastNames') => {
    setVisitorLogState(prevState => ({
      ...prevState,
      [fieldName]: [...prevState[fieldName], ''], // Push an empty string
    }));
  };

  return {
    visitorLogState,
    visitorChangeHandler,
    visitorFinish,
    addNewName,
  };
};

export default useVisitorLogHandler;
