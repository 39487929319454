import React, { useState } from 'react';
import { StyledInputWrapper } from './styled-input.styled';

interface StyledInputProps {
  type: string;
  placeholder: string;
  name: string;
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  defaultValue?: number | string;
  width: number;
  maxLength: number;
}

export const StyledInput: React.FC<StyledInputProps> = ({
  type,
  placeholder,
  name,
  defaultValue,
  onChange,
  width,
  maxLength,
}) => {
  const [inputValue, setInputValue] = useState<string | number>(
    defaultValue || ''
  );
  // eslint-disable-next-line
  const handleInputChange = (e: any) => {
    const value = e.target.value;

    // Check if the input type is 'number' and the value is not a number
    if (type === 'number' && !/^\d*$/.test(value)) {
      // Reset to the last valid value
      e.target.value = inputValue;

      return;
    }

    // Additional checks for number type
    if (type === 'number' && (value.length > 2 || parseInt(value) > 20)) {
      e.target.value = inputValue;

      return;
    }

    setInputValue(value);
    onChange && onChange(e);
  };

  return (
    <StyledInputWrapper
      min={type === 'number' ? 1 : undefined}
      max={type === 'number' ? 20 : undefined}
      maxLength={maxLength}
      width={width}
      name={name}
      placeholder={placeholder}
      type={type}
      defaultValue={defaultValue}
      // eslint-disable-next-line
      onChange={(e: any) => {
        type === 'number' ? handleInputChange(e) : onChange(e);
      }}
    />
  );
};
