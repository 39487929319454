// Define regular expressions here
const regexPatterns: Record<string, RegExp> = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};

interface RegexValidationResult {
  isValidEmail: boolean;
}

export const regexValidation = (
  patternKey: string,
  value: string
): RegexValidationResult => {
  const pattern = regexPatterns[patternKey];

  if (!pattern) {
    throw new Error(`Pattern '${patternKey}' not found.`);
  }

  return {
    isValidEmail: pattern.test(value),
  };
};
