import styled from 'styled-components';
import { font } from '../../style-utils/text';
import { colors } from '../../style-utils/colors';

export const StyledInputWrapper = styled.input<{ width: number }>`
  align-self: center;
  width: ${({ width }) => width && `${width}%`};
  padding: 16px;
  height: 28px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--btn-def, #8c67c9);
  background: #fff;
  margin-bottom: 16px;
  box-shadow: 2px 2px 10px 0px rgba(148, 110, 209, 0.1);
  font-family: ${font.family};
  font-size: 18px;
  &:hover {
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
  }
  /* Hide the up and down arrows for Webkit browsers */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide the arrows for Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  /* Hide the arrows for Internet Explorer */
  &::-ms-clear {
    display: none;
  }
`;
