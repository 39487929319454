import { BUILDING_ACTIVITIES_TYPES } from '../../constants/building-activities';
import { VISITOR_TYPES } from '../../constants/visitor-types';

export const dailyLogPaths: { [key in BUILDING_ACTIVITIES_TYPES]: string[] } = {
  [BUILDING_ACTIVITIES_TYPES.MAINTENANCE]: [
    'apartment-location',
    'priority',
    'description',
    'additional-notes',
  ],
  [BUILDING_ACTIVITIES_TYPES.LEASING_OFFICE]: [
    'description',
    'additional-notes',
  ],
  [BUILDING_ACTIVITIES_TYPES.RESIDENT]: [
    'id',
    'apartment-location',
    'priority',
    'description',
    'additional-notes',
  ],
  [BUILDING_ACTIVITIES_TYPES.MISSCELANEAUS]: [
    'apartment-location',
    'description',
    'additional-notes',
  ],
  [BUILDING_ACTIVITIES_TYPES.SHIFT_CHANGE]: ['id', 'additional-notes'],
};

export const visitorLogPaths: { [key in VISITOR_TYPES]: string[] } = {
  [VISITOR_TYPES.GUEST]: [
    'visitor-details',
    'number-of-guests',
    'id',
    'apartment-location',
    'approval',
    'parking-validation',
    'notes',
  ],
  [VISITOR_TYPES.PROSPECT]: [
    'visitor-details',
    'number-of-guests',
    'id',
    'apartment-location',
    'approval',
    'parking-validation',
    'notes',
  ],
  [VISITOR_TYPES.VENDOR]: [
    'visitor-details',
    'number-of-guests',
    'id',
    'apartment-location',
    'approval',
    'parking-validation',
    'notes',
  ],
  [VISITOR_TYPES.DELIVERY]: [
    'visitor-details',
    'number-of-guests',
    'id',
    'apartment-location',
    'approval',
    'parking-validation',
    'notes',
  ],
  [VISITOR_TYPES.EMERGENCY]: [
    'visitor-details',
    'number-of-guests',
    'apartment-location',
    'notes',
  ],
};

export const toCamelCase = (str: string): string => {
  return str
    .split(' ')
    .map((word, index) => {
      if (index === 0) return word?.toLowerCase();

      return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
    })
    .join('');
};

export const toKebabCase = (str: string): string => {
  return str.toLowerCase().replace(/\s+/g, '-');
};

const activityToStringPath = (activity: string): string => {
  return toCamelCase(activity);
};

interface ActivityMapping {
  [key: string]: string[];
}

export const generatePaths = (mapping: ActivityMapping): string[] => {
  return Object.entries(mapping).flatMap(([activity, paths]) =>
    paths.map(path => `/${activityToStringPath(activity)}/${path}`)
  );
};
