import React, { useEffect } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  // Progress,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
  Progress,
} from '../../layout/layout-container.styled';
import { Navbar } from '../../layout/visitor-log/navbar';
import { NavButton } from '../../nav-button/nav-button';
import { Text, font } from '../../../style-utils/text';
import { StyledButton } from '../../styled-button/styled-button';
import { CheckRing } from '../../icons/check-ring';
import { BUILDING_ACTIVITIES } from '../../../constants/building-activities';
import { DailyLogState } from '../../hooks/use-daily-log-handler';
import { DefaultComponentProps } from '../../app-router/component-props';
import { toKebabCase } from '../../app-router/path-generator';
import { Step } from '../../step/step';
import useRedirectOnRefresh from '../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../style-utils/use-window-height';

export const DailyLog: React.FC<DefaultComponentProps> = ({
  navigate,
  changeHandler,
  state,
  finish,
  allPaths,
  path,
  navigation,
  selectedOption,
  selectedBuilding,
}) => {
  const { buildingActivity } = state as DailyLogState;

  const allRoutes = allPaths || [];
  const basePath = `/${toKebabCase(buildingActivity)}`;
  const firstMatchingPath = allRoutes.find(path => path.startsWith(basePath));

  useEffect(() => {
    finish();
  }, []);

  useRedirectOnRefresh();
  useRedirectOnBack();
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        selectedOption={selectedOption}
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer>
          <Step currentPathIndex={1} maxPaths={0} />
          <Progress />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer>
            <Navbar
              path={path}
              navigation={navigation}
              state={state}
              isLog
              navigate={navigate}
              hasContent={false}
              currentPage={path}
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column">
              <Text fontSize={font.size.xl} fontWeight={700} mt={0} mb={10}>
                Building Activities
              </Text>
              <Text
                color={font.color.gray}
                fontSize={font.size.xs}
                mt={0}
                mb={0}
              >
                Please select one of the options below
              </Text>
            </TitleAndSubtitle>
            <ContentButtonsWrapper>
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(
                    e,
                    'buildingActivity',
                    BUILDING_ACTIVITIES.MAINTENANCE
                  )
                }
                text={BUILDING_ACTIVITIES.MAINTENANCE}
                icon={<CheckRing />}
                isSelected={
                  buildingActivity === BUILDING_ACTIVITIES.MAINTENANCE
                }
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(
                    e,
                    'buildingActivity',
                    BUILDING_ACTIVITIES.LEASING_OFFICE
                  )
                }
                text={BUILDING_ACTIVITIES.LEASING_OFFICE}
                icon={<CheckRing />}
                isSelected={
                  buildingActivity === BUILDING_ACTIVITIES.LEASING_OFFICE
                }
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(
                    e,
                    'buildingActivity',
                    BUILDING_ACTIVITIES.RESIDENT
                  )
                }
                text={BUILDING_ACTIVITIES.RESIDENT}
                icon={<CheckRing />}
                isSelected={buildingActivity === BUILDING_ACTIVITIES.RESIDENT}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(
                    e,
                    'buildingActivity',
                    BUILDING_ACTIVITIES.MISSCELANEAUS
                  )
                }
                text={BUILDING_ACTIVITIES.MISSCELANEAUS}
                icon={<CheckRing />}
                isSelected={
                  buildingActivity === BUILDING_ACTIVITIES.MISSCELANEAUS
                }
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(
                    e,
                    'buildingActivity',
                    BUILDING_ACTIVITIES.SHIFT_CHANGE
                  )
                }
                text={BUILDING_ACTIVITIES.SHIFT_CHANGE}
                icon={<CheckRing />}
                isSelected={
                  buildingActivity === BUILDING_ACTIVITIES.SHIFT_CHANGE
                }
              />
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => navigate('/main-menu', { replace: false })}
              />
              <NavButton
                disabled={buildingActivity.length >= 1 ? false : true}
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={'Continue'}
                onClick={() => {
                  if (firstMatchingPath) {
                    navigate(`/daily-log${firstMatchingPath}`, {
                      replace: false,
                    });
                  } else {
                    navigate('/main-menu', { replace: false });
                  }
                }}
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
