import React, { useEffect, useRef, useState } from 'react';
import {
  formatString,
  stripHtmlTags,
} from '../../../../function-utils/string-converter';
import { ResponsiveTableWrapper, StyledTable } from './dashboard-logs.styled';
import { extractDateAndTime } from '../../../../function-utils/date-time-extractor';

interface DashboardLogsProps {
  // eslint-disable-next-line
  log: any[];
  tableRef?: React.RefObject<HTMLTableElement>;
}

export const DashboardLogs: React.FC<DashboardLogsProps> = ({
  log,
  tableRef,
}) => {
  const [editableLog, setEditableLog] = useState(log);
  const keysToStrip = ['notes', 'additionalNotes', 'description', 'details'];

  const [editMode, setEditMode] = useState<{
    row: number | undefined;
    col: string | undefined;
    index: number | undefined;
  }>({
    row: undefined,
    col: undefined,
    index: undefined,
  });

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (
    rowIndex: number,
    header: string,
    newValue: string
  ) => {
    const newEditableLog = [...editableLog];
    newEditableLog[rowIndex][header] = keysToStrip.includes(header)
      ? stripHtmlTags(newValue)
      : newValue;
    setEditableLog(newEditableLog);
  };

  const handleArrayInputChange = (
    rowIndex: number,
    header: string,
    index: number,
    newValue: string
  ) => {
    const newEditableLog = [...editableLog];
    const updatedArray = [...newEditableLog[rowIndex][header]];
    updatedArray[index] = newValue;
    newEditableLog[rowIndex][header] = updatedArray;
    setEditableLog(newEditableLog);
  };

  const headers =
    log?.length > 0
      ? Object.keys(log[0])
          .filter(
            header =>
              header !== 'userId' &&
              header !== 'id' &&
              header !== 'updatedAt' &&
              header !== 'property_ids' &&
              log.some(item => item[header])
          )
          .map(header => header)
      : [];

  const handleCellClick = (
    rowIndex: number,
    colKey: string,
    index?: number
  ) => {
    if (
      editMode.row === rowIndex &&
      editMode.col === colKey &&
      editMode.index === index
    ) {
      // Close the current cell if it's already in edit mode
      setEditMode({ row: undefined, col: undefined, index: undefined });
    } else {
      // Open the clicked cell in edit mode
      setEditMode({ row: rowIndex, col: colKey, index });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tableWrapperRef.current &&
        !tableWrapperRef.current.contains(event.target as Node)
      ) {
        setEditMode({ row: undefined, col: undefined, index: undefined });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ResponsiveTableWrapper ref={tableWrapperRef}>
      <StyledTable ref={tableRef}>
        <thead>
          <tr>
            {headers?.map(header => (
              <th key={header}>{formatString(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {editableLog?.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {headers?.map(header => {
                const isInEditMode =
                  editMode.row === rowIndex && editMode.col === header;
                let cellValue = item[header];

                if (header === 'createdAt') {
                  const { formattedDate, formattedTime } = extractDateAndTime(
                    item[header]
                  );
                  cellValue = `${formattedDate} | ${formattedTime}`;
                } else {
                  cellValue = stripHtmlTags(cellValue);
                }

                return (
                  <td
                    key={`${rowIndex}-${header}`}
                    onClick={() => handleCellClick(rowIndex, header)}
                  >
                    {isInEditMode ? (
                      header === 'names' || header === 'lastNames' ? (
                        item?.[header]?.map((value: string, index: number) =>
                          editMode.index === index ? (
                            <input
                              key={index}
                              type="text"
                              value={value}
                              onChange={e =>
                                handleArrayInputChange(
                                  rowIndex,
                                  header,
                                  index,
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                setEditMode({
                                  row: undefined,
                                  col: undefined,
                                  index: undefined,
                                })
                              }
                              style={{ display: 'block', width: '100%' }}
                              autoFocus
                            />
                          ) : (
                            <div
                              key={index}
                              onClick={e => {
                                e.stopPropagation();
                                handleCellClick(rowIndex, header, index);
                              }}
                            >
                              {value}
                            </div>
                          )
                        )
                      ) : (
                        <input
                          type="text"
                          value={cellValue as string}
                          onChange={e =>
                            handleInputChange(rowIndex, header, e.target.value)
                          }
                          onBlur={() =>
                            setEditMode({
                              row: undefined,
                              col: undefined,
                              index: undefined,
                            })
                          }
                          style={{ width: '100%' }}
                          autoFocus
                        />
                      )
                    ) : (
                      <div>
                        {header === 'names' || header === 'lastNames'
                          ? item?.[header]?.map(
                              (value: string, index: number) => (
                                <div
                                  key={index}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleCellClick(rowIndex, header, index);
                                  }}
                                >
                                  {value}
                                </div>
                              )
                            )
                          : cellValue}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </ResponsiveTableWrapper>
  );
};
