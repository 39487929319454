import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './components/app-router/app-router';
import { AuthProvider } from './context/auth-provider';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
