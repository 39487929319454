import React, { useState } from 'react';
import { Sidebar } from '../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
  Step,
  Progress,
} from '../layout/layout-container.styled';
import { Navbar } from '../layout/visitor-log/navbar';
import { NavButton } from '../nav-button/nav-button';
import { font } from '../../style-utils/text';
import { StyledButton } from '../styled-button/styled-button';
import { CheckRing } from '../icons/check-ring';
import { DefaultComponentProps } from '../app-router/component-props';
import { NavigateFunction } from 'react-router-dom';
import { createItem, fetchAllItems } from '../requests/requests';
import { DailyLogIcon } from '../icons/daily-log';
import { VisitorLogIcon } from '../icons/visitor-log';
import { useRedirectOnBack } from '../hooks/use-redirect-on-back';
import useRedirectOnRefresh from '../hooks/use-redirect-on-refresh';
import { User } from '../../interfaces/user-interfaces';
import { useAuth } from '../../context/auth-provider';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { colors } from '../../style-utils/colors';
import { CSSProperties } from 'styled-components';
import { useWindowHeight } from '../../style-utils/use-window-height';

const handleNavigation = async (
  selectedOption: string,
  navigate: NavigateFunction
) => {
  switch (selectedOption) {
    case '/visitor-log':
      navigate('/visitor-log', { replace: false });
      break;
    case '/daily-log':
      navigate('/daily-log', { replace: false });
      break;
    case '/view-report':
      navigate('/view-report', { replace: false });
      break;
    case '/send-report':
      await fetchAllItems('/report');
      navigate('/report', { replace: false });
      break;
    default:
      console.error('Unknown option:', selectedOption);
  }
};

export const MainMenu: React.FC<DefaultComponentProps> = ({
  navigate,
  state,
  path,
  selectedBuilding,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { user }: { user: User | null | undefined } = useAuth();
  const { email, full_name, property_ids } = user || {};
  const [loading, setLoading] = useState(false);

  useRedirectOnBack();
  useRedirectOnRefresh();

  const sendDailyReport = async () => {
    try {
      setLoading(true);

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically detect client's timezone
      console.log('Client Time Zone:', timezone); // Optional: Log the detected timezone

      const response = await createItem('daily-report', {
        email,
        full_name,
        property_ids,
        timezone, // Include the timezone in the request
      });

      const res = response as AxiosResponse;
      if (res && res.status === 200) {
        // Check for successful response
        toast.success('Report sent!');
      } else {
        toast.error('Failed to send report.');
      }
    } catch (error) {
      console.error('Error sending report:', error);
      toast.error('Error sending report.');
    } finally {
      setLoading(false);
    }
  };

  const override: CSSProperties = {
    zIndex: '9',
    display: 'block',
    position: 'absolute',
    borderColor: 'red',
    top: '43%',
    left: '43%',
  };

  const height = useWindowHeight();

  if (loading) {
    return (
      <ClimbingBoxLoader
        color={colors.purple_dark}
        loading={loading}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    );
  }

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        path={path}
        selectedOption={selectedOption}
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer isMainMenu>
          <Step />
          <Progress />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer isMainMenu={path === '/main-menu'}>
            <Navbar
              path={path}
              navigation={[]}
              state={state}
              isLog={false}
              navigate={navigate}
              hasContent={false}
              currentPage={path}
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column" />
            <ContentButtonsWrapper>
              <StyledButton
                path={path}
                onClick={() => {
                  setSelectedOption('/visitor-log');
                  // navigate('/select-type');
                }}
                text={'Visitor Log'}
                icon={<VisitorLogIcon />}
                isSelected={selectedOption === '/visitor-log'}
              />
              <StyledButton
                path={path}
                onClick={() => {
                  setSelectedOption('/daily-log');
                  // navigate('/daily-log');
                }}
                text={'Daily Log'}
                icon={<DailyLogIcon />}
                isSelected={selectedOption === '/daily-log'}
              />
              <StyledButton
                onClick={() => {
                  setSelectedOption('/send-report');
                  // navigate('/daily-log');
                }}
                text={'Send Daily Report'}
                icon={<CheckRing />}
                isSelected={selectedOption === '/send-report'}
              />
              <StyledButton
                onClick={() => {
                  setSelectedOption('/view-report');
                  // navigate('/daily-log');
                }}
                text={'View Daily Report'}
                icon={<CheckRing />}
                isSelected={selectedOption === '/view-report'}
              />
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <div></div>
              <NavButton
                // navigate to the next screen
                onClick={
                  selectedOption === '/send-report'
                    ? () => sendDailyReport()
                    : () => handleNavigation(selectedOption, navigate)
                }
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={'Continue'}
                disabled={
                  !(
                    selectedOption === '/visitor-log' ||
                    selectedOption === '/daily-log' ||
                    selectedOption === '/send-report' ||
                    selectedOption === '/view-report'
                  )
                }
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
