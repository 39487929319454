import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../constants/api-constants';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { User } from '../interfaces/user-interfaces';
import { useLocation } from 'react-router-dom';

interface AuthContextType {
  setUser: (user: User | null | undefined) => void;
  user: User | null | undefined;
  cookies: Cookies;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null | undefined>(null);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies.get('authorization_token');
  const location = useLocation();

  const checkAuth = async () => {
    try {
      const response = await axios.get(
        `${apiRoute}/auth` as string,
        token && {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setUser(response.data.user);

      const currentPath = location.pathname;

      if (currentPath === '/') {
        navigate('/main-menu');
      }
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    if (token) {
      checkAuth();
    } else {
      navigate('/');
    }
  }, [token]);

  const updateUser = (newUser: User | null | undefined) => {
    setUser(newUser);
  };

  const authContextValue: AuthContextType = {
    setUser: updateUser,
    user,
    cookies,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
