import styled, { CSSProperties } from 'styled-components';
import { colors } from '../../style-utils/colors';
import { font } from '../../style-utils/text';

export const eyeIconStyles: CSSProperties = {
  position: 'absolute',
  zIndex: '1',
  bottom: '10px',
  cursor: 'pointer',
  width: '20px',
  height: '20px',
  right: '15px',
  color: '#637083',
};

export const LogInWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  background: ${colors.purple_light};
`;

export const LogInFormContainer = styled.div`
  width: 700px;
  height: 500px;
  background: white;
  border-radius: 8px;
  box-shadow: 4px 4px 15px 0px #0000000d;
  display: flex;
  flex-direction: row;
`;

export const LogInForm = styled.form`
  width: 400px;
  height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 32px;
  position: relative;
`;

export const LogInTitle = styled.p`
  font-family: ${font.family};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  color: ${colors.grey_dark};
`;

export const LogInSubTitle = styled.p`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: ${colors.grey};
  margin-top: 10px;
  margin-bottom: 32px;
`;

export const TextContainer = styled.div`
  width: 300px;
  height: 500px;
  background: linear-gradient(
    198.94deg,
    #946ed1 1.19%,
    #7e61ae 70.68%,
    #361b62 100%
  );
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.white};
  border-radius: 0 8px 8px 0;
`;

export const CompanyTitle = styled.h4`
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
`;

export const Description = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonContainer = styled.button`
  background: ${colors.purple_dark};
  width: fitContent;
  height: 48px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:nth-of-type(2) {
    margin-top: 16px;
    position: relative;
  }
`;

export const Label = styled.label`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey_dark};
  margin-bottom: 10px;
`;

export const StyledInput = styled.input`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const ErrorMessage = styled.p`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: ${colors.error};
`;
