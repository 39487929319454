import React from 'react';
import styled from 'styled-components';

const FlexTable = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  padding: 10px;
  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const Cell = styled.div`
  flex: 1; // Makes each cell take up equal space
  &:first-child {
    font-weight: bold;
  }
`;

interface BuildingDataProps {
  // eslint-disable-next-line
  buildingData?: any;
}

export const BuildingData: React.FC<BuildingDataProps> = ({ buildingData }) => {
  const renderRows = () => {
    return (
      <>
        <Row>
          <Cell>Building Name:</Cell>
          <Cell>{buildingData?.property_name}</Cell>
        </Row>
        <Row>
          <Cell>Address:</Cell>
          <Cell>{buildingData?.address}</Cell>
        </Row>
        <Row>
          <Cell>Contact Person:</Cell>
          <Cell>{buildingData?.contact_person}</Cell>
        </Row>

        <Row>
          <Cell>Phone Number:</Cell>
          <Cell>{buildingData?.phone_number}</Cell>
        </Row>
        {buildingData?.second_contact_person ? (
          <Row>
            <Cell>Second Contact Person:</Cell>
            <Cell>{buildingData?.second_contact_person}</Cell>
          </Row>
        ) : null}
        {buildingData?.second_phone_number ? (
          <Row>
            <Cell>Second Phone Number:</Cell>
            <Cell>{buildingData?.second_phone_number}</Cell>
          </Row>
        ) : null}
      </>
    );
  };

  return <FlexTable>{renderRows()}</FlexTable>;
};
