import styled from 'styled-components';

export const StyledButtonWrapper = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  width: 70%;
  padding: 16px;
  height: 28px;
  align-items: center;
  border-radius: 6px;
  border: 0.2px solid var(--btn-def, #8c67c9);
  background: #fff;
  margin-bottom: 16px;
  ${({ isSelected }) =>
    isSelected &&
    `  box-shadow: 2px 2px 10px 0px rgba(148, 110, 209, 0.1);
`};
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
