import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
} from '../../layout/layout-container.styled';
import { DashboardMainMenu } from './dashboard-shared-components/dashboard-main-menu';
import { DashboardHeading } from './dashboard-shared-components/dashboard-heading';
import { DashboardContentContainer } from './dashboard.styled';
import { DashboardAddNewBuilding } from './dashboard-buildings-components/dashboard-add-new-building';
import { DashboardEditBuildingById } from './dashboard-buildings-components/dashboard-edit-building-by-id';
import { BuildingData } from '../../../interfaces/building-interfaces';
import { DashboardBuildings } from './dashboard-buildings-components/dashboard-buildings';
import { DashboardUsers } from './dashboard-users-components/dashboard-users';
import {
  User,
  UserWithBuildingsObjects,
} from '../../../interfaces/user-interfaces';
import { DashboardAddNewUser } from './dashboard-users-components/dashboard-add-new-user';
import { DashboardEditUserById } from './dashboard-users-components/dashboard-edit-user-by-id';
import { DashboardReports } from './dashboard-reports-components/dashboard-reports';
import { useAuth } from '../../../context/auth-provider';
import { useNavigate } from 'react-router-dom';
import { DashboardHome } from './dashboard-home/dashboard-home';
import { ViewEditExport } from './dashboard-reports/dashboard-reports';
import { useWindowHeight } from '../../../style-utils/use-window-height';

export const Dashboard = () => {
  const { user }: { user: User | null | undefined } = useAuth();
  const [dashboardTitle, setDashboardTitle] = useState<string>('');
  const [editableBuildingData, setEditableBuildingData] =
    useState<BuildingData>();
  const [editableUserData, setEditableUserData] = useState<
    UserWithBuildingsObjects | User | undefined
  >();

  const navigate = useNavigate();
  const location = useLocation();

  const updateDashboardTitle = (newTitle: string) => {
    setDashboardTitle(newTitle);
  };

  const updateEditableBuildingData = (buildingToBeUpdated: BuildingData) => {
    setEditableBuildingData(buildingToBeUpdated);
  };

  const updateEditableUserData = (
    userToBeUpdated: UserWithBuildingsObjects | User
  ) => {
    setEditableUserData(userToBeUpdated);
  };

  const renderDashboardContentAccordingUrl = (currentUrl: string) => {
    switch (currentUrl) {
      case '/dashboard/buildings':
        return (
          <DashboardBuildings
            updateEditableBuildingData={updateEditableBuildingData}
          />
        );
      case '/dashboard/buildings/':
        return (
          <DashboardBuildings
            updateEditableBuildingData={updateEditableBuildingData}
          />
        );
      case '/dashboard/users':
        return (
          <DashboardUsers updateEditableUserData={updateEditableUserData} />
        );
      case '/dashboard/users/':
        return (
          <DashboardUsers updateEditableUserData={updateEditableUserData} />
        );

      case '/dashboard/reports':
        return <DashboardReports />;
      case '/dashboard/reports/':
        return <DashboardReports />;

      case '/dashboard/buildings/add-new-property':
        if (user?.role === 'admin') {
          return <DashboardAddNewBuilding />;
        } else {
          navigate('/dashboard/buildings');

          return <div>Access denied!</div>;
        }
      // return <DashboardAddNewBuilding />;
      case '/dashboard/buildings/add-new-property/':
        if (user?.role === 'admin') {
          return <DashboardAddNewBuilding />;
        } else {
          navigate('/dashboard/buildings');

          return <div>Access denied!</div>;
        }
      // return <DashboardAddNewBuilding />;
      case '/dashboard/users/add-new-user':
        if (user && user?.role === 'admin') {
          return <DashboardAddNewUser />;
        } else {
          navigate('/dashboard/users');

          return <div>Access denied!</div>;
        }
      // return <DashboardAddNewUser />;
      case '/dashboard/users/add-new-user/':
        if (user?.role === 'admin') {
          return <DashboardAddNewUser />;
        } else {
          navigate('/dashboard/users');

          return <div>Access denied!</div>;
        }
      // return <DashboardAddNewUser />;
      case '/dashboard/buildings/edit-property':
        return (
          <DashboardEditBuildingById
            editableBuildingData={editableBuildingData}
          />
        );
      case '/dashboard/buildings/edit-property/':
        return (
          <DashboardEditBuildingById
            editableBuildingData={editableBuildingData}
          />
        );

      case '/dashboard/users/edit-user':
        return <DashboardEditUserById editableUserData={editableUserData} />;
      case '/dashboard/users/edit-user/':
        return <DashboardEditUserById editableUserData={editableUserData} />;
      case '/dashboard/home':
        return <DashboardHome />;
      case '/dashboard/view-edit-export':
        return <ViewEditExport />;
      default:
        return <DashboardHome />;
    }
  };

  const height = useWindowHeight();

  return (
    <LayoutContainer height={height} id="dashboard-layout">
      <ContentWrapper dashboard={true}>
        <NavbarAndContentContainer id="dashboard-sidebar">
          <DashboardMainMenu updateDashboardTitle={updateDashboardTitle} />
          <ContentAndButtonsContainer dashboard={true}>
            <DashboardHeading title={dashboardTitle ? dashboardTitle : ''} />
            <DashboardContentContainer>
              {
                // user?.role &&
                renderDashboardContentAccordingUrl(location.pathname)
              }
            </DashboardContentContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
