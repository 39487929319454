import styled from 'styled-components';

export const font = {
  family: 'Inter',
  weight: {
    normal: 300,
    bold: 700,
  },
  size: {
    xxs: 12,
    xs: 14,
    s: 16,
    m: 18,
    l: 20,
    xl: 28,
  },
  color: {
    gray: '#637083',
    purple: '#8C67C9',
  },
};

export const Text = styled.p<{
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  lineHeight?: string;
  color?: string;
  backgroundColour?: string;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
  align?: string;
  width?: string;
  margin?: string;
  borderBottom?: string;
}>`
  display: flex;
  font-family: ${({ fontFamily }) => fontFamily || 'Inter'};
  font-weight: ${({ fontWeight }) => fontWeight || '300'};
  font-size: ${({ fontSize }) => `${fontSize}px` || '20px'};
  color: ${({ color }) => color || 'inherit'};
  background-color: ${({ backgroundColour }) =>
    backgroundColour || 'transparent'};
  margin-top: ${({ mt }) => mt && `${mt}px`};
  margin-bottom: ${({ mb }) => mb && `${mb}px`};
  margin-left: ${({ ml }) => ml && `${ml}px`};
  margin-right: ${({ mr }) => mr && `${mr}px`};
  padding-top: ${({ pt }) => pt && `${pt}px`};
  padding-bottom: ${({ pb }) => pb && `${pb}px`};
  padding-left: ${({ pl }) => pl && `${pl}px`};
  padding-right: ${({ pr }) => pr && `${pr}px`};
  text-align: ${({ align }) => align || 'left'};
  width: ${({ width }) => width && `${width}%`};
  margin: ${({ margin }) => margin && `${margin}`};
  border-bottom: ${({ borderBottom }) => borderBottom && `${borderBottom}`};
`;

export const EllipsisText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2; // Limit to two lines
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 3em; // Adjust this value based on your line-height
  line-height: 1.5em; // Adjust this value based on the design
`;
