import React from 'react';
import {
  ResponsiveTableWrapper,
  StyledTable,
} from '../dashboard/dashboard-reports/dashboard-logs.styled';
import {
  formatString,
  stripHtmlTags,
} from '../../../function-utils/string-converter';

interface ReportsTableProps {
  // eslint-disable-next-line
  log: any[];
}

export const ReportsTable: React.FC<ReportsTableProps> = ({ log }) => {
  // Function to format the date-time in American format (MM/DD/YYYY, HH:MM:SS)
  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);

    return `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleTimeString(
      'en-US',
      { hour12: true }
    )}`;
  };
  const headers =
    log?.length > 0
      ? Object.keys(log[0])
          .filter(
            header =>
              header !== 'userId' &&
              header !== 'id' &&
              header !== 'updatedAt' &&
              header !== 'property_ids' &&
              log.some(item => item[header])
          )
          .map(header => header)
      : [];

  return (
    <ResponsiveTableWrapper>
      <StyledTable>
        <thead>
          <tr>
            {headers?.map(header => (
              <th key={header}>{formatString(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {log?.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {headers?.map(header => {
                // Format 'createdAt' header's value
                const cellValue =
                  header === 'createdAt'
                    ? formatDateTime(item[header])
                    : stripHtmlTags(item[header]);

                return (
                  <td key={`${rowIndex}-${header}`}>
                    {header === 'names' || header === 'lastNames'
                      ? item?.[header]?.map((value: string, index: number) => (
                          <div key={index}>{value}</div>
                        ))
                      : cellValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </ResponsiveTableWrapper>
  );
};
