import React, { useEffect, useRef, useState } from 'react';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import {
  ActionModal,
  UsersListing,
  ButtonContainer,
  ButtonTitle,
  Column,
  DashboardContent,
  ItemContainer,
  NewUserButton,
  StyledFlex,
  StyledFlexRow,
  StyledImage,
  Title,
  RoleDiv,
  ActionModalBackground,
  DeleteModalBackground,
  DeleteModal,
  CancelConfirmButton,
  ViewPropertiesButton,
  PropertiesModal,
} from './dashboard-users.styled';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from '../../../icons/three-dots';
import { PaginationButtons } from '../dashboard-shared-components/table-pagination/table-pagination';
import { usePagination } from '../../../hooks/use-pagination';
import {
  createItem,
  fetchAllItems,
  updatePropertyOrUserItem,
} from '../../../requests/requests';
import {
  User,
  UserWithBuildingsObjects,
} from '../../../../interfaces/user-interfaces';
import { useAuth } from '../../../../context/auth-provider';
import { RightArrowBlack } from '../../../icons/right-arrow-black';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { SearchInputContainer } from '../dashboard.styled';
import { StyledInput } from '../../../login/login.styled';

interface DashboardUsersProps {
  updateEditableUserData: (newValue: User) => void;
}

export const DashboardUsers: React.FC<DashboardUsersProps> = ({
  updateEditableUserData,
}) => {
  const { user }: { user: User | null | undefined } = useAuth();
  const [usersData, setUsersData] = useState<UserWithBuildingsObjects[]>([]);
  const [editableUserId, setEditableUserId] = useState<string | null>(null);
  const [userToBeDeleted, setUserToBeDeleted] = useState<User | null>(null);
  const [isActionModalVisible, setIsActionModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isPropertiesModalVisible, setIsPropertiesModalVisible] =
    useState<boolean>(false);
  const propertiesModalRef = useRef<HTMLDivElement | null>(null);

  const [userParam, setUserParam] = useState('');
  // eslint-disable-next-line
  const handleSearchChange = (event: any) => {
    setUserParam(event.target.value);
    filterUsers(event.target.value);
  };
  // eslint-disable-next-line
  const [filteredUsers, setFilteredUsers] = useState<
    UserWithBuildingsObjects[]
  >([]);
  const isSearching = !userParam || userParam.length < 1;
  const filterUsers = (searchTerm: string) => {
    if (isSearching) {
      setFilteredUsers(usersData);
      resetPagination();

      return;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const result = usersData.filter(user => {
      const matchesNameEmailPhone =
        user.full_name.toLowerCase().includes(lowerCaseSearchTerm) ||
        user.email.toLowerCase().includes(lowerCaseSearchTerm) ||
        user.phone_number.includes(lowerCaseSearchTerm);

      // Check if search term matches any property_name within propertiesObjects
      const matchesPropertyName = user?.propertiesObjects?.some(
        // eslint-disable-next-line
        (property: any) =>
          property.property_name.toLowerCase().includes(lowerCaseSearchTerm)
      );

      return matchesNameEmailPhone || matchesPropertyName;
    });

    setFilteredUsers(result);
  };

  const {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
    resetPagination,
  } = usePagination(isSearching ? usersData : filteredUsers);

  const totalPages = getTotalPages();
  const navigate = useNavigate();

  const openAddNewUserForm = () => {
    navigate('users/add-new-user');
  };

  const getUsersfromDB = async () => {
    let response;
    if (user && user?.role === 'admin') {
      //This is a get request to get all users, because the logged in user has admin role
      response = await fetchAllItems('dashboard/users');
    } else {
      //This is a post request (but it's acctually a get req) to get only the users that are connected to the properties that the logged in user manager is managing
      if (user) {
        response = await createItem('dashboard/users', user);
      }
    }

    setUsersData(response as UserWithBuildingsObjects[]);
  };

  useEffect(() => {
    getUsersfromDB();
  }, [user]);

  const showEditDeleteActions = (userId: string) => {
    setIsActionModalVisible(!isActionModalVisible);

    setEditableUserId(userId);

    const filteredUserById = usersData?.filter(user => user.id === userId);

    setUserToBeDeleted(filteredUserById[0] as User);
    updateEditableUserData(filteredUserById[0] as User);
  };

  const hideEditDeleteActions = () => {
    setIsActionModalVisible(false);
    setEditableUserId(null);
    setUserToBeDeleted(null);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteModalVisible(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (userToBeDeleted) {
        userToBeDeleted.status = 'inactive';
        await updatePropertyOrUserItem('dashboard/users', userToBeDeleted);
        // console.log(response);
      }

      getUsersfromDB();
    } catch (error) {
      console.error(error);
    } finally {
      setUserToBeDeleted(null);
    }
  };

  const handleViewProperties = (userId: string) => {
    setIsPropertiesModalVisible(!isPropertiesModalVisible);
    setEditableUserId(userId);

    const filteredUserById = usersData?.filter(user => user.id === userId);

    updateEditableUserData(filteredUserById[0] as User);
  };

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (
        propertiesModalRef.current &&
        !propertiesModalRef.current.contains(event.target)
      ) {
        // Clicked outside the container, so close it
        setIsPropertiesModalVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useRedirectOnBack('/dashboard');

  return (
    <DashboardContent id="dashboard-users-content">
      <UsersListing>
        <Title>
          <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
            Users listing
          </Text>
          <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
            Please select user to show
          </Text>
        </Title>
        <SearchInputContainer>
          {/* <Text
            pr={16}
            fontFamily={font.family}
            fontSize={19}
            color={`${colors.black}`}
            fontWeight={700}
          >
            Search:
          </Text> */}
          <StyledInput
            name="userSearch"
            type="text"
            value={userParam}
            onChange={handleSearchChange}
            placeholder="Search..."
          />
        </SearchInputContainer>
        {user?.role === 'admin' && (
          <NewUserButton
            id="new-user-button"
            onClick={() => openAddNewUserForm()}
          >
            Add new user
          </NewUserButton>
        )}
      </UsersListing>

      <Column>
        <StyledFlexRow>
          <StyledFlex>
            <ItemContainer isHeader width={20}>
              <Text
                mt={0}
                mb={0}
                fontSize={16}
                fontWeight={600}
                color={colors.grey_dark}
              >
                User name
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={20}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Property
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={20}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Email
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={18}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Phone
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={14}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Role
              </Text>
            </ItemContainer>
            {user?.role === 'admin' && (
              <ItemContainer isHeader width={8}>
                <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                  Action
                </Text>
              </ItemContainer>
            )}
          </StyledFlex>
        </StyledFlexRow>
        {/* {!loading ? ( */}

        {
          displayedData.map((e, i) => {
            return (
              <StyledFlexRow key={i}>
                <StyledFlex>
                  <ItemContainer isHeader width={20}>
                    <StyledImage
                      src={e.user_image !== '' ? e.user_image : ''}
                      alt=""
                    />

                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.full_name
                        ? `${e.full_name}`
                        : e.propertiesObjects[0]?.property_name &&
                          `${e.propertiesObjects[0].property_name}`}
                    </Text>
                  </ItemContainer>

                  <ItemContainer
                    isHeader
                    width={20}
                    style={{ cursor: 'pointer' }}
                    isActions={true}
                  >
                    <ViewPropertiesButton
                      onClick={() => handleViewProperties(e.id)}
                    >
                      <Text
                        mt={0}
                        mb={0}
                        mr={6}
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grey_dark}
                      >
                        View Properties
                      </Text>
                      <RightArrowBlack />
                      {isPropertiesModalVisible && editableUserId === e.id && (
                        <>
                          <ActionModalBackground
                            id="properties-modal-background"
                            onClick={() => setIsPropertiesModalVisible(false)}
                          />
                          <PropertiesModal>
                            {e.propertiesObjects.map(
                              (property: BuildingData, i: number) => (
                                <Text
                                  key={i}
                                  mt={0}
                                  mb={0}
                                  fontSize={14}
                                  fontWeight={400}
                                  color={colors.grey_dark}
                                >
                                  {property.property_name}
                                </Text>
                              )
                            )}
                          </PropertiesModal>
                        </>
                      )}
                    </ViewPropertiesButton>
                  </ItemContainer>
                  <ItemContainer isHeader width={20}>
                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.email}
                    </Text>
                  </ItemContainer>

                  <ItemContainer isHeader width={18}>
                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.phone_number}
                    </Text>
                  </ItemContainer>

                  <ItemContainer isHeader width={14}>
                    <RoleDiv
                      isManager={e.role === 'manager'}
                      isAdmin={e.role === 'admin'}
                    >
                      <Text
                        mt={0}
                        mb={0}
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grey_dark}
                      >
                        {e.role.charAt(0).toUpperCase() +
                          e.role.slice(1).toLowerCase()}
                      </Text>
                    </RoleDiv>
                  </ItemContainer>

                  {user?.role === 'admin' && (
                    <ItemContainer
                      isHeader
                      width={8}
                      onClick={() => showEditDeleteActions(e.id)}
                      style={{ cursor: 'pointer' }}
                      isActions={true}
                    >
                      <ThreeDots />

                      {isActionModalVisible && editableUserId === e.id && (
                        <>
                          <ActionModalBackground
                            id="action-modal-background"
                            // onClick={() => setIsActionModalVisible(false)}
                            onClick={() => hideEditDeleteActions()}
                          />
                          <ActionModal>
                            <ButtonContainer
                              isDelete={true}
                              onClick={handleDeleteButtonClick}
                            >
                              <ButtonTitle>Delete</ButtonTitle>
                            </ButtonContainer>
                            <ButtonContainer
                              onClick={() => navigate(`users/edit-user`)}
                            >
                              <ButtonTitle>Edit</ButtonTitle>
                            </ButtonContainer>
                          </ActionModal>
                        </>
                      )}
                    </ItemContainer>
                  )}

                  {isDeleteModalVisible && (
                    <DeleteModalBackground
                      id="delete-modal-background"
                      onClick={() => setIsDeleteModalVisible(false)}
                    >
                      <DeleteModal
                        id="delete-modal"
                        onClick={() => setIsDeleteModalVisible(false)}
                      >
                        <Text fontSize={28} fontWeight={700} mb={0}>
                          Confirm delete
                        </Text>
                        <Text fontSize={16} mt={4} mb={40}>
                          Are you sure you want to delete?
                        </Text>
                        <CancelConfirmButton
                          isConfirm={true}
                          onClick={() => handleConfirmDelete()}
                        >
                          Confirm
                        </CancelConfirmButton>
                        <CancelConfirmButton onClick={handleCancelDelete}>
                          Cancel
                        </CancelConfirmButton>
                      </DeleteModal>
                    </DeleteModalBackground>
                  )}
                </StyledFlex>
              </StyledFlexRow>
            );
          })
          //   ) : (
          //     <Spinner />
          //   )
        }
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={totalPages}
        />
      </Column>
    </DashboardContent>
  );
};
