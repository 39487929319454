import React, { useEffect, useState } from 'react';
import {
  ButtonsWrapper,
  DashboardHomeButton,
  DashboardWrapper,
  StyledRow,
  TitleAndButtonsWrapper,
} from './dashboard-home.styled';
import { UsersListing } from '../dashboard-users-components/dashboard-users.styled';
import { Title } from '../dashboard-buildings-components/dashboard-buildings.styled';
import { Text } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { OptionsInput } from '../../../styled-options/styled-options';
import { User } from '../../../../interfaces/user-interfaces';
import { useAuth } from '../../../../context/auth-provider';
import useBuildingData, { TimePeriod } from './use-get-building-data';
import { Column, Row } from '../../../layout/design-helpers';
import { DashboardHomeChart } from './dashboard-home-chart';
import { DailyLogAnalytics } from './daily-log-analytics';
import { fetchAllItems } from '../../../requests/requests';
import { useNavigate } from 'react-router-dom';

export const DashboardHome = () => {
  // eslint-disable-next-line
  const [analyticsData, setAnalyticsData] = useState<any>();

  const { user }: { user: User | null | undefined } = useAuth();
  const {
    buildingNames,
    timePeriod,
    setTimePeriod,
    selectedBuilding,
    changeHandler,
    dailyData,
    monthlyData,
    weeklyData,
  } = useBuildingData(user);

  let data;
  switch (timePeriod) {
    case TimePeriod.Daily:
      data = dailyData;
      break;
    case TimePeriod.Weekly:
      data = weeklyData;
      break;
    case TimePeriod.Monthly:
      data = monthlyData;
      break;
    default:
      throw new Error('Unsupported time period');
  }

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedBuilding?.id) {
          const queryParams = {
            property_ids: selectedBuilding.id,
          };

          const response = await fetchAllItems('/daily-log', 4, queryParams);
          setAnalyticsData(response);
        }
      } catch (error) {
        console.error('Failed to fetch analytics data:', error);
        setAnalyticsData([]);
      }
    };

    if (selectedBuilding) {
      fetchData();
    }
  }, [selectedBuilding]);

  return (
    <>
      <DashboardWrapper>
        <Column overflow="hidden">
          <Row maxHeight={76}>
            <TitleAndButtonsWrapper>
              <UsersListing>
                <Title>
                  <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
                    Property Analytics
                  </Text>
                  <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
                    Please select property to show
                  </Text>
                </Title>
              </UsersListing>
              <ButtonsWrapper>
                <OptionsInput
                  isDashboard
                  selectedOption={
                    selectedBuilding ? selectedBuilding?.property_name : ''
                  }
                  options={buildingNames}
                  icon={undefined}
                  changeHandler={changeHandler}
                  changeHandlerName={'selectedBuilding'}
                />
                <DashboardHomeButton
                  isSelected={timePeriod === TimePeriod.Daily}
                  id="new-building-button"
                  onClick={() => setTimePeriod(TimePeriod.Daily)}
                >
                  {TimePeriod.Daily}
                </DashboardHomeButton>
                <DashboardHomeButton
                  isSelected={timePeriod === TimePeriod.Weekly}
                  id="new-building-button"
                  onClick={() => setTimePeriod(TimePeriod.Weekly)}
                >
                  {TimePeriod.Weekly}
                </DashboardHomeButton>
                <DashboardHomeButton
                  isSelected={timePeriod === TimePeriod.Monthly}
                  id="new-building-button"
                  onClick={() => setTimePeriod(TimePeriod.Monthly)}
                >
                  {TimePeriod.Monthly}
                </DashboardHomeButton>
              </ButtonsWrapper>
            </TitleAndButtonsWrapper>
          </Row>
          <Row maxHeight={76}>
            <TitleAndButtonsWrapper>
              <UsersListing>
                <Title>
                  <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
                    {selectedBuilding
                      ? selectedBuilding?.property_name
                      : 'Select a property'}
                  </Text>
                  <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
                    {selectedBuilding
                      ? selectedBuilding?.address
                      : 'Select a property'}
                  </Text>
                </Title>
              </UsersListing>
            </TitleAndButtonsWrapper>
          </Row>
          <StyledRow isCentered minHeight={240}>
            <DashboardHomeChart dataType={timePeriod} data={data} />
          </StyledRow>
          <Row maxHeight={76}>
            <TitleAndButtonsWrapper>
              <UsersListing>
                <Title>
                  <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
                    Daily Log Analytics
                  </Text>
                  <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
                    Please select property to show
                  </Text>
                </Title>
              </UsersListing>
              <ButtonsWrapper>
                <OptionsInput
                  isDashboard
                  selectedOption={
                    selectedBuilding ? selectedBuilding?.property_name : ''
                  }
                  options={buildingNames}
                  icon={undefined}
                  changeHandler={changeHandler}
                  changeHandlerName={'selectedBuilding'}
                />
                <DashboardHomeButton
                  isSelected={true}
                  // navigate to somewhere
                  //
                  //
                  onClick={() =>
                    navigate('/dashboard/view-edit-export', { replace: true })
                  }
                >
                  View / Edit / Export
                </DashboardHomeButton>
              </ButtonsWrapper>
            </TitleAndButtonsWrapper>
          </Row>
          <Row>
            <Column>
              <div
                style={{
                  height: '212px',
                  overflow: 'auto',
                }}
              >
                {/* eslint-disable-next-line */}
                {analyticsData?.map((e: any, i: number) => {
                  return (
                    <DailyLogAnalytics
                      selectedBuilding={selectedBuilding}
                      key={
                        i +
                        (selectedBuilding !== null
                          ? selectedBuilding?.id.toString()
                          : '')
                      }
                      data={e}
                    />
                  );
                })}
              </div>
            </Column>
          </Row>
        </Column>
      </DashboardWrapper>
    </>
  );
};
