import React, { useEffect, useState } from 'react';
import { codes } from '../../../../constants/country-codes';
import {
  BuildingData,
  EditedBuildingData,
} from '../../../../interfaces/building-interfaces';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import { RightArrow } from '../../../icons/right-arrow';
import { Spinner } from '../../../icons/spinner';
import { UploadImageIcon } from '../../../icons/upload-image';
import {
  AddNewPropertyForm,
  AddNewPropertyFormContainer,
  SaveButton,
  ButtonTitle,
  CountryCodeContainer,
  CountryCodeSelect,
  DashboardContent,
  ImageUploadContainer,
  InputContainer,
  Label,
  StyledBuildingInput,
  Title,
  ButtonsContainer,
  CancelButton,
  ErrorLabel,
  StyledImage,
} from './dashboard-buildings.styled';
import { regexValidation } from '../../../../function-utils/regex-validation';
import { useNavigate } from 'react-router-dom';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { resizeImage } from './dashboard-add-new-building';

const buildingInitialState: BuildingData = {
  id: '',
  property_image: null,
  property_name: '',
  contact_person: '',
  second_contact_person: '',
  position: '',
  country: 'USA',
  address: '',
  email: [],
  phone_number: '',
  second_phone_number: '',
};

interface DashboardEditBuildingByIdProps {
  editableBuildingData: BuildingData | undefined;
}

const numberOfEmailsObject = {
  first_email: 'first_email',
  second_email: 'second_email',
  third_email: 'third_email',
  fourth_email: 'fourth_email',
  fifth_email: 'fifth_email',
  sixth_email: 'sixth_email',
  seventh_email: 'seventh_email',
  eighth_email: 'eighth_email',
  ninth_email: 'ninth_email',
  tenth_email: 'tenth_email',
};

const initialVisibilityEmailInputs = {
  first_email: true,
  second_email: false,
  third_email: false,
  fourth_email: false,
  fifth_email: false,
  sixth_email: false,
  seventh_email: false,
  eighth_email: false,
  ninth_email: false,
  tenth_email: false,
};

export const DashboardEditBuildingById: React.FC<
  DashboardEditBuildingByIdProps
> = ({ editableBuildingData }) => {
  const [updatedBuildingData, setUpdatedBuildingData] = useState<
    BuildingData | EditedBuildingData
  >(
    editableBuildingData && editableBuildingData.property_name.length > 0
      ? editableBuildingData
      : buildingInitialState
  );
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [secondCountryCode, setSecondCountryCode] = useState<string>('+1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openNewContactInput, setOpenNewContactInput] =
    useState<boolean>(false);
  const [openNewEmailInput, setOpenNewEmailInput] = useState<boolean>(false);
  const [emailInputs, setEmailInputs] = useState<{ [key: string]: boolean }>(
    initialVisibilityEmailInputs
  );
  const [openNewPhoneInput, setOpenNewPhoneInput] = useState<boolean>(false);
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [imageSrc, setImageSrc] = useState<string | null | undefined>(
    updatedBuildingData.property_image as string
  );
  const [
    numberOfEmailInputsToBeDisplayed,
    setNumberOfEmailInputsToBeDisplayed,
  ] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (editableBuildingData) {
      let lastIndex = 0;
      let allPropertyEmails = {};

      editableBuildingData.email.forEach((email, index) => {
        const emailNumber = Object.keys(initialVisibilityEmailInputs)[index];

        setEmailInputs(prevState => ({
          ...prevState,
          [emailNumber]: true,
        }));

        //set separate email fields instead of having an email array of emails
        allPropertyEmails = { ...allPropertyEmails, [emailNumber]: email };

        lastIndex = index;
      });

      const { ...updatedBuildingDataWithoutEmailArray } = updatedBuildingData;

      setUpdatedBuildingData({
        ...(updatedBuildingDataWithoutEmailArray as EditedBuildingData),
        ...allPropertyEmails,
      });

      const emptyEmailFieldToBeDisplayed = Object.keys(
        initialVisibilityEmailInputs
      )[lastIndex + 1];

      setNumberOfEmailInputsToBeDisplayed([emptyEmailFieldToBeDisplayed]);
    }
  }, [editableBuildingData]);

  const openNewInput = (inputType: string) => {
    if (inputType === 'new-contact') {
      setOpenNewContactInput(!openNewContactInput);
    } else if (inputType === 'new-email') {
      setOpenNewEmailInput(!openNewEmailInput);
    } else if (inputType === 'new-phone') {
      setOpenNewPhoneInput(!openNewPhoneInput);
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewBuildingData = (data: any) => {
    const requiredFields: string[] = [
      'property_name',
      'contact_person',
      'position',
      'country',
      'address',
      'phone_number',
    ];

    const emptyFields: string[] = [];

    requiredFields.forEach(field => {
      if (data[field].length === 0) {
        emptyFields.push(field);
      }
    });

    Object.keys(numberOfEmailsObject).forEach(numberOfEmail => {
      const emailNumber = updatedBuildingData[numberOfEmail];
      if (typeof emailNumber === 'string' && emailNumber.length > 0) {
        const emailValidation = regexValidation(
          'email',
          updatedBuildingData[numberOfEmail] as string
        );

        if (!emailValidation.isValidEmail) {
          emptyFields.push(numberOfEmail as string);
        }
      }
    });

    return emptyFields;
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updatedBuildingData.phone_number = '';
    setCountryCode(event.target.value);
  };

  const handleSecondInputCountryCode = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    updatedBuildingData.second_phone_number = '';
    setSecondCountryCode(event.target.value);
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (name === 'property_image') {
      // Check if the event is from file input and has files
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const file = event.target.files[0];
        if (file) {
          setUpdatedBuildingData({ ...updatedBuildingData, [name]: file });
        }
      }
    } else if (name === 'phone_number' || name === 'second_phone_number') {
      // Validate phone number input to allow only numeric characters
      let numericValue = value.replace(/\D/g, '');
      numericValue = '+' + numericValue;

      setUpdatedBuildingData({ ...updatedBuildingData, [name]: numericValue });
    } else {
      setUpdatedBuildingData({ ...updatedBuildingData, [name]: value });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    setIsLoading(true);

    const invalidFields = validateNewBuildingData(updatedBuildingData);

    setInvalidFieldsInputArray(invalidFields);

    if (invalidFields.length === 0) {
      try {
        const formData = new FormData();

        if (updatedBuildingData.property_image instanceof File) {
          const resizedImage = await resizeImage(
            updatedBuildingData.property_image
          );
          formData.append('property_image', resizedImage);
        } else {
          console.log('No file selected or incorrect file format');
        }

        formData.append('id', updatedBuildingData.id);
        formData.append('property_name', updatedBuildingData.property_name);
        formData.append('contact_person', updatedBuildingData.contact_person);
        formData.append('position', updatedBuildingData.position);
        formData.append('country', updatedBuildingData.country);
        formData.append('address', updatedBuildingData.address);
        formData.append('phone_number', updatedBuildingData.phone_number);
        formData.append(
          'second_contact_person',
          updatedBuildingData.second_contact_person
        );
        formData.append(
          'second_phone_number',
          updatedBuildingData.second_phone_number
        );

        Object.keys(numberOfEmailsObject).map(emailNumber => {
          if (
            updatedBuildingData[emailNumber] &&
            typeof updatedBuildingData[emailNumber] === 'string'
          ) {
            formData.append(
              emailNumber as string,
              updatedBuildingData[emailNumber] as string
            );
          }
        });

        const response = await axios.put(
          `${apiRoute}/dashboard/buildings/edit-property`,
          formData
        );

        if (response.status === 200) {
          // console.log('User successfully added:', response.data);
          navigate('buildings');
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        setIsLoading(false);
        setUpdatedBuildingData(buildingInitialState);
        setInvalidFieldsInputArray([]);
      }
    } else {
      // console.log('Form validation failed');
      setIsLoading(false);
    }
  };

  const clearData = () => {
    setUpdatedBuildingData(buildingInitialState);
    setInvalidFieldsInputArray([]);
    navigate('buildings');
  };

  useEffect(() => {
    if (updatedBuildingData.property_name === '') {
      navigate('dashboard/buildings');
    }
  }, [editableBuildingData]);

  useEffect(() => {
    if (
      typeof updatedBuildingData.property_image === 'object' &&
      updatedBuildingData.property_image !== null
    ) {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target) {
          setImageSrc(event.target.result as string);
        }
      };

      reader.readAsDataURL(updatedBuildingData.property_image as File);
    }
  }, [updatedBuildingData.property_image]);

  const openEmailInput = (emailNumber: string, index: number) => {
    const nextEmailIndex = index + 1;
    if (nextEmailIndex < Object.keys(numberOfEmailsObject).length + 1) {
      const email = Object.keys(numberOfEmailsObject)[nextEmailIndex];

      // Update the state array by filtering out the previous string and then adding the new email
      setNumberOfEmailInputsToBeDisplayed(prevState => {
        const filteredArray = prevState.filter(
          prevEmail => prevEmail !== emailNumber
        );

        if (!emailInputs[email]) {
          return [...filteredArray, email];
        } else {
          return [...filteredArray];
        }
      });

      // Set the corresponding email input state to true
      setEmailInputs(prevState => ({
        ...prevState,
        [emailNumber]: true,
      }));
    }
  };

  const closeEmailInput = (emailNumber: string, index: number) => {
    const email = Object.keys(numberOfEmailsObject)[index];

    setUpdatedBuildingData({ ...updatedBuildingData, [emailNumber]: '' });

    setNumberOfEmailInputsToBeDisplayed(prevState => {
      // Filter out the previous email
      const filteredArray = prevState.filter(
        prevEmail => prevEmail !== emailNumber
      );

      // Concatenate the filtered array with the new email
      return [...filteredArray, email];
    });

    setEmailInputs(prevState => ({
      ...prevState,
      [emailNumber]: false,
    }));
  };

  useRedirectOnBack('/dashboard');

  return (
    <DashboardContent id="dashboard-edit-building-form" isAddNewBuilding={true}>
      <Title>
        <Text mt={16} ml={16} mb={0} fontSize={28} fontWeight={700}>
          Edit property
        </Text>
        <Text mt={6} ml={16} mb={0} fontSize={14} color={`${colors.grey}`}>
          Please update the property
        </Text>
      </Title>

      <AddNewPropertyFormContainer>
        <AddNewPropertyForm>
          {isLoading && <Spinner isAddNewPropertyForm={true} />}

          <InputContainer isImageUpload={true}>
            <ImageUploadContainer>
              {imageSrc ? (
                <StyledImage
                  src={imageSrc}
                  alt="Property Image"
                  isAddNewPropertyForm
                />
              ) : (
                <UploadImageIcon />
              )}
              <Label isImageUpload={true}>Upload photo</Label>
            </ImageUploadContainer>

            <StyledBuildingInput
              type="file"
              name="property_image"
              accept="image/*"
              placeholder={'Enter property name'}
              onChange={handleInputChange}
              isImageUpload={true}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('property_image') && (
            <ErrorLabel>Please upload a photo*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Property name</Label>
            <StyledBuildingInput
              type="text"
              name="property_name"
              value={updatedBuildingData.property_name}
              placeholder={'Enter property name'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('property_name') && (
            <ErrorLabel>Please enter property name*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Contact person</Label>
            <StyledBuildingInput
              type="text"
              name="contact_person"
              value={updatedBuildingData.contact_person}
              placeholder={'Enter contact person'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('contact_person') && (
            <ErrorLabel>Please enter contact person*</ErrorLabel>
          )}
          {openNewContactInput && (
            <InputContainer>
              <Label>Second contact person</Label>
              <StyledBuildingInput
                type="text"
                name="second_contact_person"
                value={updatedBuildingData.second_contact_person}
                placeholder={'Enter second contact person'}
                onChange={handleInputChange}
              />
            </InputContainer>
          )}
          {!openNewContactInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-contact')}
            >
              + Add new contact person
            </Label>
          )}

          {openNewContactInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-contact')}
            >
              x Close new contact field
            </Label>
          )}

          <InputContainer>
            <Label>Position</Label>
            <StyledBuildingInput
              type="text"
              name="position"
              value={updatedBuildingData.position}
              placeholder={'Enter position'}
              onChange={handleInputChange}
            />
          </InputContainer>
          {invalidFieldsInputArray.includes('position') && (
            <ErrorLabel>Please enter position*</ErrorLabel>
          )}

          <InputContainer>
            <Label>Address</Label>
            <CountryCodeContainer>
              <CountryCodeSelect
                id="country"
                name="country"
                onChange={handleInputChange}
                value={updatedBuildingData.country}
              >
                {Object.entries(codes).map(([country, code]) => (
                  <option key={code} value={country}>
                    {country}
                  </option>
                ))}
              </CountryCodeSelect>

              <StyledBuildingInput
                type="text"
                name="address"
                value={updatedBuildingData.address}
                placeholder={'Enter property location'}
                onChange={handleInputChange}
                isCountryCodeInput={true}
              />
            </CountryCodeContainer>
          </InputContainer>
          {invalidFieldsInputArray.includes('address') && (
            <ErrorLabel>Please enter address*</ErrorLabel>
          )}

          {Object.keys(numberOfEmailsObject).map(
            (emailNumber, index) =>
              emailInputs[emailNumber] && (
                <>
                  <InputContainer key={emailNumber}>
                    <Label>
                      {emailNumber.charAt(0).toUpperCase() +
                        emailNumber.slice(1).replace('_', ' ')}{' '}
                      address
                    </Label>
                    <StyledBuildingInput
                      type="email"
                      name={emailNumber}
                      value={updatedBuildingData[emailNumber] as string}
                      placeholder={`Enter ${emailNumber.replace(
                        '_',
                        ' '
                      )} address`}
                      onChange={handleInputChange}
                    />
                  </InputContainer>
                  {invalidFieldsInputArray.includes(emailNumber) && (
                    <ErrorLabel>Please enter a valid email address*</ErrorLabel>
                  )}
                  <Label
                    isOpenningNewInput={true}
                    onClick={() => closeEmailInput(emailNumber, index)}
                  >
                    {`x Close ${emailNumber.replace('_', ' ')} field`}
                  </Label>
                </>
              )
          )}

          {/* Rendering additional email inputs if new email input is opened */}
          {Object.keys(numberOfEmailsObject).map(
            (emailNumber, index) =>
              numberOfEmailInputsToBeDisplayed.includes(emailNumber) && (
                <Label
                  isOpenningNewInput={true}
                  key={emailNumber}
                  onClick={() => openEmailInput(emailNumber, index)}
                >
                  {`+ Add ${emailNumber.replace('_', ' ')} address`}
                </Label>
              )
          )}

          <InputContainer>
            <Label>Phone</Label>
            <CountryCodeContainer>
              <CountryCodeSelect
                id="country-code"
                onChange={e => handleCountryCode(e)}
                name="countryCode"
                value={countryCode}
              >
                {Object.entries(codes).map(([country, code]) => (
                  <option key={code} value={code}>
                    {country}
                  </option>
                ))}
              </CountryCodeSelect>

              <StyledBuildingInput
                type="text"
                name="phone_number"
                value={updatedBuildingData.phone_number || countryCode}
                onChange={handleInputChange}
                isCountryCodeInput={true}
              />
            </CountryCodeContainer>
          </InputContainer>
          {invalidFieldsInputArray.includes('phone_number') && (
            <ErrorLabel>Please enter email address*</ErrorLabel>
          )}

          {openNewPhoneInput && (
            <InputContainer>
              <Label>Second phone number</Label>
              <CountryCodeContainer>
                <CountryCodeSelect
                  id="country-code"
                  onChange={e => handleSecondInputCountryCode(e)}
                  name="secondCountryCode"
                  value={secondCountryCode}
                >
                  {Object.entries(codes).map(([country, code]) => (
                    <option key={code} value={code}>
                      {country}
                    </option>
                  ))}
                </CountryCodeSelect>

                <StyledBuildingInput
                  type="text"
                  name="second_phone_number"
                  value={
                    updatedBuildingData.second_phone_number || secondCountryCode
                  }
                  onChange={handleInputChange}
                  isCountryCodeInput={true}
                />
              </CountryCodeContainer>
            </InputContainer>
          )}
          {!openNewPhoneInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-phone')}
            >
              + Add new phone number
            </Label>
          )}
          {openNewPhoneInput && (
            <Label
              isOpenningNewInput={true}
              onClick={() => openNewInput('new-phone')}
            >
              x Close new phone field
            </Label>
          )}
        </AddNewPropertyForm>
      </AddNewPropertyFormContainer>
      <ButtonsContainer>
        <CancelButton onClick={() => clearData()}>
          <ButtonTitle>Cancel</ButtonTitle>
        </CancelButton>
        <SaveButton onClick={onSubmit}>
          <ButtonTitle>Save</ButtonTitle>
          <RightArrow />
        </SaveButton>
      </ButtonsContainer>
    </DashboardContent>
  );
};
