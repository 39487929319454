import React from 'react';
import { Profile } from '../../profile/profile';
import {
  BuildingDataWrapper,
  IconContainer,
  IconWrapper,
  Logo,
  SidebarContainer,
  SidebarWrapper,
} from './sidebar.styled';
import { VisitorLogIcon } from '../../icons/visitor-log';
import { DailyLogIcon } from '../../icons/daily-log';
import { NavigateFunction } from 'react-router-dom';
import { BuildingData } from '../../building-data/building-data';

interface SidebarProps {
  navigate: NavigateFunction;
  selectedOption: string;
  path?: string;
  // eslint-disable-next-line
  selectedBuilding?: any;
}

export const Sidebar: React.FC<SidebarProps> = ({
  navigate,
  selectedOption,
  path,
  selectedBuilding,
}) => {
  const isVisitorLogHighlighted = selectedOption === '/visitor-log';
  const isDailyLogHighlighted = selectedOption === '/daily-log';

  const isMainMenu = path && path === '/main-menu';

  return (
    <SidebarWrapper>
      <SidebarContainer>
        <Logo />
        <IconContainer>
          {!isMainMenu && (
            <>
              <IconWrapper isHighlighted={false}>
                <VisitorLogIcon
                  stroke={isVisitorLogHighlighted ? 'darkviolet' : undefined}
                  onClick={() => {
                    navigate('/visitor-log', { replace: false });
                  }}
                />
              </IconWrapper>
              <IconWrapper isHighlighted={false}>
                <DailyLogIcon
                  stroke={isDailyLogHighlighted ? 'darkviolet' : undefined}
                  onClick={() => {
                    navigate('/daily-log', { replace: false });
                  }}
                />
              </IconWrapper>
            </>
          )}
        </IconContainer>
        <Profile />
      </SidebarContainer>
      <BuildingDataWrapper>
        <BuildingData buildingData={selectedBuilding} />
      </BuildingDataWrapper>
    </SidebarWrapper>
  );
};
