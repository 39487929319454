import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';
import { Row } from '../../../layout/design-helpers';

export const StyledRow = styled(Row)`
  margin-top: 8px;
  margin-bottom: 8px;
  background: white;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const TitleAndButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  background: white;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  background: white;
  height: 76px;
`;

export const DashboardHomeButton = styled.button<{
  isSelected: boolean;
  isExportToPdfButton?: boolean;
}>`
  background: ${colors.purple_dark};
  width: fit-content;
  height: 32px;
  border: none;
  padding: 4px 12px;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease-out;
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  margin-top: ${({ isExportToPdfButton }) => isExportToPdfButton && '14px'};

  &:hover {
    transform: scale(1.02);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  ${({ isSelected }) =>
    isSelected
      ? `background: ${colors.purple_dark}; color: #fff;`
      : `background: transparent; color: ${colors.black}`}
`;
