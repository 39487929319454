import React from 'react';

interface DateTime {
  date: string;
  time: string;
}

export const getCurrentDateTime = (): DateTime => {
  const now = new Date();

  // Extracting date in MM/DD/YYYY format
  const date = `${String(now.getMonth() + 1).padStart(2, '0')}/${String(
    now.getDate()
  ).padStart(2, '0')}/${now.getFullYear()}`;

  // Extracting time in 12-hour format with AM or PM
  let hours = now.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const time = `${hours}${ampm}`;

  return { date, time };
};
