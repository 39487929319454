/* eslint-disable */
export const processVisitorData = (data: any) => {
  return data.map((item: any) => {
    const count = item.numberOfGuests ?? 1;

    return { count, createdAt: item.createdAt };
  });
};

export const processDailyData = (serverData: any) => {
  // Initialize counts for each hour in UTC
  const hourCountsUTC = new Array(24).fill(null).map((_, index) => ({
    hour: `${index === 0 ? 12 : index > 12 ? index - 12 : index}${index < 12 || index === 24 ? 'AM' : 'PM'
      }`,
    count: 0,
  }));

  // Process the data in UTC
  //   eslint-disable-next-line
  serverData.forEach((entry: any) => {
    const date = new Date(entry.createdAt);
    const hourUTC = date.getUTCHours();
    const hour12UTC = hourUTC === 0 || hourUTC === 12 ? 12 : hourUTC % 12;
    const amPmUTC = hourUTC < 12 ? 'AM' : 'PM';
    const hourLabelUTC = `${hour12UTC}${amPmUTC}`;

    const hourCountUTC = hourCountsUTC.find(hc => hc.hour === hourLabelUTC);
    if (hourCountUTC) {
      hourCountUTC.count += entry.count;
    }
  });

  // Convert UTC hour counts to local time
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const hourCountsLocal = hourCountsUTC.map(hourCount => {
    const hourMatch = hourCount.hour.match(/\d+/);
    if (!hourMatch) {
      return { hour: hourCount.hour, count: hourCount.count };
    }

    let hour = parseInt(hourMatch[0]) - timezoneOffset;
    let amPm = hourCount.hour.includes('AM') ? 'AM' : 'PM';

    // Adjust for AM/PM and 12-hour format
    if (hour <= 0) {
      hour += 12;
      amPm = amPm === 'AM' ? 'PM' : 'AM';
    } else if (hour > 12) {
      hour -= 12;
    }

    return { hour: `${hour}${amPm}`, count: hourCount.count };
  });

  return hourCountsLocal;
};

export const processWeeklyData = (serverData: any) => {
  // Initialize counts for each day of the week
  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const dayCounts = daysOfWeek.map(day => ({ day, count: 0 }));

  serverData.forEach((entry: any) => {
    const date = new Date(entry.createdAt);
    // Adjust for local timezone
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = (date.getDay() + 6) % 7; // Adjusting so that 0 = Monday, 6 = Sunday

    dayCounts[dayOfWeek].count += entry.count;
  });

  return dayCounts;
};

export const processMonthlyData = (serverData: any) => {
  // Function to get the number of days in the current month
  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
  };

  // Use current year and month to determine the number of days in the month
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth(); // Note: January = 0, February = 1, etc.
  const daysInMonth = getDaysInMonth(year, month + 1);

  // Initialize counts for each day of the month
  const dayCounts = new Array(daysInMonth).fill(null).map((_, index) => ({
    day: index + 1, // Day of the month (1-based)
    count: 0,
  }));

  serverData.forEach((entry: any) => {
    const date = new Date(entry.createdAt);
    // Adjust for local timezone
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    if (date.getFullYear() === year && date.getMonth() === month) {
      const dayOfMonth = date.getDate() - 1; // Array index is 0-based
      dayCounts[dayOfMonth].count += entry.count;
    }
  });

  return dayCounts;
};
