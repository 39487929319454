import React from 'react';
import { Button } from './nav-button.styled';
import { Text, font } from '../../style-utils/text';
import { RightArrow } from '../icons/right-arrow';
import { LeftArrow } from '../icons/left-arrow';
import { PlusButton } from '../icons/plus-icon';

interface NavButtonProps {
  text: string;
  background: string;
  textColor: string;
  direction: 'back' | 'forward' | 'increment' | undefined;
  onClick?: () => void;
  disabled: boolean;
}

export const NavButton: React.FC<NavButtonProps> = ({
  text,
  background,
  textColor,
  direction,
  onClick,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      background={background}
      width={direction === 'back' ? '70' : undefined}
    >
      {direction === 'back' ? <LeftArrow /> : null}
      <Text
        fontSize={font.size.xs}
        fontFamily={font.family}
        mt={0}
        mb={0}
        align={'center'}
        color={textColor}
        fontWeight={600}
      >
        {text}
      </Text>
      {direction === undefined && null}
      {direction === 'increment' ? <PlusButton /> : null}
      {direction === 'forward' ? <RightArrow /> : null}
    </Button>
  );
};
