import { Location } from '../../components/pages/visitor-log/shared/location';
import { Notes } from '../../components/pages/visitor-log/shared/notes';

export const getMisscelaneausRoutes = () => {
  const base = '/daily-log/miscellaneous/';

  return [
    { path: `${base}apartment-location`, component: Location },
    {
      path: `${base}description`,
      component: Notes,
      options: ['DESCRIPTION'],
    },
    {
      path: `${base}additional-notes`,
      component: Notes,
      options: ['ADDITIONAL_NOTES'],
    },
  ];
};
// misscelaneaus;
