import React, { useEffect, useState } from 'react';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import { CloseIcon } from '../../../icons/close-icon';
import { FilterIcon } from '../../../icons/filter';
import {
  DashboardContent,
  Analytics,
  Title,
  ExportButton,
  ButtonsFilterExport,
  FilterButtonContainer,
  FilterSidebarBackground,
  FilterSidebar,
  FilterTitle,
  Filters,
  StyledSelectMenu,
  StyledFilterInput,
  ButtonsContainer,
  CancelButton,
  ButtonTitle,
  ConfirmButton,
  TimePickersContainer,
  ErrorLabel,
  TimePickerSelectMenu,
} from './dashboard-reports.styled';
import { DateRangePicker } from 'rsuite';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import { fetchAllItems } from '../../../requests/requests';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { VISITOR_TYPES } from '../../../layout/visitor-log/navbar-controller';
import { CheckRing } from '../../../icons/check-ring';
import { CheckRingContainer } from '../dashboard-users-components/dashboard-users.styled';
import { createItem } from '../../../requests/requests';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';

interface VisitorLogFilter {
  logType: string;
  dateVisitor: [(string | undefined)?, (string | undefined)?];
  timeVisitor: [(string | undefined | null)?, (string | undefined | null)?];
  visitorType: string;
  visitorDetails: string;
  preAuthorized: string;
  buildingActivity: string;
  numberOfGuests: number | string;
  name: string;
  lastName: string;
  parkingValidation: string;
  apartmentLocation: string;
  propertyId: string;
  approval: string;
  priority: string;
  approveGuests?: number;
  nonApproveGuests?: number;
  [key: string]:
    | string
    | number
    | undefined
    | [(string | undefined)?, (string | undefined)?]
    | [(string | undefined | null)?, (string | undefined | null)?];
}

const initialState: VisitorLogFilter = {
  logType: 'Visitor Log',
  dateVisitor: [undefined, undefined],
  timeVisitor: [undefined, undefined],
  visitorType: '',
  visitorDetails: '',
  preAuthorized: '',
  buildingActivity: '',
  numberOfGuests: '',
  name: '',
  lastName: '',
  parkingValidation: '',
  apartmentLocation: '',
  propertyId: '',
  approval: '',
  priority: '',
};

export interface VisitorLogState {
  visitorType: string;
  preAuthorized: string;
  numberOfGuests: number;
  apartmentLocation: string;
  approval: string;
  parkingValidation: string;
  notes: string;
  description: string;
  name: string;
  lastName: string;
  userId: string | undefined;
  createdAt: string;
  creationHour?: string;
  approveGuests?: number;
  nonApproveGuests?: number;
}

export interface DailyLogState {
  buildingActivity: string;
  name: string;
  lastName: string;
  apartmentLocation: string;
  priority: string;
  description: string;
  additionalNotes: string;
  userId: string | undefined;
  notes: string;
  details: string;
  createdAt: string;
  creationHour?: string;
}

interface PieChartData {
  name: string;
  value: number;
  color: string;
}

export const DashboardReports: React.FC = () => {
  const [logsFilterData, setLogsFilterData] =
    useState<VisitorLogFilter>(initialState);
  const [isVisitorForm, setIsVisitorForm] = useState<boolean>(true);
  const [isFilterSidebarVisible, setIsFilterSidebarVisible] =
    useState<boolean>(false);
  const [properties, setProperties] = useState<BuildingData[]>([]);
  const [visitorDetails, setVisitorDetails] = useState<string[] | []>([]);
  const [propertyName, setPropertyName] = useState<string>('');
  const [propertyAddress, setPropertyAddress] = useState<string>('');
  const [showBuildingName, setShowBuildingName] = useState<boolean>(false);
  const [filteredDataResponse, setFilteredDataResponse] = useState<
    VisitorLogState[] | DailyLogState[] | []
  >([]);
  const [mandatoryFilterFields, setMandatoryFilterFields] = useState<string[]>(
    []
  );
  const [showChart, setShowChart] = useState<boolean>(false);
  const [pieChartVisitorLog1State, setPieChartVisitorLog1State] = useState<
    PieChartData[]
  >([]);
  const [pieChartVisitorLog2State, setPieChartVisitorLog2State] = useState<
    PieChartData[]
  >([]);

  const [pieChartDailyLogState, setPieChartDailyLogSatate] = useState<
    PieChartData[]
  >([]);
  const [hourFrom, setHourFrom] = useState<string | undefined>(undefined);
  const [minuteFrom, setMinuteFrom] = useState<string | undefined>(undefined);
  const [amPmFrom, setAmPmFrom] = useState<string | undefined>(undefined);

  const [hourTo, setHourTo] = useState<string | undefined>(undefined);
  const [minuteTo, setMinuteTo] = useState<string | undefined>(undefined);
  const [amPmTo, setAmPmTo] = useState<string | undefined>(undefined);

  const visitorTypes = [
    'Guest',
    'Prospect',
    'Vendor',
    'Delivery',
    'Emergency (911)',
  ];

  const buildingActivities = [
    'Maintenance',
    'Leasing Office',
    'Resident',
    'Miscellaneous',
    'Shift Change',
  ];

  const numberOfGuests = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  const hours = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const minutes = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
  ];

  const priorities = ['Low', 'Medium', 'High'];

  const pieChartVisitorLog1 = [
    { name: 'Guest', value: 0, color: '#0088FE' },
    { name: 'Prospect', value: 0, color: '#00C49F' },
    { name: 'Vendor', value: 0, color: '#FFBB28' },
    { name: 'Delivery', value: 0, color: '#FF8042' },
    { name: 'Emergency (911)', value: 0, color: '#8884d8' },
  ];

  const pieChartVisitorLog2 = [
    { name: 'Guest', value: 0, color: '#0088FE' },
    { name: 'Walkins', value: 0, color: '#00C49F' },
    { name: 'Appointments', value: 0, color: '#00C49F' },
    { name: 'Other-Prospect', value: 0, color: '#00C49F' },
    { name: 'Maintenance Company', value: 0, color: '#FFBB28' },
    { name: 'Service Company', value: 0, color: '#FFBB28' },
    { name: 'Other-Vendor', value: 0, color: '#FFBB28' },
    { name: 'Furniture', value: 0, color: '#FF8042' },
    { name: 'Food', value: 0, color: '#FF8042' },
    { name: 'Flowers', value: 0, color: '#FF8042' },
    { name: 'Medicine', value: 0, color: '#FF8042' },
    { name: 'Other-Delivery', value: 0, color: '#FF8042' },
    { name: 'Fire Department', value: 0, color: '#8884d8' },
    { name: 'Police', value: 0, color: '#8884d8' },
    { name: 'Ambulance', value: 0, color: '#8884d8' },
    { name: 'Other-Emergency', value: 0, color: '#8884d8' },
  ];

  const pieChartDailyLog = [
    { name: 'Maintenance', value: 0, color: '#0088FE' },
    { name: 'Leasing Office', value: 0, color: '#00C49F' },
    { name: 'Resident', value: 0, color: '#FFBB28' },
    { name: 'Miscellaneous', value: 0, color: '#FF8042' },
    { name: 'Shift Change', value: 0, color: '#8884d8' },
  ];

  const visitorDetailsAccordingVisitorType = (visitorType: string | null) => {
    switch (visitorType) {
      case '':
        setVisitorDetails([]);

        return [];
      case undefined:
        setVisitorDetails([]);

        return [];
      case VISITOR_TYPES.GUEST:
        setVisitorDetails(['Yes', 'No']);

        return ['Yes', 'No'];
      case VISITOR_TYPES.PROSPECT:
        setVisitorDetails(['Walkins', 'Appointments']);

        return ['Walkins', 'Appointments'];
      case VISITOR_TYPES.VENDOR:
        setVisitorDetails(['Maintenance Company', 'Service Company', 'Other']);

        return ['Maintenance Company', 'Service Company', 'Other'];

      case VISITOR_TYPES.DELIVERY:
        setVisitorDetails([
          'Furniture',
          'Food',
          'Flowers',
          'Medicine',
          'Other',
        ]);

        return ['Furniture', 'Food', 'Flowers', 'Medicine', 'Other'];

      case VISITOR_TYPES.EMERGENCY:
        setVisitorDetails(['Fire Department', 'Police', 'Ambulance', 'Other']);

        return ['Fire Department', 'Police', 'Ambulance', 'Other'];

      default:
        setVisitorDetails([]);
    }
  };

  const getPropertiesfromDB = async () => {
    const response = await fetchAllItems('dashboard/buildings');

    setProperties(response as BuildingData[]);
  };

  useEffect(() => {
    getPropertiesfromDB();
    const link = document.createElement('link');
    link.rel = 'stylesheet';

    link.href = 'https://unpkg.com/rsuite@4.1.3/dist/styles/rsuite-default.css';

    document.head.appendChild(link);

    // Cleanup function to remove the link if the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const formatDate = (dates: [(Date | undefined)?, (Date | undefined)?]) => {
    const fromDate = new Date(dates[0] as Date);
    const toDate = new Date(dates[1] as Date);

    const fromYear = fromDate.getFullYear();
    const fromMonth = (fromDate.getMonth() + 1).toString().padStart(2, '0');
    const fromDay = fromDate.getDate().toString().padStart(2, '0');

    const toYear = toDate.getFullYear();
    const toMonth = (toDate.getMonth() + 1).toString().padStart(2, '0');
    const toDay = toDate.getDate().toString().padStart(2, '0');

    const fromFormatedDate = `${fromYear}-${fromMonth}-${fromDay}`;
    const toFormatedDate = `${toYear}-${toMonth}-${toDay}`;

    setLogsFilterData({
      ...logsFilterData,
      ['dateVisitor']: [fromFormatedDate, toFormatedDate],
    });
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'logType') {
      if (value === 'Visitor Log') {
        setIsVisitorForm(true);
      } else if (value === 'Daily Log') {
        setIsVisitorForm(false);
      }
    }

    if (name === 'propertyId') {
      properties.filter(property => {
        if (Number(property.id) === Number(value)) {
          setPropertyName(property.property_name);
          setPropertyAddress(property.address);
        }
      });
    }

    setLogsFilterData({ ...logsFilterData, [name]: value });
  };

  const handleHourFrom = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHourFrom(event.target.value);
  };
  const handleMinuteFrom = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMinuteFrom(event.target.value);
  };
  const handleAmPMFrom = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmPmFrom(event.target.value);
  };
  const handleHourTo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHourTo(event.target.value);
  };
  const handleMinuteTo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMinuteTo(event.target.value);
  };
  const handleAmPMTo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmPmTo(event.target.value);
  };

  const getTimeFromTimeInputs = () => {
    if (hourFrom && minuteFrom && amPmFrom && hourTo && minuteTo && amPmTo) {
      let hourFromFormat24: number | string = parseInt(hourFrom, 10);
      if (amPmFrom === 'pm' && hourFromFormat24 !== 12) {
        hourFromFormat24 += 12;
      } else if (amPmFrom === 'pm' && hourFromFormat24 === 12) {
        hourFromFormat24 = '12';
      } else if (amPmFrom === 'am' && hourFromFormat24 === 12) {
        hourFromFormat24 = '00';
      } else if (amPmFrom === 'am') {
        hourFromFormat24 = hourFrom;
      }

      let hourToFormat24: number | string = parseInt(hourTo, 10);
      if (amPmTo === 'pm' && hourToFormat24 !== 12) {
        hourToFormat24 += 12;
      } else if (amPmTo === 'pm' && hourToFormat24 === 12) {
        hourToFormat24 = '12';
      } else if (amPmTo === 'am' && hourToFormat24 === 12) {
        hourToFormat24 = '00';
      } else if (amPmFrom === 'am') {
        hourToFormat24 = hourTo;
      }

      setLogsFilterData({
        ...logsFilterData,
        timeVisitor: [
          `${hourFromFormat24}:${minuteFrom}`,
          `${hourToFormat24}:${minuteTo}`,
        ],
      });
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const checkMandatoryFilterFields = (data: any) => {
    const emptyFilterFields: string[] = [];

    if (data['dateVisitor'][0] === undefined) {
      emptyFilterFields.push('dateVisitor');
    }
    if (data['propertyId'].length === 0) {
      emptyFilterFields.push('propertyId');
    }
    // requiredFields.forEach((field) => {
    //   if (data[field].length === 0 || data[field][0] === undefined) {
    //     emptyFilterFields.push(field);
    //   }
    // });

    return emptyFilterFields;
  };

  const onSubmit: React.FormEventHandler = async e => {
    e.preventDefault();
    getTimeFromTimeInputs();

    const emptyMandatoryFields = checkMandatoryFilterFields({
      dateVisitor: logsFilterData.dateVisitor,
      propertyId: logsFilterData.propertyId,
    });
    setMandatoryFilterFields(emptyMandatoryFields);

    if (emptyMandatoryFields.length === 0) {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically detect client's timezone
        console.log('Client Time Zone:', timezone); // Optional: Log the detected timezone

        const response = await createItem('dashboard/reports', {
          logsFilterData,
          timezone,
        });
        setFilteredDataResponse(
          response as VisitorLogState[] | DailyLogState[] | []
        );
        setShowBuildingName(true);
        setIsFilterSidebarVisible(false);
        setShowChart(true);
        setIsVisitorForm(true);

        convertDatesInTheFilteredResponse(
          response as VisitorLogState[] | DailyLogState[]
        );
      } catch (error) {
        console.error(error);
      } finally {
        setMandatoryFilterFields([]);

        setHourFrom(undefined);
        setMinuteFrom(undefined);
        setAmPmFrom(undefined);
        setHourTo(undefined);
        setMinuteTo(undefined);
        setAmPmTo(undefined);
      }
    }
  };
  const convertDatesInTheFilteredResponse = (
    // eslint-disable-next-line
    data: any[]  // Accepting 'any' type for data to maintain generality
  ): void => {
    if (data.length === 0) {
      console.log('convertDatesInTheFilteredResponse: No data to process.');

      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically detect client's timezone
    console.log('Client Time Zone:', timezone); // Log the detected timezone

    console.log('convertDatesInTheFilteredResponse: Processing data...');
    // eslint-disable-next-line
    data.map((log: any, index: number) => {
      try {
        console.log(`Processing log #${index + 1}`, log);

        if (!log.utcCreatedAt) {
          console.error(
            `Log #${index + 1} missing 'utcCreatedAt' field. Skipping this log.`
          );

          return log; // Skip processing this log if utcCreatedAt is not available
        }

        // Parse the UTC 'createdAt' date string safely
        const utcDate = new Date(log.utcCreatedAt);
        if (isNaN(utcDate.getTime())) {
          throw new Error('Invalid UTC date format or value');
        }
        console.log(
          `Log #${index + 1}: UTC Date object - ${utcDate.toISOString()}`
        );

        // Convert UTC to local time
        const localDate = new Date(
          utcDate.toLocaleString('en-US', { timeZone: timezone })
        );
        if (isNaN(localDate.getTime())) {
          throw new Error('Failed to convert UTC to local date');
        }
        console.log(
          `Log #${index + 1}: Local Date object - ${localDate.toString()}`
        );

        // Extract time part and format it
        const ampmTime = localDate.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        console.log(`Log #${index + 1}: Formatted AM/PM time - ${ampmTime}`);

        // Update log with local date and formatted time
        log.creationHour = ampmTime;
        log.createdAt = localDate.toISOString().split('T')[0];
        console.log(`Log #${index + 1}: Updated log -`, log);
      } catch (error) {
        console.error(`Error processing log #${index + 1}:`, error);
      }

      return log;
    });

    setFilteredDataResponse(data);
    console.log('convertDatesInTheFilteredResponse: Data processing complete.');
  };

  const clearData = () => {
    setLogsFilterData(initialState);
    setMandatoryFilterFields([]);

    setHourFrom(undefined);
    setMinuteFrom(undefined);
    setAmPmFrom(undefined);
    setHourTo(undefined);
    setMinuteTo(undefined);
    setAmPmTo(undefined);
  };

  useEffect(() => {
    logsFilterData.visitorDetails = '';
    visitorDetailsAccordingVisitorType(logsFilterData.visitorType);
  }, [logsFilterData.visitorType]);

  const filterButtonClick = () => {
    setIsFilterSidebarVisible(true);
    // const reports = fetchAllItems('dashboard/reports');
    // console.log(reports);
    setLogsFilterData(initialState);
    setPropertyName('');
    setPropertyAddress('');
    setFilteredDataResponse([]);
  };

  // eslint-disable-next-line
  const lineChartData: any = {};
  // eslint-disable-next-line
  filteredDataResponse.forEach((entry: any) => {
    if (!lineChartData[entry.createdAt]) {
      lineChartData[entry.createdAt] = {
        createdAt: entry.createdAt,
        approveGuests: 0,
        nonApproveGuests: 0,
        numberOfGuests: 0, // Initializing the total counter here
      };
    }
    if (entry.approval.length > 1 && entry.approval === 'Yes') {
      lineChartData[entry.createdAt].approveGuests += entry.numberOfGuests;
    } else {
      if (entry.approval.length > 1) {
        lineChartData[entry.createdAt].nonApproveGuests += entry.numberOfGuests;
      }
    }
    // Update the total counter for each entry
    lineChartData[entry.createdAt].numberOfGuests =
      lineChartData[entry.createdAt].approveGuests +
      lineChartData[entry.createdAt].nonApproveGuests;
  });
  const finalLineChartData = Object.values(lineChartData);

  const generateVisitorLogPieData = () => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    filteredDataResponse.forEach((log: any) => {
      pieChartVisitorLog2.find(item => {
        if (item.name === log.visitorDetails) {
          item.value += log.numberOfGuests;
        }

        if (log.visitorType === 'Guest') {
          if (item.name === 'Guest') {
            item.value += log.numberOfGuests;
          }
        }

        if (log.visitorType === 'Prospect' && log.visitorDetails === 'Other') {
          if (item.name === 'Other-Prospect') {
            item.value += log.numberOfGuests;
          }
        }

        if (log.visitorType === 'Vendor' && log.visitorDetails === 'Other') {
          if (item.name === 'Other-Vendor') {
            item.value += log.numberOfGuests;
          }
        }

        if (log.visitorType === 'Delivery' && log.visitorDetails === 'Other') {
          if (item.name === 'Other-Delivery') {
            item.value += log.numberOfGuests;
          }
        }

        if (
          log.visitorType === 'Emergency (911)' &&
          log.visitorDetails === 'Other'
        ) {
          if (item.name === 'Other-Emergency') {
            item.value += log.numberOfGuests;
          }
        }
      });
    });

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    filteredDataResponse.forEach((log: any) => {
      if (log.visitorType === 'Guest') {
        pieChartVisitorLog1.find(item => {
          if (item.name === 'Guest') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.visitorType === 'Prospect') {
        pieChartVisitorLog1.find(item => {
          if (item.name === 'Prospect') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.visitorType === 'Vendor') {
        pieChartVisitorLog1.find(item => {
          if (item.name === 'Vendor') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.visitorType === 'Delivery') {
        pieChartVisitorLog1.find(item => {
          if (item.name === 'Delivery') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.visitorType === 'Emergency (911)') {
        pieChartVisitorLog1.find(item => {
          if (item.name === 'Emergency (911)') {
            item.value += log.numberOfGuests;
          }
        });
      }
    });

    setPieChartVisitorLog1State(pieChartVisitorLog1);
    setPieChartVisitorLog2State(pieChartVisitorLog2);
  };
  // }

  const generateDailyLogPieData = () => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    filteredDataResponse.forEach((log: any) => {
      if (log.buildingActivity === 'Maintenance') {
        pieChartDailyLog.find(item => {
          if (item.name === 'Maintenance') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.buildingActivity === 'Leasing Office') {
        pieChartDailyLog.find(item => {
          if (item.name === 'Leasing Office') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.buildingActivity === 'Resident') {
        pieChartDailyLog.find(item => {
          if (item.name === 'Resident') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.buildingActivity === 'Miscellaneous') {
        pieChartDailyLog.find(item => {
          if (item.name === 'Miscellaneous') {
            item.value += log.numberOfGuests;
          }
        });
      }
      if (log.buildingActivity === 'Shift Change') {
        pieChartDailyLog.find(item => {
          if (item.name === 'Shift Change') {
            item.value += log.numberOfGuests;
          }
        });
      }
    });

    setPieChartDailyLogSatate(pieChartDailyLog);
  };

  const handleCloseFilters = () => {
    setIsFilterSidebarVisible(false);
    setIsVisitorForm(true);
    setMandatoryFilterFields([]);

    setHourFrom(undefined);
    setMinuteFrom(undefined);
    setAmPmFrom(undefined);
    setHourTo(undefined);
    setMinuteTo(undefined);
    setAmPmTo(undefined);
  };

  useEffect(() => {
    if (logsFilterData.logType === 'Visitor Log') {
      generateVisitorLogPieData();
    }
    if (logsFilterData.logType === 'Daily Log') {
      generateDailyLogPieData();
    }
  }, [isFilterSidebarVisible]);

  const handleExportToPDF = () => {
    const chartToPdf = document.getElementById('dashboard-reports-content');
    const pieChartToPdf = document.getElementById('pie-chart');
    const pieChartLegendToPdf = document.getElementById('pie-chart-legend');
    const pieChartLegend2ToPdf = document.getElementById('pie-chart-legend2');

    if (
      chartToPdf &&
      pieChartToPdf &&
      pieChartLegendToPdf &&
      pieChartLegend2ToPdf
    ) {
      const pdf = new jsPDF();

      // Adjust the typing for 'element'
      const convertElementToCanvas = (
        element: HTMLElement,
        options: Parameters<typeof html2canvas>[1] = {}
      ) => {
        return html2canvas(element, {
          ...options,
          useCORS: true,
          logging: true,
          scale: 1,
        });
      };

      // Function to add a canvas image to the PDF and handle page breaks
      const addImageToPDF = (
        canvas: HTMLCanvasElement,
        width: number,
        height: number,
        adjustedYPosition: number,
        adjustedXPosition: number
      ) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(
          imgData,
          'PNG',
          adjustedXPosition,
          adjustedYPosition,
          width,
          height
        );
      };

      // Sequentially convert each element to canvas and add to the PDF
      convertElementToCanvas(chartToPdf as HTMLElement)
        .then(canvas => {
          addImageToPDF(canvas, 210, 140, 0, 0);

          if (logsFilterData.logType === 'Visitor Log') {
            return convertElementToCanvas(pieChartLegendToPdf as HTMLElement, {
              backgroundColor: null,
            });
          } else {
            return convertElementToCanvas(pieChartLegend2ToPdf as HTMLElement, {
              backgroundColor: null,
            });
          }
        })
        .then(canvas => {
          if (logsFilterData.logType === 'Visitor Log') {
            addImageToPDF(canvas, 210, 4, 230, 0);

            return convertElementToCanvas(pieChartLegend2ToPdf as HTMLElement, {
              backgroundColor: null,
            });
          } else {
            return canvas;
          }
        })
        .then(canvas => {
          const adjustedYPosition =
            logsFilterData.logType === 'Visitor Log' ? 160 : 180;

          addImageToPDF(
            canvas,
            logsFilterData.logType === 'Visitor Log' ? 40 : 30,
            logsFilterData.logType === 'Visitor Log' ? 60 : 20,
            adjustedYPosition,
            160
          );

          return convertElementToCanvas(pieChartToPdf as HTMLElement, {
            backgroundColor: null,
          });
        })
        .then(canvas => {
          addImageToPDF(canvas, 210, 100, 140, 0);
          pdf.save('chart.pdf');
        })
        .catch(error => {
          console.error('Error generating PDF: ', error);
        });
    }
  };

  const handleExportToXLS = () => {
    const wb = XLSX.utils.book_new();

    // Sheet 1 - all filtered data
    const ws1Data = modifyAllFilteredDataForExcel(filteredDataResponse);
    const ws1 = XLSX.utils.json_to_sheet(ws1Data);
    ws1['!cols'] = [
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 25 },
      { wch: 15 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

    const ws2 = XLSX.utils.json_to_sheet(finalLineChartData);
    ws2['!cols'] = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
    XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');

    // Sheet 3 - Depending on the logType
    if (logsFilterData.logType === 'Visitor Log') {
      const ws3Data = modifyPieDataForExcel(pieChartVisitorLog2State);
      const ws3 = XLSX.utils.json_to_sheet(ws3Data);
      ws3['!cols'] = [{ wch: 25 }, { wch: 15 }];
      XLSX.utils.book_append_sheet(wb, ws3, 'Sheet3');

      const ws4Data = modifyPieDataForExcel(pieChartVisitorLog1State);
      const ws4 = XLSX.utils.json_to_sheet(ws4Data);
      ws3['!cols'] = [{ wch: 25 }, { wch: 15 }];
      XLSX.utils.book_append_sheet(wb, ws4, 'Sheet4');
    } else if (logsFilterData.logType === 'Daily Log') {
      const ws3Data = modifyPieDataForExcel(pieChartDailyLogState);

      const ws3 = XLSX.utils.json_to_sheet(ws3Data);
      ws3['!cols'] = [{ wch: 25 }, { wch: 15 }];
      XLSX.utils.book_append_sheet(wb, ws3, 'Sheet3');
    }

    XLSX.writeFile(wb, 'chart_data.xlsx');
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const modifyPieDataForExcel = (data: any) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return data.map((item: any) => ({
      VisitorType: item.name,
      NumberOfPeople: item.value,
    }));
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const modifyAllFilteredDataForExcel = (data: any) => {
    if (logsFilterData.logType === 'Visitor Log') {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      return data.map((item: any) => ({
        VisitorType: item.visitorType,
        VisitorDetails: item.visitorDetails,
        Names: item.names[0],
        LastNames: item.lastNames[0],
        PreAuthorized: item.preAuthorized,
        ApartmentLocation: item.apartmentLocation,
        NumberOfGuests: item.numberOfGuests,
        Property_ids: item.property_ids[0],
        Date: item.createdAt,
        Time: item.creationHour,
      }));
    } else {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      return data.map((item: any) => ({
        BuildingActivity: item.buildingActivity,
        Name: item.name,
        LastName: item.lastName,
        ApartmentLocation: item.apartmentLocation,
        NumberOfGuests: item.numberOfGuests,
        Priority: item.priority,
        Property_ids: item.property_ids[0],
        Date: item.createdAt,
        Time: item.creationHour,
      }));
    }
  };

  useEffect(() => {
    setLogsFilterData({ ...initialState, logType: logsFilterData.logType });
  }, [logsFilterData.logType]);

  useEffect(() => {
    getTimeFromTimeInputs();
  }, [amPmTo]);

  useRedirectOnBack('/dashboard');

  return (
    <DashboardContent id="dashboard-reports-content">
      <Analytics>
        <Title>
          <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
            Analytics
          </Text>
          <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
            Choose filters please
          </Text>
        </Title>
        <ButtonsFilterExport>
          <FilterButtonContainer onClick={() => filterButtonClick()}>
            <Text id="filter-button">Filter</Text>
            <FilterIcon />
          </FilterButtonContainer>
          <ExportButton id="export-button" onClick={handleExportToPDF}>
            Export PDF
          </ExportButton>
          <ExportButton id="export-button-excel" onClick={handleExportToXLS}>
            Export Excel
          </ExportButton>
        </ButtonsFilterExport>
      </Analytics>

      {showBuildingName && !isFilterSidebarVisible && propertyName !== '' && (
        <>
          <Analytics>
            <Title>
              <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
                {propertyName !== '' ? propertyName : ''}
              </Text>
              <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
                {propertyName !== '' ? propertyAddress : ''}
              </Text>
            </Title>
          </Analytics>
        </>
      )}

      {showChart &&
        (filteredDataResponse.length === 0 ? (
          <Text mt={20} mb={0} ml={12} fontSize={16} fontWeight={700}>
            There is no data for the filters you chose, please try again.
          </Text>
        ) : (
          <>
            <Text mt={20} mb={10} ml={10} fontSize={16} fontWeight={600}>
              Approved and not approved visitors.
            </Text>
            <ResponsiveContainer width="90%" height="60%">
              <LineChart
                width={800}
                height={500}
                data={finalLineChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="createdAt"
                  type="category"
                  allowDuplicatedCategory={false}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="numberOfGuests"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  name="All Guests"
                />
                <Line
                  type="monotone"
                  dataKey="approveGuests"
                  stroke="#82CA9D"
                  name="Approved Guests"
                />
                <Line
                  type="monotone"
                  dataKey="nonApproveGuests"
                  stroke="#FF7300"
                  name="Non-approved Guests"
                />
              </LineChart>
            </ResponsiveContainer>

            <Text mt={60} ml={10} fontSize={16} fontWeight={600}>
              Types of visitors
            </Text>

            <ResponsiveContainer width="84%" height="60%" id="pie-chart">
              <PieChart width={400} height={400}>
                <Tooltip />
                {/* <Legend /> */}
                <Pie
                  data={
                    logsFilterData.logType === 'Visitor Log'
                      ? pieChartVisitorLog1State
                      : pieChartDailyLogState
                  }
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                >
                  {logsFilterData.logType === 'Visitor Log'
                    ? pieChartVisitorLog1State.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={pieChartVisitorLog1State[index].color}
                        />
                      ))
                    : pieChartDailyLogState.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={pieChartDailyLogState[index].color}
                        />
                      ))}
                </Pie>

                {logsFilterData.logType === 'Visitor Log' && (
                  <Pie
                    data={pieChartVisitorLog2State}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={100}
                    outerRadius={130}
                    label
                  >
                    {pieChartVisitorLog2State.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieChartVisitorLog2State[index].color}
                      />
                    ))}
                  </Pie>
                )}
              </PieChart>
            </ResponsiveContainer>
          </>
        ))}

      {showChart &&
        filteredDataResponse.length > 0 &&
        logsFilterData.logType === 'Visitor Log' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            id="pie-chart-legend"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  marginRight: '20px',
                  marginTop: '0',
                }}
              >
                Total:
              </p>
              {pieChartVisitorLog1State.map((item, index) => (
                <p
                  key={index}
                  style={{
                    color: `${item.color}`,
                    marginRight: '20px',
                    marginTop: '0',
                  }}
                >
                  {item.name} - {item.value}
                </p>
              ))}
            </div>
            <div
              style={{
                marginLeft: '70px',
                marginTop: '30px',
                position: 'absolute',
                right: '0',
                top: '100%',
              }}
              id="pie-chart-legend2"
            >
              {pieChartVisitorLog2State.map((item, index) => (
                <p
                  key={index}
                  style={{
                    color: `${item.color}`,
                    marginRight: '20px',
                    marginTop: '0',
                  }}
                >
                  {item.name} - {item.value}
                </p>
              ))}
            </div>
          </div>
        )}

      {showChart &&
        filteredDataResponse.length > 0 &&
        logsFilterData.logType === 'Daily Log' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            id="pie-chart-legend"
          >
            <div
              style={{
                marginLeft: '70px',
                marginTop: '150px',
                position: 'absolute',
                right: '100px',
                top: '100%',
              }}
              id="pie-chart-legend2"
            >
              {pieChartDailyLogState.map((item, index) => (
                <p
                  key={index}
                  style={{
                    color: `${item.color}`,
                    marginRight: '20px',
                    marginTop: '0',
                  }}
                >
                  {item.name} - {item.value}
                </p>
              ))}
            </div>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  marginRight: '20px',
                  marginTop: '0',
                }}
              >
                Total:
              </p>
              {pieChartDailyLogState.map((item, index) => (
                <p
                  key={index}
                  style={{
                    color: `${item.color}`,
                    marginRight: '20px',
                    marginTop: '0',
                  }}
                >
                  {item.name} - {item.value}
                </p>
              ))}
            </div> */}
          </div>
        )}

      {isFilterSidebarVisible && (
        <FilterSidebarBackground
          id="filter-sidebar-background"
          onClick={() => handleCloseFilters()}
        >
          <FilterSidebar id="filter-sidebar" onClick={e => e.stopPropagation()}>
            <FilterTitle id="filters-title">
              <Text fontSize={28} fontWeight={700} mt={0} mb={0}>
                Filter reports
              </Text>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleCloseFilters()}
              >
                <CloseIcon />
              </div>
            </FilterTitle>

            <Filters id="filter-inputs-section">
              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  marginBottom: '30px',
                }}
              >
                <CheckRingContainer isFilterChecker>
                  <CheckRing />
                </CheckRingContainer>
                <StyledSelectMenu
                  name="logType"
                  value={logsFilterData.logType}
                  onChange={handleInputChange}
                  isChooseTypeOfLog={true}
                >
                  <option value={'Visitor Log'}>Visitor Log</option>
                  <option value={'Daily Log'}>Daily Log</option>
                </StyledSelectMenu>
              </div>
              <Text mb={10} mt={16}>
                Property
              </Text>
              <StyledSelectMenu
                name="propertyId"
                value={logsFilterData.propertyId}
                onChange={handleInputChange}
              >
                <option value="">Choose a property</option>

                {properties.map(property => (
                  <option
                    className="select-option"
                    key={property.id}
                    value={`${property.id}`}
                  >
                    {property.property_name}, {property.address}
                  </option>
                ))}
              </StyledSelectMenu>
              {mandatoryFilterFields.includes('propertyId') && (
                <ErrorLabel>Please choose a property*</ErrorLabel>
              )}
              <Text mb={10} mt={16}>
                Date Visitor
              </Text>
              <DateRangePicker
                onChange={dates => formatDate(dates)}
                placeholder={'Search date'}
              />
              {mandatoryFilterFields.includes('dateVisitor') && (
                <ErrorLabel>Please enter a date range*</ErrorLabel>
              )}
              <Text mb={10} mt={24}>
                Time Visitor
              </Text>
              <TimePickersContainer>
                <div>
                  <Text fontSize={12}>from</Text>
                  <div
                    key={hourFrom}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <TimePickerSelectMenu
                      name="hourFrom"
                      value={hourFrom}
                      onChange={handleHourFrom}
                    >
                      <option value=""></option>

                      {hours.map(hour => (
                        <option
                          className="select-option"
                          key={hour}
                          value={`${hour}`}
                        >
                          {hour}
                        </option>
                      ))}
                    </TimePickerSelectMenu>
                    <TimePickerSelectMenu
                      name="minuteFrom"
                      value={minuteFrom}
                      onChange={handleMinuteFrom}
                    >
                      <option value=""></option>

                      {minutes.map(minute => (
                        <option
                          className="select-option"
                          key={minute}
                          value={`${minute}`}
                        >
                          {minute}
                        </option>
                      ))}
                    </TimePickerSelectMenu>
                    <TimePickerSelectMenu
                      name="amPmFrom"
                      value={amPmFrom}
                      onChange={handleAmPMFrom}
                    >
                      <option value=""></option>
                      <option value="am">am</option>
                      <option value="pm">pm</option>
                    </TimePickerSelectMenu>
                  </div>
                </div>

                <div>
                  <Text fontSize={12}>to</Text>
                  <div
                    key={hourTo}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <TimePickerSelectMenu
                      name="hourTo"
                      value={hourTo}
                      onChange={handleHourTo}
                    >
                      <option value=""></option>

                      {hours.map(hour => (
                        <option
                          className="select-option"
                          key={hour}
                          value={`${hour}`}
                        >
                          {hour}
                        </option>
                      ))}
                    </TimePickerSelectMenu>
                    <TimePickerSelectMenu
                      name="minuteTo"
                      value={minuteTo}
                      onChange={handleMinuteTo}
                    >
                      <option value=""></option>

                      {minutes.map(minute => (
                        <option
                          className="select-option"
                          key={minute}
                          value={`${minute}`}
                        >
                          {minute}
                        </option>
                      ))}
                    </TimePickerSelectMenu>
                    <TimePickerSelectMenu
                      name="amPmTo"
                      value={amPmTo}
                      onChange={handleAmPMTo}
                    >
                      <option value=""></option>
                      <option value="am">am</option>
                      <option value="pm">pm</option>
                    </TimePickerSelectMenu>
                  </div>
                </div>
              </TimePickersContainer>

              {!isVisitorForm && (
                <>
                  <Text mb={10} mt={16}>
                    Building Activities
                  </Text>
                  <StyledSelectMenu
                    name="buildingActivity"
                    value={logsFilterData.buildingActivity}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose building activities</option>

                    {buildingActivities.map(activity => (
                      <option
                        className="select-option"
                        key={activity}
                        value={`${activity}`}
                      >
                        {activity}
                      </option>
                    ))}
                  </StyledSelectMenu>
                </>
              )}
              {isVisitorForm && (
                <>
                  <Text mb={10} mt={16}>
                    Visitor type
                  </Text>
                  <StyledSelectMenu
                    name="visitorType"
                    value={logsFilterData.visitorType}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose visitor type</option>

                    {visitorTypes.map(property => (
                      <option
                        className="select-option"
                        key={property}
                        value={`${property}`}
                      >
                        {property}
                      </option>
                    ))}
                  </StyledSelectMenu>
                </>
              )}
              {isVisitorForm && logsFilterData.visitorType.length !== 0 && (
                <>
                  <Text mb={10} mt={16}>
                    Visitor details
                  </Text>
                  <StyledSelectMenu
                    name={
                      logsFilterData.visitorType === 'Guest'
                        ? 'preAuthorized'
                        : 'visitorDetails'
                    }
                    value={
                      logsFilterData.visitorType === 'Guest'
                        ? logsFilterData.preAuthorized
                        : logsFilterData.visitorDetails
                    }
                    onChange={handleInputChange}
                  >
                    {logsFilterData.visitorType === 'Guest' ? (
                      <option value="">Pre Authorized</option>
                    ) : (
                      <option value="">Choose visitor details</option>
                    )}

                    {visitorDetails.map(property => (
                      <option
                        className="select-option"
                        key={property}
                        value={`${property}`}
                      >
                        {property}
                      </option>
                    ))}
                  </StyledSelectMenu>
                </>
              )}
              {isVisitorForm && (
                <>
                  <Text mb={10} mt={16}>
                    No. of Guests
                  </Text>
                  <StyledSelectMenu
                    name="numberOfGuests"
                    value={logsFilterData.numberOfGuests}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose number of guests</option>

                    {numberOfGuests.map(number => (
                      <option
                        className="select-option"
                        key={number}
                        value={`${number}`}
                      >
                        {number}
                      </option>
                    ))}
                  </StyledSelectMenu>
                </>
              )}

              {logsFilterData.visitorType !== 'Emergency (911)' &&
                logsFilterData.buildingActivity !== 'Maintenance' &&
                logsFilterData.buildingActivity !== 'Leasing Office' &&
                logsFilterData.buildingActivity !== 'Miscellaneous' && (
                  <>
                    <Text mb={10} mt={16}>
                      Name
                    </Text>
                    <StyledFilterInput
                      type="text"
                      name="name"
                      value={logsFilterData.name}
                      placeholder={'Guest name'}
                      onChange={handleInputChange}
                    />

                    <Text mb={10} mt={16}>
                      Last Name
                    </Text>
                    <StyledFilterInput
                      type="text"
                      name="lastName"
                      value={logsFilterData.lastName}
                      placeholder={'Guest last name'}
                      onChange={handleInputChange}
                    />
                  </>
                )}

              {logsFilterData.buildingActivity !== 'Leasing Office' &&
                logsFilterData.buildingActivity !== 'Shift Change' && (
                  <>
                    <Text mb={10} mt={16}>
                      Apartment/Location
                    </Text>
                    <StyledFilterInput
                      type="text"
                      name="apartmentLocation"
                      value={logsFilterData.apartmentLocation}
                      placeholder={'Enter apartment/location'}
                      onChange={handleInputChange}
                    />
                  </>
                )}

              {isVisitorForm &&
                logsFilterData.visitorType !== 'Emergency (911)' && (
                  <>
                    <Text mb={10} mt={16}>
                      Parking Validation
                    </Text>
                    <StyledSelectMenu
                      name="parkingValidation"
                      value={logsFilterData.parkingValidation}
                      onChange={handleInputChange}
                    >
                      <option value="">Yes/No</option>

                      <option
                        className="select-option"
                        key={'yes'}
                        value={'Yes'}
                      >
                        Yes
                      </option>
                      <option className="select-option" key={'no'} value={'No'}>
                        No
                      </option>
                    </StyledSelectMenu>

                    <Text mb={10} mt={16}>
                      Approval
                    </Text>
                    <StyledSelectMenu
                      name="approval"
                      value={logsFilterData.approval}
                      onChange={handleInputChange}
                    >
                      <option value="">Yes/No</option>

                      <option
                        className="select-option"
                        key={'yes'}
                        value={'Yes'}
                      >
                        Yes
                      </option>
                      <option className="select-option" key={'no'} value={'No'}>
                        No
                      </option>
                    </StyledSelectMenu>
                  </>
                )}

              {!isVisitorForm &&
                logsFilterData.buildingActivity !== 'Leasing Office' &&
                logsFilterData.buildingActivity !== 'Miscellaneous' &&
                logsFilterData.buildingActivity !== 'Shift Change' && (
                  <>
                    <Text mb={10} mt={16}>
                      Priority
                    </Text>
                    <StyledSelectMenu
                      name="priority"
                      value={logsFilterData.priority}
                      onChange={handleInputChange}
                    >
                      <option value="">Choose priority</option>

                      {priorities.map(priority => (
                        <option
                          className="select-option"
                          key={priority}
                          value={`${priority}`}
                        >
                          {priority}
                        </option>
                      ))}
                    </StyledSelectMenu>
                  </>
                )}
            </Filters>

            <ButtonsContainer>
              <CancelButton onClick={() => clearData()}>
                <ButtonTitle>Cancel</ButtonTitle>
              </CancelButton>
              <ConfirmButton onClick={onSubmit}>
                <ButtonTitle>Confirm</ButtonTitle>
              </ConfirmButton>
            </ButtonsContainer>
          </FilterSidebar>
        </FilterSidebarBackground>
      )}
    </DashboardContent>
  );
};
