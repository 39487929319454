import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { Text, font } from '../../style-utils/text';

interface TextEditorProps {
  changeHandler: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | string,
    fieldName?: string,
    explicitValue?: string
  ) => void;
  defaultValue: string;
  changeHandlerFor: string;
}

export const StyledQuill = styled(ReactQuill)<{ isSettings?: boolean }>`
  height: 300px;
  width: 70%;
  max-width: 600px;
  margin-bottom: 75px;
`;

export const TextEditor: React.FC<TextEditorProps> = ({
  changeHandler,
  changeHandlerFor,
  defaultValue,
}) => {
  const quillRef = useRef<ReactQuill>(null);

  const [error, setError] = useState<string | null>(null);
  // eslint-disable-next-line
  const constrictText = (content: string) => {
    if (content?.length > 255) {
      setError('Max characters: 255');
      // Truncate the content inside Quill editor
      const quill = quillRef.current?.getEditor();
      quill?.deleteText(255, content.length);

      return false;
    } else {
      setError(null); // Clear the error when content is valid

      return true;
    }
  };

  return (
    <>
      <StyledQuill
        ref={quillRef}
        defaultValue={defaultValue}
        // the paramater here acts as both the event that is fired and the explicit value that needs to be set
        // this is weird but this is the way react-quill works
        // TRY NOT TO CHANGE
        onChange={e => {
          if (constrictText(e)) {
            changeHandler(e, changeHandlerFor, e);
          }
        }}
      />
      {error?.length && (
        <div
          style={{
            display: 'flex',
            position: 'relative',
            background: 'lightgray',
          }}
        >
          <Text
            pl={12}
            pr={12}
            width="100%"
            backgroundColour={'lightgray'}
            fontFamily={font.family}
            color={'red'}
            fontSize={font.size.xs}
          >
            {error}
          </Text>
        </div>
      )}
    </>
  );
};
