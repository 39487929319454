import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex: 1;
`;
export const ScanInputWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  justify-content: flex-end;
`;

export const ScanIconWrapper = styled.div`
  display: flex;
  padding: 6px 20px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--btn-def, #8c67c9);
`;

export const ScanContainer = styled.div`
  cursor: pointer;
  width: 70%;
  height: 80px;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(199, 206, 209, 0.1);
`;
