import React from 'react';
import {
  ScanContainer,
  ScanIconWrapper,
  ScanInputWrapper,
} from './scan-input.styled';
import { ScanIcon } from '../icons/scan';
import { Text, font } from '../../style-utils/text';

interface ScanInputProps {
  onClick: () => void;
}
export const ScanInput: React.FC<ScanInputProps> = ({ onClick }) => {
  return (
    <ScanContainer onClick={onClick}>
      <Text
        color={font.color.gray}
        fontFamily={font.family}
        fontSize={font.size.xs}
        pl={16}
      >
        Please scan guest ID or driver license
      </Text>
      <ScanInputWrapper>
        <ScanIconWrapper>
          <Text
            color={'white'}
            fontSize={font.size.xs}
            fontWeight={600}
            fontFamily={font.family}
          >
            Scan
          </Text>
          <ScanIcon />
        </ScanIconWrapper>
      </ScanInputWrapper>
    </ScanContainer>
  );
};
