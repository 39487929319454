export const formatString = (input: string): string => {
  if (/[a-z][A-Z]/.test(input)) {
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^\w/, c => c.toUpperCase());
  } else {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
};

export const stripHtmlTags = (input?: string) => {
  if (typeof input === 'string' && input.length > 0) {
    return input.replace(/<[^>]*>/g, '');
  }

  return input; // Return the input as-is if it's not a string
};
