import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const LogoContainer = styled.div`
  height: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;
export const LogoSpan = styled.span`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${colors.purple_dark};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.white};
`;

export const MenuAndLogoutWrappper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 32px;
`;

export const MenuContainer = styled.div``;

export const DashboardLogout = styled.div``;

export const MenuTitle = styled.div`
  margin-bottom: 24px;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
`;

export const LogOutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const DashboardHeadingContainer = styled.div`
  height: 80px;
  min-height: 80px;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
`;

export const SearchAndProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid purple;s */
`;

export const StyledSearchInput = styled.input<{ isReportsFilter?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  width: ${({ isReportsFilter }) => (isReportsFilter ? '100%' : '400px')};
  max-height: 40px;
  max-width: ${({ isReportsFilter }) =>
    isReportsFilter ? 'inherit' : '400px'};
  padding-left: 20px;
  border-radius: 50px;
  background: ${colors.white};
  border: 0.2px solid ${colors.grey_light};
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const StyledSearchButton = styled.button`
  background: ${colors.purple_dark};
  width: 78px;
  height: 28px;
  border: none;
  padding: 4px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  /* box-shadow: 2px 2px 8px 0px #0000001a; */
  position: absolute;
  bottom: 6px;
  right: 6px;
  transition: all 0.3s ease-out;

  &:hover {
    transform: scale(1.02);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const LogedInUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
`;

export const ProfileButtonBorder = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${colors.grey_light};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const DashboardContentContainer = styled.div`
  height: 100%;
  background: ${colors.dashboard_light_grey};
  padding: 16px;
`;
