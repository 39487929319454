import React from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
} from '../../../layout/layout-container.styled';
import { Navbar } from '../../../layout/visitor-log/navbar';
import { NavButton } from '../../../nav-button/nav-button';
import { Text, font } from '../../../../style-utils/text';
import { StyledButton } from '../../../styled-button/styled-button';
import { CheckRing } from '../../../icons/check-ring';
import { VisitorLogState } from '../../../hooks/use-visitor-log-handler';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { Step } from '../../../step/step';
import { findPathIndex } from './controllers/find-path-index';
import { Progress } from '../../../progress/progress';
import useRedirectOnRefresh from '../../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../../style-utils/use-window-height';

export const ParkingTicketValidation: React.FC<DefaultComponentProps> = ({
  navigate,
  state,
  changeHandler,
  nextPath,
  prevPath,
  path,
  navigation,
  selectedOption,
  selectedBuilding,
}) => {
  const { parkingValidation } = state as VisitorLogState;
  const isLog = path?.includes('/daily-log') || false;
  const maxPaths = navigation?.length || 0;
  const currentPathIndex = findPathIndex(path, navigation);
  useRedirectOnRefresh();
  useRedirectOnBack();
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        selectedOption={selectedOption}
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer>
          <Step currentPathIndex={currentPathIndex} maxPaths={maxPaths || 0} />
          <Progress
            currentPathIndex={currentPathIndex}
            maxPaths={maxPaths || 0}
          />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer>
            <Navbar
              path={path}
              navigation={navigation}
              state={state}
              isLog={isLog}
              navigate={navigate}
              currentPage={path}
              hasContent
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column">
              <Text fontSize={font.size.xl} fontWeight={700} mt={0} mb={10}>
                Parking Ticket Validation
              </Text>
              <Text
                color={font.color.gray}
                fontSize={font.size.xs}
                mt={0}
                mb={0}
              >
                Please select one of the options below
              </Text>
            </TitleAndSubtitle>
            <ContentButtonsWrapper>
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'parkingValidation', 'Yes')
                }
                text={'Yes'}
                icon={<CheckRing />}
                isSelected={parkingValidation === 'Yes'}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'parkingValidation', 'No')
                }
                text={'No'}
                icon={<CheckRing />}
                isSelected={parkingValidation === 'No'}
              />
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => navigate(prevPath, { replace: false })}
              />
              <NavButton
                disabled={parkingValidation.length > 1 ? false : true}
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={'Continue'}
                onClick={() => navigate(nextPath, { replace: false })}
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
