import React from 'react';
import { Column, Row } from '../../../layout/design-helpers';
import { EllipsisText, Text, font } from '../../../../style-utils/text';
import styled from 'styled-components';
import { StyledRow } from './dashboard-home.styled';
import { DailyLogState } from '../../../hooks/use-daily-log-handler';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import { extractDateAndTime } from '../../../../function-utils/date-time-extractor';
import { stripHtmlTags } from '../../../../function-utils/string-converter';

export const Container = styled.div`
  padding: 16px;
`;

export const StyledRowContainer = styled(Row)`
  align-items: center;
`;

interface DailyLogAnalyticsProps {
  data: DailyLogState;
  selectedBuilding: BuildingData | null;
}

export const DailyLogAnalytics: React.FC<DailyLogAnalyticsProps> = ({
  data,
  selectedBuilding,
}) => {
  const getDateTime = () => {
    if (!data || typeof data.createdAt !== 'string') {
      return { formattedDate: 'N/A', formattedTime: 'N/A' };
    }

    try {
      return extractDateAndTime(data.createdAt);
    } catch (error) {
      console.error('Error extracting date and time:', error);

      return { formattedDate: 'Error', formattedTime: 'Error' };
    }
  };

  const { formattedDate, formattedTime } = getDateTime();

  return (
    data && (
      <StyledRow maxHeight={60}>
        <Column flex={1}>
          <Row>
            <Text
              fontFamily={font.family}
              fontSize={font.size.xxs}
              mt={8}
              mb={8}
              pl={16}
            >
              {formattedDate} | {formattedTime}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize={font.size.xs}
              fontFamily={font.family}
              fontWeight={600}
              mt={0}
              mb={8}
              pl={16}
            >
              {selectedBuilding?.property_name}
            </Text>
          </Row>
        </Column>
        <Column flex={6}>
          <StyledRowContainer>
            <EllipsisText
              fontWeight={500}
              fontFamily={font.family}
              fontSize={font.size.xs}
              mb={0}
              mt={8}
              pr={16}
              pl={16}
            >
              {data?.buildingActivity &&
                `Activity: ${data.buildingActivity} | `}
              {data?.name && `Name: ${data.name} `}
              {data?.lastName && `${data.lastName} | `}
              {data?.apartmentLocation &&
                `Location: ${data.apartmentLocation} | `}
              {data?.priority && `Priority: ${data.priority} | `}
              {(data?.additionalNotes ||
                data?.details ||
                data?.description ||
                data?.notes) && (
                <>
                  Notes:
                  {data?.notes && `${' ' + stripHtmlTags(data?.notes) + ' '} `}
                  {data?.additionalNotes &&
                    ` ${stripHtmlTags(data.additionalNotes) + ' '} `}
                  {data?.details && ` ${stripHtmlTags(data.details) + ' '} `}
                  {data?.description &&
                    ` ${stripHtmlTags(data.description) + ' '} `}
                </>
              )}
            </EllipsisText>
          </StyledRowContainer>
        </Column>
      </StyledRow>
    )
  );
};
