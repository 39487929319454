import React from 'react';
import {
  ButtonContainer,
  // Container,
  ParentContainer,
  TextContainer,
  // Wrapper,
} from './table-pagination.styled';
import { PaginationLeftArrow } from '../../../../icons/pagination-left-arrow';
import { Text } from '../../../../../style-utils/text';
import { PaginationRightArrow } from '../../../../icons/pagination-right-arrow';
import { colors } from '../../../../../style-utils/colors';

interface PaginationButtons {
  currentPage: number;
  hasPreviousPage: boolean | null;
  hasNextPage: boolean | null;
  previousPage: () => void;
  nextPage: () => void;
  totalPages: number | null;
}

export const PaginationButtons: React.FC<PaginationButtons> = ({
  hasNextPage,
  hasPreviousPage,
  currentPage,
  previousPage,
  nextPage,
  totalPages,
}) => {
  return (
    <ParentContainer>
      <ButtonContainer onClick={previousPage}>
        <PaginationLeftArrow
          color={hasPreviousPage ? colors.grey_dark : 'transparent'}
        />
      </ButtonContainer>

      <TextContainer>
        <Text>
          {currentPage} of {totalPages}
        </Text>
      </TextContainer>

      <ButtonContainer onClick={nextPage}>
        <PaginationRightArrow
          color={hasNextPage ? colors.grey_dark : 'transparent'}
        />
      </ButtonContainer>
    </ParentContainer>
  );
};
