import React from 'react';
import styled from 'styled-components';
import { colors } from '../../style-utils/colors';

interface SpinnerProps {
  isAddNewPropertyForm?: boolean;
  isId?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  isAddNewPropertyForm,
  isId,
}) => {
  return (
    <SpinnerSvg
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      isAddNewPropertyForm={isAddNewPropertyForm}
      isId={isId}
    >
      <SpinnerPath
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="24"
      ></SpinnerPath>
    </SpinnerSvg>
  );
};

const SpinnerSvg = styled.svg<{
  isAddNewPropertyForm?: boolean;
  isId?: boolean;
}>`
  ${({ isId }) =>
    isId
      ? `
  position:relative;
  margin:  auto;
      `
      : `
  position: absolute;
  top: 40%;
  left: 40%;
  `}

  z-index: 999999;
  animation: rotator 1.4s linear infinite;

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`;

const SpinnerPath = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;

  @keyframes colors {
    0% {
      stroke: ${colors.purple_dark};
    }
    25% {
      stroke: ${colors.purple_light};
    }
    50% {
      stroke: ${colors.purple_dark};
    }
    75% {
      stroke: ${colors.purple_light};
    }
    100% {
      stroke: ${colors.purple_dark};
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
`;
