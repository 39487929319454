import { useState } from 'react';

export interface DailyLogState {
  buildingActivity: string;
  name: string;
  lastName: string;
  apartmentLocation: string;
  priority: string;
  description: string;
  additionalNotes: string;
  userId: string | undefined;
  notes: string;
  details: string;
  property_ids: number[] | [] | undefined;
  createdAt?: string;
}

const useDailyLogHandler = (
  userId: string | undefined,
  property_ids: number[] | [] | undefined
) => {
  const initialState: DailyLogState = {
    buildingActivity: '',
    name: '',
    lastName: '',
    apartmentLocation: '',
    priority: '',
    notes: '',
    details: '',
    description: '',
    additionalNotes: '',
    userId: userId || '',
    property_ids: property_ids || [],
  };

  const [dailyLogState, setDailyLogState] =
    useState<DailyLogState>(initialState);

  const dailyLogChangeHandler = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | string,
    fieldName?: string,
    explicitValue?: string
  ) => {
    // eslint-disable-next-line
    let value: string | undefined;

    if (typeof e === 'string') {
      value = explicitValue;
    } else if ('value' in e.target) {
      value = e.target.value;
    } else {
      value = explicitValue;
    }

    if (fieldName) {
      setDailyLogState(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const dailyLogFinish = () => {
    setDailyLogState(initialState);
  };

  return { dailyLogState, dailyLogChangeHandler, dailyLogFinish };
};

export default useDailyLogHandler;
