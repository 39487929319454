import React from 'react';
import styled from 'styled-components';
import { colors } from '../../style-utils/colors';
import { Text, font } from '../../style-utils/text';

const ProgressContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  width: 350px; // Setting the width of the container
`;

const ProgressBarBackground = styled.div`
  height: 3px;
  background-color: ${colors.grey_light};
  width: 100%;
  position: relative;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: ${colors.purple_dark};
  transition: width 0.3s ease-out;
  position: absolute;
  left: 0;
  top: 0;
`;

interface ProgressProps {
  maxPaths: number;
  currentPathIndex: number;
}

export const Progress: React.FC<ProgressProps> = ({
  maxPaths,
  currentPathIndex,
}) => {
  const progressPercentage = ((currentPathIndex + 2) / (maxPaths + 1)) * 100;

  return (
    <ProgressContainer>
      <Text
        fontFamily={font.family}
        mb={6}
        fontSize={font.size.xs}
        mt={0}
        pb={0}
      >
        {maxPaths === 0
          ? `Step ${currentPathIndex} `
          : `Step ${currentPathIndex + 2} of ${maxPaths + 1} Completed`}
      </Text>
      <ProgressBarBackground>
        <ProgressBar progress={progressPercentage} />
      </ProgressBarBackground>
    </ProgressContainer>
  );
};
