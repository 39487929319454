import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInForm,
  LogInTitle,
  LogInSubTitle,
  ButtonContainer,
  ButtonTitle,
  InputContainer,
  Label,
  TextContainer,
  StyledInput,
  CompanyTitle,
  Description,
  eyeIconStyles,
  ErrorMessage,
} from './login.styled';
import { RightArrow } from '../icons/right-arrow';
import toast from 'react-hot-toast';
import { apiRoute } from '../../constants/api-constants';
import { Spinner } from '../icons/spinner';
import { useAuth } from '../../context/auth-provider';
import { DefaultComponentProps } from '../app-router/component-props';

interface LogInData {
  email: string;
  password: string;
}

export const LogIn: React.FC<DefaultComponentProps> = ({
  navigate,
  cookies,
}) => {
  const [logInData, setLogInData] = useState<LogInData>({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);

  const { setUser, user } = useAuth();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Trim empty spaces if the field is 'password'
    const trimmedValue = name === 'password' ? value.trim() : value;

    setLogInData({ ...logInData, [name]: trimmedValue });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await axios.post(apiRoute as string, logInData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      cookies.set('authorization_token', response.data.token);
      toast.success('You have successfully logged in');
      setInvalidCredentials(false);
      setUser(response.data.user);

      if (response.data.user.role === 'employee') {
        navigate('/main-menu', { replace: false });
      }

      if (
        response.data.user.role === 'admin' ||
        response.data.user.role === 'manager'
      ) {
        navigate('/dashboard', { replace: false });
      }
    } catch (error) {
      console.error(error);
      console.log(error);
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 401:
            setInvalidCredentials(true);
            toast.error('Invalid email or password');
            break;
          default:
            toast.error(
              'An unexpected error occurred. Please try again later.'
            );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      switch (user.role) {
        case 'admin':
        case 'manager':
          navigate('/dashboard');
          break;
        case 'employee':
          navigate('/main-menu');
          break;
        default:
        // Handle any other cases or just do nothing
      }
    }
  }, [user]);

  return (
    <LogInWrapper id="log-in-form">
      <LogInFormContainer>
        <LogInForm onSubmit={onSubmit}>
          {isLoading && <Spinner />}
          <LogInTitle>Sign In</LogInTitle>
          <LogInSubTitle>
            Enter the email address you use to sign in to Concierge
          </LogInSubTitle>
          <InputContainer>
            <Label>Email address</Label>
            <StyledInput
              type="text"
              name="email"
              value={logInData.email}
              placeholder={'Email address'}
              onChange={handleInputChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Password</Label>
            <StyledInput
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={logInData.password}
              placeholder={'Password'}
              onChange={handleInputChange}
              style={{ position: 'relative' }}
            />
            <i
              style={eyeIconStyles}
              onClick={() => setShowPassword(!showPassword)}
              className={showPassword ? 'far fa-eye' : `far fa-eye-slash`}
            />
          </InputContainer>
          {invalidCredentials && (
            <ErrorMessage>
              Invalid email or password. Please try again.
            </ErrorMessage>
          )}
          <ButtonContainer type="submit">
            <ButtonTitle>Sign In </ButtonTitle>
            <RightArrow />
          </ButtonContainer>
        </LogInForm>
        <TextContainer>
          <CompanyTitle>Welcome to Concierge</CompanyTitle>
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed risus
            nisi, condimentum id congue vitae, blandit a arcu. Nullam eget massa
            ex. Aliquam tincidunt non diam ut posuere.
          </Description>
        </TextContainer>
      </LogInFormContainer>
    </LogInWrapper>
  );
};
