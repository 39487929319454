export enum BUILDING_ACTIVITIES {
  MAINTENANCE = 'Maintenance',
  LEASING_OFFICE = 'Leasing Office',
  RESIDENT = 'Resident',
  MISSCELANEAUS = 'Miscellaneous',
  SHIFT_CHANGE = 'Shift Change',
}

export enum BUILDING_ACTIVITIES_TYPES {
  MAINTENANCE = 'maintenance',
  LEASING_OFFICE = 'leasing-office',
  RESIDENT = 'resident',
  MISSCELANEAUS = 'miscellaneous',
  SHIFT_CHANGE = 'shift-change',
}
