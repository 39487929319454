import React from 'react';

interface VisitorLogIconProps {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  stroke?: string;
}

export const VisitorLogIcon: React.FC<VisitorLogIconProps> = ({
  onClick,
  stroke,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M17.5 3.5H17.7C19.4913 3.5 20.387 3.5 20.9435 4.0565C21.5 4.61299 21.5 5.50866 21.5 7.3V7.5"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <path
        d="M17.5 20.5H17.7C19.4913 20.5 20.387 20.5 20.9435 19.9435C21.5 19.387 21.5 18.4913 21.5 16.7V16.5"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <path
        d="M6.5 3.5H6.3C4.50866 3.5 3.61299 3.5 3.0565 4.0565C2.5 4.61299 2.5 5.50866 2.5 7.3V7.5"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <path
        d="M6.5 20.5H6.3C4.50866 20.5 3.61299 20.5 3.0565 19.9435C2.5 19.387 2.5 18.4913 2.5 16.7V16.5"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <path
        d="M7.21484 15.7847C7.68758 15.1024 8.37508 14.5254 9.21678 14.1204C10.0585 13.7155 11.0187 13.5 12 13.5C12.9813 13.5 13.9415 13.7155 14.7832 14.1204C15.6249 14.5254 16.3124 15.1024 16.7852 15.7847"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="9"
        r="2.5"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
    </svg>
  );
};
