import { Location } from '../../components/pages/visitor-log/shared/location';
import { Notes } from '../../components/pages/visitor-log/shared/notes';
import { NumberOfGuests } from '../../components/pages/visitor-log/shared/number-of-people';
import { VisitorDetails } from '../../components/pages/visitor-log/shared/visitor-details';

export const getEmergencyRoutes = () => {
  const base = '/visitor-log/emergency/';

  return [
    { path: `${base}apartment-location`, component: Location },
    { path: `${base}notes`, component: Notes },
    { path: `${base}number-of-guests`, component: NumberOfGuests },
    {
      path: `${base}visitor-details`,
      component: VisitorDetails,
      options: ['Fire Department', 'Police', 'Ambulance', 'Other'],
    },
  ];
};
