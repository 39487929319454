import React from 'react';
interface DailyLogIconProps {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  stroke?: string;
}
export const DailyLogIcon: React.FC<DailyLogIconProps> = ({
  onClick,
  stroke,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="4"
        y="5"
        width="16"
        height="16"
        rx="2"
        stroke={stroke || '#637083'}
      />
      <path
        d="M16 2L16 6C16 6.94281 16 7.41421 15.7071 7.7071C15.4142 8 14.9428 8 14 8L10 8C9.05719 8 8.58579 8 8.29289 7.70711C8 7.41421 8 6.94281 8 6L8 2"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
      <path
        d="M9 14L15 14"
        stroke={stroke || '#637083'}
        strokeLinecap="round"
      />
    </svg>
  );
};
