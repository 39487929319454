import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirectOnBack = (route = '/main-menu') => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = 'Do you want to leave? Changes may not be saved.';

      return e.returnValue;
    };

    const handlePopState = () => {
      // Initialize or increment the back button press count in sessionStorage
      let backButtonCount = parseInt(
        sessionStorage.getItem('backButtonCount') || '0',
        10
      );
      backButtonCount += 1;
      sessionStorage.setItem('backButtonCount', backButtonCount.toString());

      if (backButtonCount > 1) {
        // change the threshold as per your requirement
        const userAcknowledged = window.confirm(
          'You will be redirected to the main menu and all progress will be lost'
        );
        if (userAcknowledged) {
          navigate(route);
        } else {
          // Reset the count if the user decides to stay
          sessionStorage.setItem('backButtonCount', '0');
        }
      }
    };

    // Push an entry to the history to initiate back button detection
    window.history.pushState({ preventBack: true }, '', '#rTrig');

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, route]);
};
