import React from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
} from '../../../layout/layout-container.styled';
import { Navbar } from '../../../layout/visitor-log/navbar';
import { NavButton } from '../../../nav-button/nav-button';
import { Text, font } from '../../../../style-utils/text';
import { StyledInput } from '../../../styled-input/styled-input';
import { InputContainer } from '../../../login/login.styled';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { DailyLogState } from '../../../hooks/use-daily-log-handler';
import { Step } from '../../../step/step';
import { findPathIndex } from './controllers/find-path-index';
import { Progress } from '../../../progress/progress';
import useRedirectOnRefresh from '../../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../../style-utils/use-window-height';

export const NameAndLastName: React.FC<DefaultComponentProps> = ({
  navigate,
  changeHandler,
  state,
  nextPath,
  prevPath,
  path,
  navigation,
  selectedOption,
  selectedBuilding,
}) => {
  const { name, lastName } = state as DailyLogState;
  const isLog = path?.includes('/daily-log') || false;
  const maxPaths = navigation?.length || 0;
  const currentPathIndex = findPathIndex(path, navigation);
  useRedirectOnRefresh();
  useRedirectOnBack();
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        selectedOption={selectedOption}
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer>
          <Step currentPathIndex={currentPathIndex} maxPaths={maxPaths || 0} />
          <Progress
            currentPathIndex={currentPathIndex}
            maxPaths={maxPaths || 0}
          />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer>
            <Navbar
              path={path}
              navigation={navigation}
              state={state}
              isLog={isLog}
              navigate={navigate}
              currentPage={path}
              hasContent
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column">
              <Text fontSize={font.size.xl} fontWeight={700} mt={0} mb={10}>
                {`ID (Name And Last Name)`}
              </Text>
              <Text
                color={font.color.gray}
                fontSize={font.size.xs}
                mt={0}
                mb={0}
              >
                {`Name and Last name for the resident`}
              </Text>
            </TitleAndSubtitle>
            <ContentButtonsWrapper>
              <InputContainer>
                <Text
                  color={'black'}
                  fontFamily={font.family}
                  fontWeight={600}
                  fontSize={font.size.xs}
                  mt={0}
                  mb={0}
                  width={'70'}
                  pl={0}
                  pr={32}
                  pb={10}
                  margin={'0 auto'}
                >
                  {`Name`}
                </Text>
                <StyledInput
                  maxLength={30}
                  width={70}
                  placeholder={'Enter name'}
                  type="text"
                  defaultValue={name}
                  name={'name'}
                  // eslint-disable-next-line
                  onChange={(e: any) => changeHandler(e, 'name')}
                />
                <Text
                  fontWeight={600}
                  color={'black'}
                  fontFamily={font.family}
                  fontSize={font.size.xs}
                  mt={0}
                  mb={0}
                  width={'70'}
                  pl={0}
                  pr={32}
                  pb={10}
                  margin={'0 auto'}
                >
                  {`Last Name`}
                </Text>
                <StyledInput
                  maxLength={50}
                  width={70}
                  placeholder={'Enter last name'}
                  type="text"
                  defaultValue={lastName}
                  name={'lastName '}
                  // eslint-disable-next-line
                  onChange={(e: any) => changeHandler(e, 'lastName')}
                />
              </InputContainer>
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => navigate(prevPath, { replace: false })}
              />
              <NavButton
                disabled={
                  lastName.length >= 1 && name.length >= 1 ? false : true
                }
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={'Continue'}
                onClick={() => navigate(nextPath, { replace: false })}
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
