import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const DashboardContent = styled.div<{ isAddNewBuilding?: boolean }>`
  height: 100%;
  width: 100%;
  background: ${({ isAddNewBuilding }) =>
    isAddNewBuilding && `${colors.white}`};
  overflow: ${({ isAddNewBuilding }) => isAddNewBuilding && 'hidden'};
`;

export const BuildingsListing = styled.div`
  box-sizing: border-box;
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: ${colors.white};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const NewBuildingButton = styled.button`
  background: ${colors.purple_dark};
  width: 150px;
  height: 36px;
  border: none;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  /* box-shadow: 2px 2px 8px 0px #0000001a; */
  transition: all 0.3s ease-out;
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    transform: scale(1.02);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const Column = styled.div<{ isCentered?: boolean }>`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ isCentered?: boolean }>`
  display: flex;
  flex: 1;
  ${({ isCentered }) =>
    isCentered &&
    `
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledFlexRow = styled(Row)`
  flex: 1;
  max-height: 60px;
  border-radius: 6px;
  margin-bottom: 8px;

  &:nth-of-type(1) {
    margin-top: 8px;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.white};
  border-radius: 6px;
  overflow: hidden;
`;

// total items width must be 100
export const ItemContainer = styled.div<{
  width?: number;
  isHeader?: boolean | undefined;
  isImage?: boolean | undefined;
  isActions?: boolean;
}>`
  width: ${({ width }) => width && width}%;
  height: ${({ isHeader }) => (isHeader ? `60px;` : `100%;`)};
  display: flex;
  justify-content: ${({ isImage }) => (isImage ? 'center;' : 'left;')};
  /* ${({ isImage }) =>
    isImage
      ? `padding-left: 0px;`
      : `padding-left: 16px; padding-right: 16px;`}; */
  align-items: center;
  overflow: auto;
  background: ${colors.white};
  padding-left: 16px;
  padding-right: 16px;

  /* position: ${({ isActions }) => isActions && 'relative'}; */

  &:nth-child(5) {
    justify-content: flex-end;
  }
`;

export const Button = styled.button<{
  backgroundColor: string;
  textColor: string;
}>`
  height: 27px;
  width: 92px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-family: ${colors.purple_dark};
  cursor: pointer;
  border: none;
`;

export const StyledInput = styled.input`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const AddNewPropertyFormContainer = styled.div`
  width: 100%;
  /* height: 520px; */
  height: 460px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
`;

export const AddNewPropertyForm = styled.form`
  width: 450px;
  height: 520px;
  position: relative;
`;

export const InputContainer = styled.div<{ isImageUpload?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  border: ${({ isImageUpload }) => isImageUpload && ''};

  align-items: ${({ isImageUpload }) => isImageUpload && 'center'};
  height: ${({ isImageUpload }) => isImageUpload && '86px'};
  position: ${({ isImageUpload }) => isImageUpload && 'relative'};
  border: ${({ isImageUpload }) =>
    isImageUpload && `0.2px solid ${colors.grey_light}`};
  border-radius: 8px;

  input[type='file'] {
    height: 100%;
    width: 100%;
    z-index: 9px;
    position: absolute;
    opacity: 0;
  }
`;

export const ImageUploadContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Label = styled.label<{
  isImageUpload?: boolean;
  isOpenningNewInput?: boolean;
}>`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${({ isImageUpload }) => (isImageUpload ? '0px' : '10px')};
  margin-left: ${({ isImageUpload }) => isImageUpload && '16px'};
  color: ${({ isOpenningNewInput }) =>
    isOpenningNewInput ? `${colors.purple_label}` : `${colors.grey_dark}`};
  cursor: pointer;
  display: ${({ isOpenningNewInput }) => isOpenningNewInput && 'block'};
`;

export const ErrorLabel = styled.label`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 500;
  color: ${colors.error};
  display: block;
  margin-top: 6px;
`;

export const StyledBuildingInput = styled.input<{
  isImageUpload?: boolean;
  isCountryCodeInput?: boolean;
}>`
  display: flex;
  padding: 14px 16px;
  padding: ${({ isImageUpload }) => isImageUpload && '0px'};
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  width: ${({ isCountryCodeInput }) => isCountryCodeInput && '100%'};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const CountryCodeContainer = styled.div`
  display: flex;
`;

export const CountryCodeSelect = styled.select`
  padding: 4px;
  margin-right: 6px;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
`;

export const CountryCode = styled.div`
  font-size: ${font.size.xs};
  margin-bottom: 2px;
  margin-right: 6px;
`;

export const ButtonsContainer = styled.div`
  height: 36px;
  margin-top: 30px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled.button`
  background: ${colors.white};
  width: 97px;
  height: 36px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: ${colors.grey};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SaveButton = styled.button`
  background: ${colors.purple_dark};
  width: 97px;
  height: 36px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 64px; */
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p`
  font-family: ${font.family};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const ActionModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`;

export const ActionModal = styled.div`
  position: absolute;
  height: 39px;
  min-width: 184px;
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  right: 16px;
  border-radius: 8px;
  background: var(--default-white, #fff);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.button<{ isDelete?: boolean }>`
  background: ${({ isDelete }) =>
    isDelete ? `${colors.error}` : `${colors.purple_dark}`};
  height: 23px;
  width: 80px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const StyledImage = styled.img<{ isAddNewPropertyForm?: boolean }>`
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 8px;

  width: ${({ isAddNewPropertyForm }) => isAddNewPropertyForm && '40px'};
  height: ${({ isAddNewPropertyForm }) => isAddNewPropertyForm && '40px'};
`;

export const DeleteModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
`;

export const DeleteModal = styled.div`
  background: #fff;
  width: 350px;
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 10;
`;

export const CancelConfirmButton = styled.div<{ isConfirm?: boolean }>`
  background: ${({ isConfirm }) =>
    isConfirm ? `${colors.purple_dark}` : `${colors.white}`};
  display: flex;
  height: 41px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  align-self: stretch;
  border-radius: 8px;
  cursor: pointer;
`;
