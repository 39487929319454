import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  font-size: 0.9em;
  table-layout: auto; // Auto layout for flexible cell width

  th,
  td {
    padding: 0.6em;
    border: 1px solid #ddd;
    word-wrap: break-word; // Enable word wrapping inside cells
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  @media (max-width: 768px) {
    font-size: 0.8em; // Smaller font size for smaller screens

    th,
    td {
      padding: 0.4em; // Adjust padding for smaller screens
    }
  }
`;

export const ResponsiveTableWrapper = styled.div`
  overflow-x: hidden; // Hide horizontal scrollbar
`;
