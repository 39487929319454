import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { routes } from '../../constants/page-routes/page-routes';
import { useAuth } from '../../context/auth-provider';
import { getCurrentDateTime } from '../get-date-time/get-date-time';
import useVisitorLogHandler from '../hooks/use-visitor-log-handler';
import useDailyLogHandler from '../hooks/use-daily-log-handler';
import {
  dailyLogPaths,
  generatePaths,
  visitorLogPaths,
} from './path-generator';
import { getNextAndPrevPaths } from './next-and-prev-paths';
import { getNavigationFromVisitorType } from '../layout/visitor-log/navbar-controller';
import { User } from '../../interfaces/user-interfaces';
import useBuildingData from '../pages/dashboard/dashboard-home/use-get-building-data';

export const AppRouter = () => {
  const location = useLocation();
  const currentURLPath = location.pathname;

  const {
    user,
  }: {
    user: User | null | undefined;
  } = useAuth();

  const { id, property_ids } = user || {};
  const { selectedBuilding } = useBuildingData(user);
  const { visitorLogState, visitorFinish, visitorChangeHandler, addNewName } =
    useVisitorLogHandler(id, property_ids);

  const { dailyLogChangeHandler, dailyLogFinish, dailyLogState } =
    useDailyLogHandler(id, property_ids);

  const visitorLogRoutes = generatePaths(visitorLogPaths);

  const dailyLogRoutes = generatePaths(dailyLogPaths);

  const navigate = useNavigate();

  const { cookies } = useAuth();

  const { time, date } = getCurrentDateTime();

  const allPaths = [...visitorLogRoutes, ...dailyLogRoutes];

  useEffect(() => {
    if (
      user &&
      currentURLPath.includes('dashboard') &&
      user?.role === 'employee'
    ) {
      navigate('/main-menu');
    }
  }, [user]);

  return (
    <Routes>
      {routes.map((route, i) => {
        const { path, component: Component, options = [] } = route;

        const { nextPath, prevPath, lastPath } = getNextAndPrevPaths(
          currentURLPath,
          allPaths
        );

        const state = path.includes('/visitor-log')
          ? visitorLogState
          : dailyLogState;

        const changeHandler = path.includes('/visitor-log')
          ? visitorChangeHandler
          : dailyLogChangeHandler;

        const finish = path.includes('/visitor-log')
          ? visitorFinish
          : dailyLogFinish;

        const navigation = getNavigationFromVisitorType(
          path.includes('/visitor-log')
            ? visitorLogState.visitorType
            : dailyLogState.buildingActivity
        );

        const selectedOption = path.includes('/visitor-log')
          ? '/visitor-log'
          : '/daily-log';

        return (
          <Route
            key={i}
            path={path}
            element={
              <Component
                selectedBuilding={selectedBuilding}
                addNewName={addNewName}
                selectedOption={selectedOption}
                lastPath={lastPath}
                navigation={navigation}
                allPaths={allPaths}
                nextPath={nextPath}
                prevPath={prevPath}
                options={options}
                date={date}
                time={time}
                cookies={cookies}
                state={state}
                navigate={navigate}
                path={path}
                changeHandler={changeHandler}
                finish={finish}
              />
            }
          />
        );
      })}
    </Routes>
  );
};
