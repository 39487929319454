import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const DashboardContent = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.white};
  overflow: auto;
  position: relative;
`;

export const Analytics = styled.div`
  box-sizing: border-box;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: ${colors.white};
  border-bottom: 0.2px solid ${colors.grey_light};
`;

export const CheckBoxInput = styled.input`
  margin-left: 16px;
  margin-right: 4px;

  &:nth-child(1) {
    margin-left: 0;
    border: 1px solid red;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ButtonsFilterExport = styled.div`
  display: flex;
`;

export const FilterButtonContainer = styled.div`
  background: ${colors.white};
  max-width: 97px;
  height: 32px;
  border: none;
  padding: 0 8px 0 16px;
  color: ${colors.grey_dark};
  cursor: pointer;
  border-radius: 50px;
  border: 0.2px solid rgba(51, 51, 51, 0.5);
  transition: all 0.3s ease-out;
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  &:hover {
    transform: scale(1.02);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ExportButton = styled.button`
  background: ${colors.purple_dark};
  width: fit-content;
  height: 32px;
  border: none;
  padding: 4px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease-out;
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;

  &:hover {
    transform: scale(1.02);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const FilterSidebarBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 7;
`;

export const FilterSidebar = styled.div`
  background: #fff;
  box-sizing: border-box;
  width: 550px;
  height: 100%;
  z-index: 10;
  overflow: auto;
  padding-bottom: 75px;
`;

export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-top: 16px; */
  padding: 32px;
  overflow: auto;
  /* gap: 16px; */
`;

export const StyledSelectMenu = styled.select<{ isChooseTypeOfLog?: boolean }>`
  -webkit-appearance: none;
  display: flex;
  padding: 8px 16px;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  box-shadow: ${({ isChooseTypeOfLog }) =>
    isChooseTypeOfLog && '2px 2px 10px 0px rgba(148, 110, 209, 0.10)'};
  font-size: ${({ isChooseTypeOfLog }) => isChooseTypeOfLog && '18px'};
  font-weight: ${({ isChooseTypeOfLog }) => isChooseTypeOfLog && '600'};
  text-align: ${({ isChooseTypeOfLog }) => isChooseTypeOfLog && 'center'};
  text-transform: ${({ isChooseTypeOfLog }) =>
    isChooseTypeOfLog && 'capitalize'};
  padding: ${({ isChooseTypeOfLog }) =>
    isChooseTypeOfLog ? '16px 50px' : '8px 16px'};

  .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const StyledFilterInput = styled.input`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 518px;
  z-index: 2;
  right: 0%;
  bottom: 0%;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  background: white;
`;

export const CancelButton = styled.button`
  background: ${colors.white};
  width: 50%;
  height: 41px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: ${colors.grey};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ConfirmButton = styled.button`
  background: ${colors.purple_dark};
  width: 50%;
  height: 36px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* margin-top: 64px; */
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const CheckRingContainer = styled.div`
  position: absolute;
  top: 50%;
  /* margin-left: 16px; */
`;

export const TimePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const ErrorLabel = styled.label`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 500;
  color: ${colors.error};
  display: block;
  margin-top: 6px;
`;

export const TimePickerSelectMenu = styled.select`
  /* -webkit-appearance: none; */
  display: flex;
  padding: 6px 10px;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  margin-right: 6px;

  .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;
