// requests.ts

import axios from 'axios';
import { apiRoute } from '../../constants/api-constants';

type DataResponse = unknown;
type DataResponseArray = unknown[];

const baseURL = apiRoute;
// || 'http://localhost:8000/api';

// Fetch a specific item by its ID
export const fetchItemById = async (
  slug: string,
  id: number
): Promise<DataResponse> => {
  const { data } = await axios.get(`${baseURL}/${slug}/${id}`);

  return data;
};

// Fetch all items
export const fetchAllItems = async (
  slug: string,
  limit?: number,
  // eslint-disable-next-line
  queryParams?: Record<string, any> // Additional query parameters
): Promise<DataResponseArray> => {
  // Initialize an object to hold the final query parameters
  // eslint-disable-next-line
  const finalQueryParams: Record<string, any> = {};

  // If limit is provided, add it to the final query parameters
  if (limit) {
    finalQueryParams.limit = limit;
  }

  // Add any additional query parameters to the final query parameters
  if (queryParams) {
    Object.assign(finalQueryParams, queryParams);
  }

  // Construct the query string using URLSearchParams
  const queryString = new URLSearchParams(finalQueryParams).toString();
  const url = `${baseURL}/${slug}${queryString ? `?${queryString}` : ''}`;

  // Fetch data
  const { data } = await axios.get(url);

  return data;
};

// Create a new item
export const createItem = async (
  slug: string,
  data: object
): Promise<DataResponse> => {
  const { data: responseData } = await axios.post(`${baseURL}/${slug}`, data);

  return responseData;
};

// Update an existing item
export const updateItem = async (
  slug: string,
  data: object,
  id: number
): Promise<DataResponse> => {
  const { data: responseData } = await axios.put(
    `${baseURL}/${slug}/${id}`,
    data
  );

  return responseData;
};

// Update property or user item
export const updatePropertyOrUserItem = async (
  slug: string,
  data: object
): Promise<DataResponse> => {
  const { data: responseData } = await axios.put(`${baseURL}/${slug}`, data);

  return responseData;
};
