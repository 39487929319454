import styled from 'styled-components';
import { colors } from '../../style-utils/colors';

export const Dropdown = styled.div<{ isDashboard?: boolean }>`
  position: relative;
  ${({ isDashboard }) => (isDashboard ? `width: 180px; ` : `width: 70%;`)};
`;

export const DropdownButton = styled.div<{
  isSelected: boolean;
  isDashboard: boolean;
  isRsuite?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isDashboard, isRsuite }) =>
    isDashboard &&
    (isRsuite
      ? `height: 36px;
      border-radius: 6px;
         `
      : `height: 12px;
         padding: 8px 8px 8px 16px;
         border-radius: 50px;`)}
  ${({ isDashboard }) =>
    !isDashboard &&
    `height: 40px;  
     padding-top: 8px;
     padding-bottom: 8px;
     padding-left: 16px;
     padding-right: 40px;
     border-radius: 6px;`}

  border: 1px solid var(--btn-def, ${colors.grey_light});
  background: #fff;

  ${({ isSelected }) =>
    isSelected && `box-shadow: 2px 2px 10px 0px rgba(148, 110, 209, 0.1);`};
`;

export const OptionsList = styled.div<{
  isDashboard?: boolean;
  isRsuite?: boolean;
}>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: none;
  border: 1px solid var(--btn-def, ${colors.grey_light});
  border-radius: 6px;
  z-index: 69;
  max-height: 210px;
  overflow: auto;

  ${({ isDashboard, isRsuite }) =>
    isDashboard && isRsuite && `padding-top: 8px; height: fit-content;`}
`;

export const OptionItem = styled.div<{
  isSelected: boolean;
  isDashboard?: boolean;
  isRsuite?: boolean;
}>`
  ${({ isDashboard }) =>
    isDashboard
      ? `justify-content:left; height: 15px;`
      : `justify-content: space-between; height: 35px;`}
  display: flex;
  align-items: center;

  padding: 8px 16px;

  cursor: pointer;
  overflow: hidden;
  ${({ isDashboard, isSelected }) =>
    isDashboard
      ? ``
      : isSelected
      ? `padding-right: 40px;`
      : `padding-right: 16px;`}

  ${({ isDashboard, isRsuite }) =>
    isDashboard &&
    isRsuite &&
    `margin-bottom: 8px; padding-left: 0; min-width: 170px; `}

  &:hover {
    background-color: #f2f2f2;
    border-radius: 2px;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  margin-left: 10px;
`;
