import React from 'react';

export const DashboardLogoArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12L9.64645 11.6464L9.29289 12L9.64645 12.3536L10 12ZM20 12.5C20.2761 12.5 20.5 12.2761 20.5 12C20.5 11.7239 20.2761 11.5 20 11.5V12.5ZM15.6464 5.64645L9.64645 11.6464L10.3536 12.3536L16.3536 6.35355L15.6464 5.64645ZM9.64645 12.3536L15.6464 18.3536L16.3536 17.6464L10.3536 11.6464L9.64645 12.3536ZM10 12.5H20V11.5H10V12.5Z"
        fill="#8C67C9"
      />
      <path d="M4 5L4 19" stroke="#8C67C9" />
    </svg>
  );
};
