import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import useScreenSize from '../../../../style-utils/use-screen-size';
import { TimePeriod } from './use-get-building-data';

interface DashboardHomeChartProps {
  dataType: string;
  // eslint-disable-next-line
  data: any;
}

export const DashboardHomeChart: React.FC<DashboardHomeChartProps> = ({
  dataType,
  data = [],
}) => {
  const { windowWidth, isTablet, isMobile } = useScreenSize();

  let formatedTimePeriod: string;
  switch (dataType) {
    case TimePeriod.Daily:
      formatedTimePeriod = 'hour';
      break;
    case TimePeriod.Weekly:
      formatedTimePeriod = 'day';
      break;
    case TimePeriod.Monthly:
      formatedTimePeriod = 'day';
      break;
    default:
      throw new Error('Unsupported time period');
  }

  const chartData = data
    ? data?.map(
        (
          // eslint-disable-next-line
        item: any
        ) => ({
          name: item[formatedTimePeriod],
          uv: item.count,
          Entries: item.count,
        })
      )
    : [
        {
          name: 'No data',
          uv: 1,
          Entries: 1,
        },
      ];

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      key={windowWidth.toString()}
    >
      <LineChart
        data={chartData}
        margin={{
          top: 32,
          right: isMobile
            ? 32
            : isTablet
            ? 64
            : chartData[0]?.name === 'January'
            ? 10
            : 60,
          left: isMobile
            ? 32
            : isTablet
            ? 64
            : chartData[0]?.name === 'January'
            ? 0
            : 60,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Entries" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};
