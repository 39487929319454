import React, { useEffect } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  Progress,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
} from '../../layout/layout-container.styled';
import { Navbar } from '../../layout/visitor-log/navbar';
import { NavButton } from '../../nav-button/nav-button';
import { Text, font } from '../../../style-utils/text';
import { StyledButton } from '../../styled-button/styled-button';
import { CheckRing } from '../../icons/check-ring';
import { GUEST_TYPES } from '../../../constants/visitor-types';
import { DefaultComponentProps } from '../../app-router/component-props';
import { VisitorLogState } from '../../hooks/use-visitor-log-handler';
import { toKebabCase } from '../../app-router/path-generator';
import { VISITOR_TYPES } from '../../layout/visitor-log/navbar-controller';
import { Step } from '../../step/step';
import useRedirectOnRefresh from '../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../style-utils/use-window-height';

export const VisitorLog: React.FC<DefaultComponentProps> = ({
  navigate,
  finish,
  state,
  changeHandler,
  allPaths,
  path,
  navigation,
  selectedBuilding,
}) => {
  const { visitorType } = state as VisitorLogState;
  const allRoutes = allPaths || [];

  const basePath =
    visitorType === VISITOR_TYPES.EMERGENCY
      ? '/emergency'
      : `/${toKebabCase(visitorType)}`;

  const firstMatchingPath = allRoutes.find(path => path.startsWith(basePath));

  useEffect(() => {
    finish();
  }, []);
  useRedirectOnRefresh();
  useRedirectOnBack();

  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <Sidebar
        selectedBuilding={selectedBuilding}
        selectedOption="/visitor-log"
        navigate={navigate}
      />
      <ContentWrapper>
        <StepAndProgressContainer>
          <Step currentPathIndex={1} maxPaths={0} />
          <Progress />
        </StepAndProgressContainer>
        <NavbarAndContentContainer>
          <NavbarContainer>
            <Navbar
              path={path}
              navigation={navigation}
              state={state}
              isLog={false}
              navigate={navigate}
              hasContent={false}
              currentPage={path}
            />
          </NavbarContainer>
          <ContentAndButtonsContainer>
            <TitleAndSubtitle direction="column">
              <Text fontSize={font.size.xl} fontWeight={700} mt={0} mb={10}>
                Visitor Type
              </Text>
              <Text
                color={font.color.gray}
                fontSize={font.size.xs}
                mt={0}
                mb={0}
              >
                Please select one of the options below
              </Text>
            </TitleAndSubtitle>
            <ContentButtonsWrapper>
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'visitorType', GUEST_TYPES.GUEST)
                }
                text={GUEST_TYPES.GUEST}
                icon={<CheckRing />}
                isSelected={visitorType === GUEST_TYPES.GUEST}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'visitorType', GUEST_TYPES.PROSPECT)
                }
                text={GUEST_TYPES.PROSPECT}
                icon={<CheckRing />}
                isSelected={visitorType === GUEST_TYPES.PROSPECT}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'visitorType', GUEST_TYPES.VENDOR)
                }
                text={GUEST_TYPES.VENDOR}
                icon={<CheckRing />}
                isSelected={visitorType === GUEST_TYPES.VENDOR}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'visitorType', GUEST_TYPES.DELIVERY)
                }
                text={GUEST_TYPES.DELIVERY}
                icon={<CheckRing />}
                isSelected={visitorType === GUEST_TYPES.DELIVERY}
              />
              <StyledButton
                // eslint-disable-next-line
                onClick={(e: any) =>
                  changeHandler(e, 'visitorType', GUEST_TYPES.EMERGENCY)
                }
                text={GUEST_TYPES.EMERGENCY}
                icon={<CheckRing />}
                isSelected={visitorType === GUEST_TYPES.EMERGENCY}
              />
            </ContentButtonsWrapper>
            <ButtonsContainer>
              <NavButton
                disabled={false}
                direction="back"
                textColor="#637083"
                background="transparent"
                text={'Back'}
                onClick={() => navigate('/main-menu')}
              />
              <NavButton
                disabled={visitorType.length > 1 ? false : true}
                direction="forward"
                textColor={'white'}
                background={font.color.purple}
                text={'Continue'}
                onClick={() => {
                  if (firstMatchingPath) {
                    navigate(`/visitor-log${firstMatchingPath}`, {
                      replace: false,
                    });
                  } else {
                    navigate('/main-menu', { replace: false });
                  }
                }}
              />
            </ButtonsContainer>
          </ContentAndButtonsContainer>
        </NavbarAndContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
