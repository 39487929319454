import React, { useEffect } from 'react';
import { DatePicker } from 'rsuite';

interface SelectDateProps {
  value: Date | undefined;
  onChange: (value: Date) => void;
}

export const SelectDate: React.FC<SelectDateProps> = ({ value, onChange }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';

    link.href = 'https://unpkg.com/rsuite@4.1.3/dist/styles/rsuite-default.css';

    document.head.appendChild(link);

    // Cleanup function to remove the link if the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <DatePicker placeholder="Select a date" onChange={onChange} value={value} />
  );
};
