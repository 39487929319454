import React, { useState, useRef, useEffect } from 'react';
import {
  Dropdown,
  DropdownButton,
  IconWrapper,
  OptionsList,
  OptionItem,
} from './styled-options.styled';
import { Text, font } from '../../style-utils/text';
import { DownArrow } from '../icons/down-arrow';

interface OptionsInputProps {
  options?: string[];
  selectedOption?: string;
  icon: React.ReactNode;
  changeHandler: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | string,
    fieldName?: string,
    explicitValue?: string
  ) => void;
  changeHandlerName: string;
  isDashboard?: boolean;
  isRsuite?: boolean;
}

export const OptionsInput: React.FC<OptionsInputProps> = ({
  options,
  selectedOption,
  icon,
  changeHandler,
  changeHandlerName,
  isDashboard,
  isRsuite,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  // eslint-disable-next-line
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown ref={ref} isDashboard={isDashboard || false}>
      <DropdownButton
        isRsuite={isRsuite}
        isDashboard={isDashboard || false}
        onClick={toggleOpen}
        isSelected={Boolean(selectedOption)}
      >
        {selectedOption && <IconWrapper>{icon}</IconWrapper>}
        <Text
          fontFamily={font.family}
          fontSize={isDashboard ? font.size.xs : font.size.m}
          fontWeight={selectedOption ? 600 : 400}
        >
          {selectedOption
            ? selectedOption
            : isDashboard
            ? 'Select a property'
            : 'Select an option'}
        </Text>
        <DownArrow />
      </DropdownButton>
      {isOpen && (
        <OptionsList isDashboard={isDashboard || false} isRsuite={isRsuite}>
          {options?.map(option => (
            <OptionItem
              isDashboard={isDashboard}
              isSelected={option === selectedOption}
              isRsuite={isRsuite}
              key={option}
              onClick={e => {
                changeHandler(e, changeHandlerName, option);
                toggleOpen();
              }}
            >
              <IconWrapper>{option === selectedOption ? icon : ''}</IconWrapper>
              <Text
                fontFamily={font.family}
                fontSize={isDashboard ? font.size.xs : font.size.m}
                fontWeight={option === selectedOption ? 600 : 500}
              >
                {option}
              </Text>
              {!isDashboard && <div style={{ width: '40px' }}></div>}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </Dropdown>
  );
};
