import React, { useEffect, useState } from 'react';
import { colors } from '../../../../style-utils/colors';
import { Text } from '../../../../style-utils/text';
import {
  ActionModal,
  ActionModalBackground,
  BuildingsListing,
  ButtonContainer,
  ButtonTitle,
  CancelConfirmButton,
  Column,
  DashboardContent,
  DeleteModal,
  DeleteModalBackground,
  ItemContainer,
  NewBuildingButton,
  StyledFlex,
  StyledFlexRow,
  StyledImage,
  Title,
} from './dashboard-buildings.styled';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from '../../../icons/three-dots';
import { PaginationButtons } from '../dashboard-shared-components/table-pagination/table-pagination';
import { usePagination } from '../../../hooks/use-pagination';
import { BuildingData } from '../../../../interfaces/building-interfaces';
import {
  createItem,
  fetchAllItems,
  updatePropertyOrUserItem,
} from '../../../requests/requests';
import { User } from '../../../../interfaces/user-interfaces';
import { useAuth } from '../../../../context/auth-provider';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { SearchInputContainer } from '../dashboard.styled';
import { StyledInput } from '../../../login/login.styled';

interface DashboardBuildingsProps {
  updateEditableBuildingData: (newValue: BuildingData) => void;
}

export const DashboardBuildings: React.FC<DashboardBuildingsProps> = ({
  updateEditableBuildingData,
}) => {
  const { user }: { user: User | null | undefined } = useAuth();
  const [buildingsData, setBuildingsData] = useState<BuildingData[]>([]);
  const [editableBuildingId, setEditableBuildingId] = useState<string | null>(
    null
  );
  const [buildingToBeDeleted, setBuildingToBeDeleted] =
    useState<BuildingData | null>(null);
  const [isActionModalVisible, setIsActionModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const [filteredBuildings, setFilteredBuildings] = useState<BuildingData[]>(
    []
  );
  const [searchParam, setSearchParam] = useState('');
  // eslint-disable-next-line
  const handleSearchChange = (event: any) => {
    setSearchParam(event.target.value);
    filterBuildings(event.target.value);
  };

  const filterBuildings = (searchTerm: string) => {
    if (!searchTerm) {
      setFilteredBuildings(buildingsData);
      resetPagination();

      return;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const result = buildingsData.filter((building: BuildingData) => {
      return (
        building.property_name?.toLowerCase().includes(lowerCaseSearchTerm) ||
        building.address?.toLowerCase().includes(lowerCaseSearchTerm) ||
        building.contact_person?.toLowerCase().includes(lowerCaseSearchTerm) ||
        building.email[0]?.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });

    setFilteredBuildings(result);
  };

  const isSearching = !searchParam || searchParam.length < 1;

  const {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
    resetPagination,
  } = usePagination(isSearching ? buildingsData : filteredBuildings);

  const totalPages = getTotalPages();
  const navigate = useNavigate();

  const openAddNewBuildingForm = () => {
    navigate('buildings/add-new-property');
  };

  const getPropertiesfromDB = async () => {
    let response;
    if (user && user?.role === 'admin') {
      //This is a get request to get all buildings, because the logged in user has admin role
      response = await fetchAllItems('dashboard/buildings');
    } else {
      //This is a post request (but it's acctually a get req) to get only the buildings that are assigned to the logged in user, which has a manager role
      if (user) {
        response = await createItem('dashboard/buildings', user);
      }
    }

    setBuildingsData(response as BuildingData[]);
  };

  useEffect(() => {
    getPropertiesfromDB();
  }, [user]);

  const showEditDeleteActions = (buildingId: string) => {
    setIsActionModalVisible(!isActionModalVisible);

    setEditableBuildingId(buildingId);

    const filteredBuildingById = buildingsData?.filter(
      building => building.id === buildingId
    );

    setBuildingToBeDeleted(filteredBuildingById[0]);
    updateEditableBuildingData(filteredBuildingById[0]);
  };

  const hideEditDeleteActions = () => {
    setIsActionModalVisible(false);
    setEditableBuildingId(null);
    setBuildingToBeDeleted(null);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteModalVisible(true);
  };

  const handleCancelDelete = () => {
    setBuildingToBeDeleted(null);
    setIsDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (buildingToBeDeleted) {
        buildingToBeDeleted.status = 'inactive';

        await updatePropertyOrUserItem(
          'dashboard/buildings',
          buildingToBeDeleted
        );
      }

      getPropertiesfromDB();
    } catch (error) {
      console.error(error);
    } finally {
      setBuildingToBeDeleted(null);
    }
    setIsDeleteModalVisible(false);
  };

  useRedirectOnBack('/dashboard');

  return (
    <DashboardContent id="dashboard-buildings-content">
      <BuildingsListing>
        <Title>
          <Text mt={2} mb={0} fontSize={18} fontWeight={700}>
            Buildings listing
          </Text>
          <Text mt={2} mb={0} fontSize={12} color={`${colors.grey}`}>
            Please select property to show
          </Text>
        </Title>
        <SearchInputContainer>
          {/* <Text
            pr={16}
            fontFamily={font.family}
            fontSize={19}
            color={`${colors.black}`}
            fontWeight={700}
          >
            Search:
          </Text> */}
          <StyledInput
            name="userSearch"
            type="text"
            value={searchParam}
            onChange={handleSearchChange}
            placeholder="Search..."
          />
        </SearchInputContainer>
        {user?.role === 'admin' && (
          <NewBuildingButton
            id="new-building-button"
            onClick={() => openAddNewBuildingForm()}
          >
            Add new building
          </NewBuildingButton>
        )}
      </BuildingsListing>

      <Column>
        <StyledFlexRow>
          <StyledFlex>
            <ItemContainer isHeader width={22}>
              <Text
                mt={0}
                mb={0}
                fontSize={16}
                fontWeight={600}
                color={colors.grey_dark}
              >
                Property name
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={26}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Location
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={23}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Email
              </Text>
            </ItemContainer>
            <ItemContainer isHeader width={23}>
              <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                Phone
              </Text>
            </ItemContainer>

            {user?.role === 'admin' && (
              <ItemContainer isHeader width={6}>
                <Text fontSize={16} fontWeight={600} color={colors.grey_dark}>
                  Action
                </Text>
              </ItemContainer>
            )}
          </StyledFlex>
        </StyledFlexRow>
        {/* {!loading ? ( */}

        {
          displayedData.map((e, i) => {
            return (
              <StyledFlexRow key={i}>
                <StyledFlex>
                  <ItemContainer isHeader width={22}>
                    <StyledImage
                      src={e.property_image !== '' ? e.property_image : ''}
                      alt=""
                    />

                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.property_name}
                    </Text>
                  </ItemContainer>

                  <ItemContainer isHeader width={26}>
                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.address}, {e.country}
                    </Text>
                  </ItemContainer>
                  <ItemContainer isHeader width={23}>
                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.email[0]}
                    </Text>
                  </ItemContainer>

                  <ItemContainer isHeader width={23}>
                    <Text
                      mt={0}
                      mb={0}
                      fontSize={14}
                      fontWeight={400}
                      color={colors.grey_dark}
                    >
                      {e.phone_number}
                    </Text>
                  </ItemContainer>

                  {user?.role === 'admin' && (
                    <ItemContainer
                      isHeader
                      width={6}
                      onClick={() => showEditDeleteActions(e.id)}
                      style={{ cursor: 'pointer' }}
                      isActions={true}
                    >
                      <ThreeDots />

                      {isActionModalVisible && editableBuildingId === e.id && (
                        <>
                          <ActionModalBackground
                            id="action-modal-background"
                            // onClick={() => setIsActionModalVisible(false)}
                            onClick={() => hideEditDeleteActions()}
                          />
                          <ActionModal>
                            <ButtonContainer
                              isDelete={true}
                              onClick={handleDeleteButtonClick}
                            >
                              <ButtonTitle>Delete</ButtonTitle>
                            </ButtonContainer>
                            <ButtonContainer
                              onClick={() =>
                                navigate(`buildings/edit-property`)
                              }
                            >
                              <ButtonTitle>Edit</ButtonTitle>
                            </ButtonContainer>
                          </ActionModal>
                        </>
                      )}
                    </ItemContainer>
                  )}

                  {isDeleteModalVisible && (
                    <DeleteModalBackground
                      id="delete-modal-background"
                      onClick={() => setIsDeleteModalVisible(false)}
                    >
                      <DeleteModal
                        id="delete-modal"
                        onClick={e => e.stopPropagation()}
                      >
                        <Text fontSize={28} fontWeight={700} mb={0}>
                          Confirm delete
                        </Text>
                        <Text fontSize={16} mt={4} mb={40}>
                          Are you sure you want to delete?
                        </Text>
                        <CancelConfirmButton
                          isConfirm={true}
                          onClick={() => handleConfirmDelete()}
                        >
                          Confirm
                        </CancelConfirmButton>
                        <CancelConfirmButton onClick={handleCancelDelete}>
                          Cancel
                        </CancelConfirmButton>
                      </DeleteModal>
                    </DeleteModalBackground>
                  )}
                </StyledFlex>
              </StyledFlexRow>
            );
          })
          //   ) : (
          //     <Spinner />
          //   )
        }
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={totalPages}
        />
      </Column>
    </DashboardContent>
  );
};
