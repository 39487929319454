import styled from 'styled-components';
import { colors } from '../../style-utils/colors';
import { font } from '../../style-utils/text';

export const ProfileButton = styled.div<{
  dashboard?: boolean;
}>`
  width: 32px;
  height: 32px;
  ${({ dashboard }) =>
    dashboard ? `margin-bottom: 0px;` : `margin-bottom: 24px;`}
  border-radius: 50%;
  background: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProfileModal = styled.div<{
  dashboard?: boolean;
}>`
  position: absolute;
  width: fit-content;
  height: 140px;
  min-width: 200px;
  background: white;
  border-radius: 8px;
  ${({ dashboard }) => (dashboard ? `top: 75px;` : `bottom: 55px;`)}
  ${({ dashboard }) => (dashboard ? `right: 20px;` : `left: 20px;`)}
  box-shadow: 2px 2px 10px 6px #0000000d;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  z-index: 2;
`;

export const ButtonContainer = styled.button<{ dashboard?: boolean }>`
  margin-bottom: 12px;
  background: ${colors.purple_dark};
  width: fitContent;
  height: 40px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
`;
