import { NameAndLastName } from '../../components/pages/visitor-log/shared/name-and-last-name';
import { Notes } from '../../components/pages/visitor-log/shared/notes';

export const getShiftChangeRoutes = () => {
  const base = '/daily-log/shift-change/';

  return [
    { path: `${base}id`, component: NameAndLastName },

    {
      path: `${base}additional-notes`,
      component: Notes,
      options: ['ADDITIONAL_NOTES'],
    },
  ];
};
