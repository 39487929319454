import styled from 'styled-components';
import { font } from '../../style-utils/text';

export const Button = styled.div<{
  background?: string;
  width?: string;
  disabled: boolean;
}>`
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}px` : `94px`)};
  height: 24px;
  border-radius: 6px;
  background-color: ${({ background, disabled }) =>
    disabled
      ? '#e0e0e0' // Disabled state color
      : background
      ? background
      : `${font.color.purple}`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) =>
    disabled ? '0.6' : '1'}; // Reduced opacity for disabled state
  padding: 6px 16px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
