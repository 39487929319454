import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  NavbarContainer,
  StepAndProgressContainer,
  ContentAndButtonsContainer,
  ButtonsContainer,
  TitleAndSubtitle,
  ContentButtonsWrapper,
  TextWrapper,
  ButtonWrapper,
} from '../../../layout/layout-container.styled';
import { Navbar } from '../../../layout/visitor-log/navbar';
import { NavButton } from '../../../nav-button/nav-button';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { ScanInput } from '../../../scan-input/scan-input';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { Step } from '../../../step/step';
import { findPathIndex } from './controllers/find-path-index';
import { Progress } from '../../../progress/progress';
import { StyledInput } from '../../../styled-input/styled-input';
import styled from 'styled-components';
import { useCamera } from '../../../hooks/use-camera';
import { VisitorLogState } from '../../../hooks/use-visitor-log-handler';
import useRedirectOnRefresh from '../../../hooks/use-redirect-on-refresh';
import { useRedirectOnBack } from '../../../hooks/use-redirect-on-back';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import VideoCapture from '../../../VideoCapture/VideoCapture';

const InputAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30% - 32px);
  padding: 16px;
`;

export const Id: React.FC<DefaultComponentProps> = ({
  navigate,
  nextPath,
  prevPath,
  state,
  path,
  navigation,
  selectedOption,
  changeHandler,
  addNewName,
  selectedBuilding,
}) => {
  useRedirectOnRefresh();
  useRedirectOnBack();

  const isLog = path?.includes('/daily-log') || false;
  const maxPaths = navigation?.length || 0;
  const currentPathIndex = findPathIndex(path, navigation);
  const { names, lastNames } = state as VisitorLogState;

  const { isCameraMode, setIsCameraMode } = useCamera();

  const [key, setKey] = useState(0);
  // eslint-disable-next-line
  const [resultsFromScan, setResultsFromScan] = useState<any>([]);

  const extractNames = (scanText: string) => {
    const lastNamePattern = /DCS(.*?)\n/;
    const firstNamePattern = /DAC(.*?)\n/;

    const lastNameMatch = scanText?.match(lastNamePattern);
    const firstNameMatch = scanText?.match(firstNamePattern);

    const lastName = lastNameMatch ? lastNameMatch[1] : '';
    const firstName = firstNameMatch ? firstNameMatch[1] : '';

    return { firstName, lastName };
  };

  useEffect(() => {
    if (resultsFromScan && resultsFromScan?.barcodeResultItems?.[0]?.text) {
      setIsCameraMode(false);
      const { firstName, lastName } = extractNames(
        resultsFromScan?.barcodeResultItems?.[0]?.text
      );

      if (firstName) {
        changeHandler(firstName, 'names');
      }

      if (lastName) {
        changeHandler(lastName, 'lastNames');
      }
    }

    setKey(key + 1);
  }, [resultsFromScan]);

  const handleScanClick = async () => {
    if (isCameraMode) {
      setIsCameraMode(false);
    } else {
      setResultsFromScan([]);
      setIsCameraMode(true);
    }
  };

  const height = useWindowHeight();

  return (
    <>
      {isCameraMode ? (
        <VideoCapture
          setIsCameraMode={setIsCameraMode}
          setResultsFromScan={setResultsFromScan}
        />
      ) : (
        <LayoutContainer height={height}>
          <Sidebar
            selectedBuilding={selectedBuilding}
            selectedOption={selectedOption}
            navigate={navigate}
          />
          <ContentWrapper>
            <StepAndProgressContainer>
              <Step
                currentPathIndex={currentPathIndex}
                maxPaths={maxPaths || 0}
              />
              <Progress
                currentPathIndex={currentPathIndex}
                maxPaths={maxPaths || 0}
              />
            </StepAndProgressContainer>
            <NavbarAndContentContainer>
              <NavbarContainer>
                <Navbar
                  path={path}
                  navigation={navigation}
                  state={state}
                  isLog={isLog}
                  navigate={navigate}
                  currentPage={path}
                  hasContent
                />
              </NavbarContainer>
              <ContentAndButtonsContainer>
                <>
                  <TitleAndSubtitle direction="row">
                    <TextWrapper>
                      <Text
                        fontSize={font.size.xl}
                        fontWeight={700}
                        mt={0}
                        mb={10}
                      >
                        Scan ID
                      </Text>
                      <Text
                        color={font.color.gray}
                        fontSize={font.size.xs}
                        mt={0}
                        mb={0}
                      >
                        {`Please scan guest ID or type manually`}
                      </Text>
                    </TextWrapper>
                    <ButtonWrapper>
                      <NavButton
                        onClick={() => {
                          addNewName('names'),
                            addNewName('lastNames'),
                            setKey(key + 1);
                        }}
                        disabled={false}
                        text={'Add New'}
                        background={colors.purple_dark}
                        textColor={'white'}
                        direction={'increment'}
                      />
                    </ButtonWrapper>
                  </TitleAndSubtitle>
                  <ContentButtonsWrapper>
                    <>
                      <ScanInput
                        onClick={() => {
                          handleScanClick();
                          setIsCameraMode(!isCameraMode);
                        }}
                      />
                      <Text
                        fontSize={font.size.xl}
                        fontWeight={700}
                        mt={0}
                        mb={0}
                        align="center"
                      >
                        Name and Last Name
                      </Text>
                      <InputAndLabelWrapper>
                        <Text
                          fontWeight={500}
                          fontFamily={font.family}
                          fontSize={font.size.xs}
                          ml={-16}
                        >
                          Name
                        </Text>
                        <StyledInput
                          key={key + 'name'}
                          maxLength={30}
                          width={100}
                          placeholder={'Name'}
                          type="text"
                          defaultValue={names?.[names?.length - 1]}
                          name={'name'}
                          onChange={e =>
                            changeHandler(
                              e,
                              'names',
                              undefined,
                              names?.length - 1
                            )
                          }
                        />
                        <Text
                          ml={-16}
                          fontWeight={500}
                          fontFamily={font.family}
                          fontSize={font.size.xs}
                        >
                          Lastname
                        </Text>
                        <StyledInput
                          key={key + 'lastName'}
                          maxLength={50}
                          width={100}
                          placeholder={'Lastname'}
                          type="text"
                          defaultValue={lastNames?.[lastNames?.length - 1]}
                          name={'lastName'}
                          onChange={e =>
                            changeHandler(
                              e,
                              'lastNames',
                              undefined,
                              lastNames?.length - 1
                            )
                          }
                        />
                      </InputAndLabelWrapper>
                    </>
                  </ContentButtonsWrapper>
                  <ButtonsContainer>
                    <NavButton
                      disabled={false}
                      direction="back"
                      textColor="#637083"
                      background="transparent"
                      text={'Back'}
                      onClick={() => {
                        navigate(prevPath, { replace: false });
                      }}
                    />
                    <NavButton
                      disabled={
                        names?.[names.length - 1]?.length < 2 &&
                        lastNames?.[lastNames.length - 1]?.length < 2
                      }
                      direction="forward"
                      textColor={'white'}
                      background={font.color.purple}
                      text={'Continue'}
                      onClick={() => {
                        navigate(nextPath, { replace: false });
                      }}
                    />
                  </ButtonsContainer>
                </>
              </ContentAndButtonsContainer>
            </NavbarAndContentContainer>
          </ContentWrapper>
        </LayoutContainer>
      )}
    </>
  );
};
